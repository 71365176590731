<template>
  FEES
</template>

<script>

import calculateSessionFees from '../composables/calculateSessionFees.js'
import { onMounted } from 'vue'


export default {

    setup() {

        
        return {
            calculateSessionFees
        }
    }

}
</script>

<style>

</style>