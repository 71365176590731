<template>
  
  <Nav />
  <SignInForm />
</template>

<script>

import SignInForm from '../components/SignInForm.vue'
import LoginSignUp from '../components/LoginSignUp.vue'
import Nav from '../components/Nav.vue'
import TestVModel from '../components/TestVModel.vue'


export default {

    components: { TestVModel, LoginSignUp, SignInForm, Nav }

}
</script>

<style>

</style>