import firebase from 'firebase'
import { ref, onMounted } from 'vue'

// by convention, composable function names start with "use"
// https://vuejs.org/guide/reusability/composables.html

export function useUserSignedIn() {

    const userSignedIn = ref(null)

        firebase.auth().onAuthStateChanged((user) => {

            if (user) {
                 
                  userSignedIn.value = true
                  console.log("useUserSignedIn composable", userSignedIn.value)
              
                } else {

                  userSignedIn.value = false
                  console.log("useUserSignedIn composable", userSignedIn.value)

                }
             
            })

            
        onMounted(() => userSignedIn )


        return { userSignedIn }

        }


