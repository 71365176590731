<template>
  <SinglePost 

  />
</template>

<script>


import { ref, onMounted } from 'vue'
import SinglePost from  '../components/SinglePost.vue'

export default {

  components: { SinglePost },


}

</script>

<style>

</style>