<template>
    <Nav />

    <div id="accessTokenTrue" style="display:none"></div>
    <div class="screenShareClass" id="screenshare" style="margin-top:10vh;display:block;"></div>
    <div id="current_session_ID" style="display:none"></div>

    <div :class="{'desktop-controls-two': isMobileDevice()}">
    <WriteSendButton    v-if="userSignedIn"
                        @update-connection-type="updateConnectionType"
                        @open-full-screen="openFullScreen('theVideo')"
                        @request-picture-in-picture="requestPictureInPicture('theVideo')"
                        @screen-share="screenShare(room, uid, userFirstName)"
                        @code-button-click="addCodeToQuestion"
                        :sendMobileButtonHidden="sendMobileButtonHidden"
                        :putInFocus="putInFocus"
                        :sendQuestion="sendQuestion"
                        :questionSent="questionSent"
                        :sessionStatusUpdate="sessionStatusUpdate"
                        :showTimer="showTimer"
            />
    </div>
    
    
    <div class="desktop-controls-position d-flex flex-column justify-content-start">
        <el-button 
            v-if="isMobileDevice() && !showTimer" type="danger" class="desktop-send-end-button m-1" @click="sendQuestion" style="margin-left:-9px">
           <span style="margin-left:-8px; font-size: small;">SEND</span>             
        </el-button>
    </div>

    <div v-if="isMobileDevice()" class="session-status-desktop">
        <div  class="d-flex flex-row align-items-center justify-content-center">
            <div>{{ sessionStatusUpdate }}</div><Loader v-if="questionSent && !showTimer" class="mx-3"/>
        </div>
    </div>
   

    <DesktopControlButtons 
        v-if="showTimer"
        :stopVideoStream="stopVideoStream"
        :openFullScreen="openFullScreen"
        :requestPictureInPicture="requestPictureInPicture"
        :screenShare="screenShare"
        :room="room"
        :uid="uid"
        :userFirstName="userFirstName"
        />

  

    <div class="row d-flex justify-content-center video-page-style overflow-x-hidden"> 

            <el-card id="backgroundDiv" shadow="never" class="no-border-nor-radius col-sm-6 tranparent-background" :body-style="{ padding: '0px'}">
             
                <el-card shadow="never" class="no-border-nor-radius tranparent-background" :body-style="{padding: '0px'}">  

                    <div>   
                        
                            <div v-if="!showTimer" class="view-height-9"> 
                                <div  class="d-flex flex-row align-items-center justify-content-center">
                                    <div class="py-2 bold-font">{{ sessionStatusUpdate }}</div><Loader v-if="questionSent && !showTimer" class="mx-3"/>
                                </div>
                            </div>
                            
                            <div v-if="isMobileDevice() && showTimer" class="view-height-9">SPACE</div>

                            <div class="my-5">

                                <Post 
                                    :message="question"
                                    :subject="subjectChosen"   
                                    :id="room"
                                    :theroom="room"   
                                    :uid="uid"
                                    :userFirstName="userFirstName"
                                    :sender="userFirstName"
                                    :code="code"
                                    :class="{ inVisible: !fillGap }"
                                    :questionSent="questionSent"
                                /> 


                            <div v-if="!showTimer"
                            
                                :class="{  
                                    gapfill: fillGap, 
                                    'border-top-0': fillGap,
                                    'postBorderDesktop' : isMobileDevice()
                                    }"      
                                    class="whiteBackground borderRadius25px">
                                    

                                <div class="row" @click="putInFocus"> 
                                    <div ref="question_input" id="question_input" class="d-flex justify-content-start align-items-center"
                                    :class="{'p-4' : !isMobileDevice(), 'p-5' : isMobileDevice()}">
                                    <div v-if="largePlaceholder" class="d-flex justify-content-start align-items-start fauxPlaceholder" id="fauxPlaceholder"
                                        >
                                            <div>How can we help?</div>
                                        </div>    
                                        <NewQuestionInput v-model="question" id="theQuestionInput"
                                        @keypress="putInFocus"
                                        @paste="putInFocus"
                                        />
                                    </div>
        
                                </div> 
                                <div class="row">
                                    <div class="d-flex justify-content-center">

                                        <CodeInput id="codeInput" 
                                            v-if="codeSelected" 
                                            v-model="code" 
                                            :showFormattedCode="codeSubmitted" 
                                            :submittedCode="code" 
                                            @keydown="studentUpdateCodeLive(postId)" 
                                            :autosize="{ minRows: editMinRows }"
                                            />

                                    </div>                       
                                </div>
                            </div>

                            <div class="d-flex flex-row justify-content-between my-3"> 

 

                            </div>


                            <LiveCodeEdit :buttonLabel="buttonLabel" :isCode="codeSubmitted" @click="testGetPostID(postId)"/> 

                            </div>

                    </div>
                </el-card>

               
                

            </el-card>


    </div>

    <NavBottom @update-connection-type="updateConnectionType" :showTimer="showTimer" />
   


</template>

<script>

import firebase from 'firebase'
import { onMounted, ref, watch, watchEffect, nextTick, defineComponent } from 'vue'
import { timestamp } from '../firebase/config'
import { useRouter } from 'vue-router'
import createAccessTokenAndJoinRoom from '../composables/createAccessTokenAndJoinRoom.js'
import twillioVideoConnect from '../composables/twillioVideoConnect.js'
import Nav from '../components/Nav.vue'
import NavBottom from '../components/NavBottom.vue'
import isMobileDevice from '../composables/mobileDevice.js'
import screenShare from '../composables/screenShare.js'
import getPagePath from '../composables/getPagePath.js'
import FAPaperPlane from '../components/icons/FAPaperPlane.vue'
import QuestionInput from '../components/QuestionInput.vue'
import CodeInput from '../components/CodeInput.vue'
import Post from  '../components/Post.vue'
import getFirstName from '../composables/getFirstName.js'
import SubjectHeader from '../components/SubjectHeader.vue'
import requestPictureInPicture from '../composables/requestPictureInPicture.js'
import openFullScreen from '../composables/requestFullScreen.js'
import WriteSendButton from '../components/WriteSendButton.vue'
import LiveCodeEdit from '../components/LiveCodeEdit.vue'
import MenuItems from '../components/MenuItems.vue'
import NewQuestionInput from '../components/NewQuestionInput.vue'
import ControlButton from '../components/ControlButton.vue'
import DigitalClock from '../components/DigitalClock.vue'
import Timer from '../components/Timer.vue'
import startTimerOnConnection from '../composables/startTimerOnConnection.js'
import { useUserSignedIn } from '../composables/useUserSignedIn.js'
import svgpath from '../composables/getSVGpath.js'
import useConfirmConnectionType from '../composables/useConfirmConnectionType.js'
import Loader from  '../components/Loader.vue'
import getCurrentSessionID from '../composables/getCurrentSessionID.js'
import useCurrentSessionID from '../composables/useCurrentSessionID.js'
import VideoModal from '../components/VideoModal.vue'
import stopVideoStream from '../composables/stopVideoStream.js'
import FullScreen from '../components/icons/FullScreen.vue'
import PictureInPicture from '../components/icons/PictureInPicture.vue'
import HangUp from '../components/icons/HangUp.vue'
import ScreenShare from '../components/icons/ScreenShare.vue'
import DesktopControlButtons from '../components/DesktopControlButtons.vue'





export default {

    components: { Nav, QuestionInput, Post, SubjectHeader, CodeInput, NavBottom, LiveCodeEdit, WriteSendButton, FAPaperPlane, MenuItems, ControlButton, DigitalClock, Timer, Loader, VideoModal, NewQuestionInput, FullScreen, HangUp, PictureInPicture, ScreenShare, DesktopControlButtons },

    setup() {

    const subjects = ref(["Javascript", "React", "Vue", "Photoshop"]);
    const headLine = ref('Instant Expert Help')
    const sessionStatusUpdate = ref('')
    const questionsCollection = firebase.firestore().collection("questions");
    const questionDoc = questionsCollection.doc();
    const studentAccessCollection = firebase.firestore().collection("studentAccess");
    const teacherAccessCollection = firebase.firestore().collection("teaherAccess");
    const sessionsCollection = firebase.firestore().collection("sessions")

    const postId = questionDoc.id
    const router = useRouter()
    const email = ref('') // EMAIL AND UID HERE ARE FROM THIS FILE (VIDEO.VUE)
    const uid = ref('')
    const name = ref('')
    const roomDocRef = ref('')
    const fakeUID = ref('')
    const collectionRef = ref(null)
    const room = ref('')
    const question = ref('')
    const code = ref('')
    const questionSent = ref(false)
    const subjectChosen = ref('')
    const showSubjectHeader = ref(false)
    const thePlaceHolder = ref("How can we help?")
    const isToken = ref(false)
    const userFirstName = ref('')
    const testclass = ref(false)
    const pic2 = ref(require('../assets/call-screenshot.png'))
    const pic20 = ref(require('../assets/cam-call.png'))
    const { userSignedIn } = useUserSignedIn()

    const call = svgpath('call', 0)
    
    const subjectsArray = ['vue', 'react', 'javascript', 'photoshop', 'protools', 'figma', 'html', 'instagram', 'ableton', 'crypto', 'nft']
    const studentAccessToken = ref(null)
    const codeSelected = ref(false)
    const codeSubmitted = ref(false)
    const buttonLabel = ref('VIDEO PAGE EDIT CODE')
    const isEditing = ref(false)
    const fillGap = ref(false)
    const editMinRows = ref(3)
    const removeElement = ref(false)
    // const theQuestionInput = ref(null)
    const sendMobileButtonHidden = ref(true)
    const isVideoConnection = ref(true)
    const mobile = ref(null)
    const videoViewHeight = ref(true)
    const largePlaceholder = ref(true)
    const buttonName = ref(null)
    const buttonFunction = ref(null)

    const demoVideoURL = "https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/Expert5%20Promo%20vid%201.2%20(alt).mp4?alt=media&token=53bd48cc-6863-4455-812c-591f926a2a4e"
    const howToVideoURL = "https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/QuichHowTo2.mp4?alt=media&token=05bd3326-8dd0-4dcd-b227-308c8c8ae07c"

    const showTimer = ref(false)



    const turnOnTimer = () => {
            showTimer.value = true 
        }


    const highlightCode = () => {

            if(codeSubmitted.value === true){
                console.log("HIGHLIGHT CODE HERE ***!!!")
                hljs.highlightAll();
            }else {
                console.log("NO HIGHLIGHT CODE!!!")
            }
        }

    const codeSubbittedWithQuestion = () => {

        if((codeSelected.value === true) && (code.value !=='')){
            console.log("CODE SUBMITTED WITH QUESTION")
            codeSubmitted.value = true
        } else {
            console.log("NO CODE SUBMITTED WITH QUESTION")
        }

    }

    const question_input = ref(null)
    const showLogIn = ref(true)


    const updateConnectionType = () => {
        if(isVideoConnection.value === true){
            console.log("EMIT VOICE")
            isVideoConnection.value = false
        } else {
            console.log("EMIT VIDEO")
            isVideoConnection.value = true
        }
    }
  


    const testGetPostID = (id) => {

        console.log("theID", id)
        codeSubmitted.value = false
        isEditing.value = true

        const questionDoc = questionsCollection.doc(id) 

        questionDoc.get().then((doc) => {

            code.value = doc.data().code
            editMinRows.value = code.value.split(/\r\n|\r|\n/).length
            console.log("***** code.value *****", code.value)


            }).then(() => {
                questionDoc.set({
                    editingCode: true,
                    },{merge:true})
            }).catch((err) => { console.log("Error @ testGetPostID", err)}) 
    }

    const studentUpdateCodeLive = (id) => {

        if(isEditing.value === false){
            return
        }

        if(isEditing.value === true){

            const docRef = questionsCollection.doc(id)
                 
            docRef.get().then((doc) => {

                if (doc.exists) {
                    docRef.set({
                        code:code.value,
                        pageSentFrom: ''
                    },{merge:true})

                } else {
                    console.log("No such doc")
                }

            })
        }
    }


    // HERE THE 'CODE' SUBMITTED BY THE STUDENT UPDATES LIVE WHILE THE EXPERT TYPES
    questionsCollection.doc(postId).onSnapshot((doc) =>{

        if(doc.data()){
            code.value = doc.data().code
        }
    })

    sessionsCollection.doc(postId).onSnapshot((doc) =>{

        if(doc.exists){
        // try {    
            if(doc.data().sessionStatus == "started"){
                sessionStatusUpdate.value = "Connected"
                fillGap.value = true
            } else {
            sessionStatusUpdate.value = " " 
            }

        // } catch(err) { console.log("error @ sessionStatus " + err)}

        }
    })



    const checkIfTokenArrived = () => {

        if(document.getElementById("accessTokenTrue").textContent != ""){
            return
        }

        studentAccessCollection.doc(room.value).onSnapshot((doc) => {
         
            try {

                    const { accessToken } = doc.data()
                    // WHEN THE ACCESS TOKEN ARRIIVES, TURN ON RED LIGHT (OR DO OTHER STUFF)
                    if(accessToken) {
                            isToken.value = true
                            
                            studentAccessToken.value = accessToken
                            console.log("Access Token confirmed @ Video.vue")
                            console.log("//******** NEW STUDENT ACCESS TOKEN VALUE *******//: " + studentAccessToken.value)

                            
                                console.log("advance_token")
                                const thisToken = studentAccessToken.value
                                twillioVideoConnect(thisToken, room.value, "student", null, isVideoConnection.value)

                            
                    }

            } catch(err) { console.log("error @ checkIfTokenArrived " + err)}

            });
           
            }

    const checkForSubject = () => {

        const questionArray = question.value.split(" ");
        questionArray.forEach(element => {  

            const noSpecialChars = element.replace(/\s+/g, '')
            const cleanString = noSpecialChars.replace(/[^a-zA-Z ]/g, "").toLowerCase();

            if(subjectsArray.includes(cleanString)){
                console.log("MATCH!!")
                subjectChosen.value = cleanString              
            }
        })

    }

    //////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////

    const checkForTokenOnQuestionSend = () => {

            console.log("checkForTokenOnQuestionSend")

            studentAccessCollection.doc(room.value).get()
            .then((doc) => {
                if (doc.exists) {

                    const { accessToken } = doc.data()
                    if(accessToken){
                        console.log("YES ACCESS TOKEN")
                        // return
                    } else{
                        console.log("NO ACCESS TOKEN - CREATING NOW")
                        // create Student access token here
                        checkIfTokenArrived() 
                    }

                    console.log("Document data:", doc.data());
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).then(() => {

                console.log("CREATING BACK-UP ACCESS TOKEN")
                try {
                    const backupToken = document.getElementById("accessTokenTrue").textContent
                    twillioVideoConnect(backupToken, room.value, "student", null, isVideoConnection.value)
                }catch(err){console.log(err)}
            })
            
            .catch((error) => {
                console.log("Error getting document:", error);
            });

    }

    const addCodeToQuestion = () => {

        if(codeSelected.value === true){
            codeSelected.value = false
        } else {
            codeSelected.value = true
        }
        

    }

    const confirmConnectionType = () => { 

            if(isVideoConnection.value === true){

                let check = confirm("Confirm video call.\nClick 'Cancel' for voice only.")

                if(check === true){
                    console.log("Video connection CONFIRMED by student. Use 'Video' connection")
                } else {
                    console.log("Video connection CANCELLED by student. Turn video off, and use 'Audio Only' connectiion")
                    isVideoConnection.value = false
                }

            } else {
                console.log("USER HAS CHANGED CONNECTION TYPE 'VIDEO' (DEFAULT) TO 'AUDIO ONLY. NO NEED TO CONFIRM.'")
            }

        }


    const sendQuestion = () => {

            // document.getElementById('theQuestionInput').blur()

            
            startTimerOnConnection(room.value, turnOnTimer)

            console.log("question.value:", question.value)

            checkForSubject()

            console.log("subjectChosen: " + subjectChosen.value)

            if(subjectChosen.value == ''){
                return alert("Please include a subject we cover in your question. e.g 'Vue'")
                        
            }

            if(question.value == ''){
                return alert("Please enter a question")
                
            }

            //////////////////////////////////////
            // PLACE CONNECTION TYPE ALERT HERE
            console.log("1. isVideoConnection.value", isVideoConnection.value )

            // useConfirmConnectionType(isVideoConnection.value )
            confirmConnectionType()

            codeSubbittedWithQuestion()
            

            // ISSUE WITH SPACING ON TOP LINE WHEN SAVING EDITED CODE
            // QUICK FIX: NEW LINE "\n" ADDED BEFORE CODE
            // MAY NEED IMPROVING IN THE FUTURE

            let codeWithEmptyTopLine;

            if(code.value != null){
                codeWithEmptyTopLine = '\n' + code.value
            } else {
                codeWithEmptyTopLine = ''
            }
            

            questionsCollection.doc(room.value).set({

                subject: subjectChosen.value,
                question:question.value,
                codeSelected: codeSelected.value,
                code:codeWithEmptyTopLine,
                isVideoConnection: isVideoConnection.value

            }, {merge:true})

            .then(() => {

            
            studentAccessCollection.doc(room.value).set({
                isVideoConnection: isVideoConnection.value

            },{merge:true})

            })
            .then(() => {
                checkForTokenOnQuestionSend()

            })
            .then(() => {
                questionSent.value = true
                sessionStatusUpdate.value = "Contacting " + subjectChosen.value + " users..."

                //// JOIN ROOM HERE
                const thisToken = studentAccessToken.value
                console.log("THIS TOKEN: " + thisToken + " studentAccessToken.value: " + studentAccessToken.value)
                //*** TURNED-OFF TWILIO CONNECTION WHILE TEST ********//
                twillioVideoConnect(thisToken, room.value, "student", null, isVideoConnection.value)
                highlightCode()
            
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });

    }

    //////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////


    const advanceCreateAccessToken = () => {

            console.log("ADVANCE CREATE ACCESS TOKEN")

            questionDoc.set({

                sender: userFirstName.value,
                senderEmail: email.value,
                senderUID: uid.value,
                // subject: "vue",
                time: timestamp(),
                /////// ********* TESTING WITH MY TELEPHONE NUMBER *********** //////
                senderTel: "+13235297141",
                // question:"test question",
                id: postId,
                datenow: Date.now(),
                hasResponders: false,
                source: "web",
                mobile_device:!mobile.value,
                zoom_requests: []

            })
            .then(() => {

                if(questionDoc){
                    
                    console.log("Document successfully written! ID: " + questionDoc.id);
                    console.log("isVideoConnection isVideoConnection", isVideoConnection.value)
                    // questionSent.value = true
                    room.value = questionDoc.id
                    getCurrentSessionID(questionDoc.id)
                    // ONLY CREATE ACCESS TOKEN HERE - DON'T JOIN ROOM
                    createAccessTokenAndJoinRoom(room.value, 'student', roomDocRef.value, room.value, !mobile.value, collectionRef.value, isVideoConnection.value)

                } else {
                    alert("QUESTION DOC FIRESTORE REF NOT CREATED")
                }

            })
            .then(() => {
                    // checkIfTokenArrived()
                    console.log("useCurrentSessionID", useCurrentSessionID())
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });

    }
    
    // NOTE: 'onAuthStateChanged' CAN FIRE MULTIPLE TIMES - 
    // THIS CAUSED 'advanceCreateAccessToken' ALSO TO FIRE MULTIMPLE TIMES, CREATING MANY ACCESS TOKENS
    // WHICH CAUSED VIDEO CONNECTION TO FAIL.
    // USING 'authFlag' TO PREVENT THIS
    // INFO HERE: https://stackoverflow.com/questions/49873223/why-does-my-firebase-onauthstatechanged-trigger-multiple-times-react-native
    
    const authFlag = ref(true);

    firebase.auth().onAuthStateChanged((user) => {

            if(authFlag.value === true){

            authFlag.value = false;

            if (user) { 
                    
                    console.log("@ VIDEO.VUE USER SIGNED IN")
                    showLogIn.value = false
                    email.value = user.email
                    uid.value = user.uid
                    console.log("@ VIDEO.VUE USER SIGNED IN - EMAIL", email.value )
                    const docRef = firebase.firestore().collection("users").doc(user.email);

                    docRef.get().then((doc) => {  
        
                        if (doc.exists) {
                            const { firstname } = doc.data()
                            console.log("##### THE FIRST NAME " + firstname)
                            userFirstName.value = firstname

                            advanceCreateAccessToken() // off while delevoping

                        } else {
                        console.log("No such document")
                            }

                        })

                    } else {
                    console.log("@ VIDEO.VUE NO USER")
                }

            }
        })



    const putInFocus = () => {
            console.log("PUT IN FOCUS!")
            if(userSignedIn.value === false){
                alert("Please sign-in to ask a question")
            }

            largePlaceholder.value = false
            sendMobileButtonHidden.value = false
            thePlaceHolder.value = ""
            document.getElementById('theQuestionInput').focus()
        }

    watchEffect(() => {

        console.log("WATCH EFFECT sessionStatusUpdate.value", sessionStatusUpdate.value)
        // if(sessionStatusUpdate.value === 'Connected') {videoViewHeight.value = false }
        const el = document.getElementById('theQuestionInput');
        console.log("theQuestionInput is active", el === document.activeElement);

  

    })
    

    onMounted(() =>{

        mobile.value = isMobileDevice()

        hljs.configure({
                ignoreUnescapedHTML: true
                });


            hljs.highlightAll();

        document.getElementById("accessTokenTrue").textContent = ""
        // console.log("PAGE PATH '" + getPagePath() + "'")

        

    })



    return { 

                email, 
                uid, 
                fakeUID, 
                collectionRef, 
                createAccessTokenAndJoinRoom,
                twillioVideoConnect,
                room, 
                name, 
                question, 
                sendQuestion, 
                questionsCollection,
                postId,
                questionDoc,
                questionSent,
                subjects,
                subjectChosen,
                thePlaceHolder,
                isMobileDevice,
                isToken,
                checkIfTokenArrived,
                getFirstName,
                userFirstName,
                showSubjectHeader,
                testclass,
                pic2,
                pic20,
                subjectsArray,
                checkForSubject,
                getPagePath,
                screenShare,
                advanceCreateAccessToken,
                codeSelected,
                codeSubmitted,
                codeSubbittedWithQuestion,
                code,
                headLine,
                sessionStatusUpdate,
                requestPictureInPicture,
                question_input,
                showLogIn,
                buttonLabel,
                testGetPostID,
                isEditing,
                studentUpdateCodeLive,
                fillGap,
                editMinRows,
                removeElement,
                addCodeToQuestion,
                // theQuestionInput,
                putInFocus,
                sendMobileButtonHidden,
                isVideoConnection,
                updateConnectionType,
                mobile,
                videoViewHeight,
                authFlag,
                startTimerOnConnection,
                showTimer,
                turnOnTimer,
                useUserSignedIn,
                userSignedIn,
                svgpath,
                call,
                useConfirmConnectionType,
                confirmConnectionType,
                getCurrentSessionID,
                useCurrentSessionID,
                demoVideoURL,
                howToVideoURL,
                openFullScreen,
                largePlaceholder,
                stopVideoStream,
                // controlButtons,
                buttonFunction,
                buttonName,
                // controlButtonsObject,

                

                            
            }

    }

}
</script>




<style scoped>

.marginTop {
    padding-top:14vh
} 

.extraMargin {
    margin:8px;
}

.purpleColor {
    background-color: #660f56;
}

.pageHeight {
    min-height: 100vh;
    /* margin-bottom: 30vh; */
}

.someButtons {
    border-bottom:1px solid black;
    border-left:1px solid black;
    border-right:1px solid black;
    border-top:1px solid #d3d3d3;
    background-color: #f0f0f0;
}

/* .sendButton {
    border-right:1px solid #d5d5d5;
} */

.dropDownStyle {
    border-left:1px solid #d5d5d5;
}

.studentVideoStyle {
    border: 1px solid #505050;
    padding: 2%

}

.subjectHeaderVisibilityNone {  

 visibility: hidden;

}

.subjectHeaderVisibilityVisible {  

 visibility: visible;

}

.subjectsStyle {
    font-size: small;
    text-transform: uppercase;
    font-weight: bold;
}

.avatarFadeOut {
  animation: fadeOut; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */

}

.beigeBackground {
   background-color: #edebe7;
   min-height: 45vh
}



.headlineFontSizeMobile {
    /* margin-top: 5vh; */
    font-size: 100%;
    /* font-family: 'Titan One', cursive; */
    font-weight: bold;
    text-transform: uppercase;
    color:white; 
}

.headlineFontSizeDesktop {
    font-size: 380%;
    font-family:'Helvetica';
    color:white; 
}


.mainBorderStyle {
    border-radius:0px; 
    max-width: 97vw; 
    background-color:transparent; 
    
} 

.mainBorderWidthDesktop {

    border: solid 3px black

}

.mainBorderWidthMobile {

    border: solid 3px black;
    border-radius: 5px;
    margin: 10px
}

.flexcenter {
    display: flex;
    justify-content: center;
}

/* .inputmargins {
    margin: 0px 20px 0px 20px;
} */

.inVisible {
    display: none;
}

.gapfill {
    margin-top:-0.6em
}

.no-border-nor-radius {

    border:0; 
    border-radius:0;
}

.font-100px {
    font-size: 72px; 
}

.session-status {
    font-size: 100%
}

.video-page-style {
    
    
    max-width: 107vw;
    min-width: 100vw;
    margin-bottom: 20vh;
    /* max-height: 100vh;  */
    /* background-color: white; */
}

.video-page-padding-bottom {
    padding-bottom:20vh;
}

.video-page-margin-top {
    margin-top:10vh;
}

.side-logo {

    width: 40%;
    margin-top: 8vh;
    margin-bottom: 1vh

}

.sign-in-out {
    background-color: white; 
    border-radius: 20px
}

.zeroZindex {
    z-index: -1;
}

.extraBorder {
    border: solid black 1px
}

.text250percent {
    font-size: 250%;
}

.text100ercent {
    font-size: 100%;
}

.input-opacity-zero {
    opacity: 0;
}

.send-button-desktop {

    width:50px; 
    height: 50px;
    border-radius: 25px;
    background-color: #ae2d68;
    border-color: #ae2d68;
}

.view-height-12 {
    height: 12vh;
}

.view-height-9 {
    height: 12vh;
}

.sign-up-button {
    background-color: #AE2D68;
    color: white

}

.new-question-input {

    font-size: 'medium';
    padding-top: '15px';
    padding-bottom:'15px';
    border-radius:'0';
    background-color: 'red';
    min-width: '1000px';
    font-weight: '500'
}

.large-placeholder {
    font-size: xx-large ;
    font-weight: bold;
    opacity: 0.5;
}

.input-min-height {
   height: 80px;
}

.fauxPlaceholder {

    font-size: xx-large; 
    position:fixed; 
    color: #bebebe; 
    font-weight: bold

}

.session-status-desktop {
    font-weight: bold; 
    padding-top:10vh; 
    padding-left: 2vw; 
    position: fixed;
}
                                        






</style>