<template>
  
  <Nav />
  <SignUpForm />
  <NavBottom />
</template>

<script>

import SignUpForm from '../components/SignUpForm.vue'
import LoginSignUp from '../components/LoginSignUp.vue'
import Nav from '../components/Nav.vue'
import NavBottom from '../components/NavBottom.vue'
import TestVModel from '../components/TestVModel.vue'


export default {

    components: { TestVModel, LoginSignUp, SignUpForm, Nav, NavBottom }

}
</script>

<style>

</style>