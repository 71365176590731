<template>
    <button class="endVideoClass">
        <PictureInPicture />       
    </button>  
</template>

<script>

import PictureInPicture from '../components/icons/PictureInPicture.vue'

export default {
    components: { PictureInPicture }

}
</script>

<style>

</style>