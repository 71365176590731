<template>
    <div class="loader"></div>
</template>

<script>
export default {

}
</script>

<style>

    .loader,
    .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
    }
    .loader {
    /* margin: 60px auto; */
    font-size: 2px;
    /* position: relative; */
    /* text-indent: -9999em; */


    /* my colors */
    border-top: 1.1em solid rgba(128, 77, 41, 0.2);
    border-right: 1.1em solid rgba(128, 77, 41, 0.2);
    border-bottom: 1.1em solid rgba(128, 77, 41, 0.2);
    border-left: 1.1em solid black;

    /* orignal colors */
    /* border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff; */

    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }
    @keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
    }

</style>