<template>

    <div :class="{boxCardMobile: mobileDevice, boxCardDesktop: !mobileDevice }" class="boxBorderRadius p-4 mb-4 testShadow">
        <div class="innnerbox">
          <div class="text item">{{ sometext }}</div>
        </div>
        <hr>
        <div class="justifyText"> {{ moreinfo }}</div>
    </div>

</template>

<script>

import { ref, onMounted } from 'vue'

export default {

  props: [ 'sometext', 'moreinfo'],

  

  setup() {

      const mobileDevice = ref(true)

      onMounted(() => {

                if((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)){ 

              mobileDevice.value = false

            }

    })

    return { mobileDevice }

  }

}


</script>

<style scoped>
.text {
  font-size: 18px;
  font-weight: bold;
}

.item {
  padding: 18px 0;
}

.boxCardMobile {
  width: 100%;
}

.boxCardDesktop {
  width: 400px;
}

.boxBorderRadius {
  border-radius:25px
}

.innnerbox {
  /* background-color: cornflowerblue; */
  border-radius: 15px 15px 3px 3px;
}

.whiteText {
  color:white
}

.justifyText {
  text-align: justify;
}




</style>

