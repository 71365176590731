<template>
         <div>The easiest way... 
                <span class="text-start col-sm-1" 
                        :class="{ myFadeOut: classExit, myFadeIn: classEntrance }"
                                style="font-weight:bold;">
                                {{ subject }}
                </span>
        </div>
  
</template>

<script>

import { ref, onMounted, watch} from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'

export default {

    setup() {

                const classExit = ref(false)
                const classEntrance = ref(false)
                const mobileDevice = ref(true)
                const subject = ref('to get an expert')

                onMounted(() => {

                mobileDevice.value = isMobileDevice()


                const selection = [ 
                                   "to get an expert",                  
                                   "to get unstuck",
                                   "to get help",
                                   "to get a lesson",
                                   "to find a teacher",
                                   "to get feedback",
                                   "to get an answer"
                                      
                                ]


                const work = () => {setInterval(myTimer, 2000);}

                const myTimer = () => {

                    classEntrance.value = true
                    setTimeout(() => { classExit.value = true; }, 1000);
                    
                    const index = selection.indexOf(subject.value)
                    subject.value = selection[index + 1]  
                    if(index === selection.length - 1){subject.value = selection[0]} // this sets the loop back to the beginning

                 }

                work()

            
            });

            // THIS IS HOW 'WATCH' WORKS:
                watch( subject, () => {
              
                    setTimeout(() => { classExit.value = false; }, 2000);
                    setTimeout(() => { classEntrance.value = false; }, 1000);
                    
                })


        return { isMobileDevice, classExit, classEntrance, mobileDevice, subject }
    }

}
</script>

<style scoped>

.marker {
    font-family: 'Permanent Marker', cursive;
}

.pacificoCursive {
    font-family: 'Pacifico', cursive;
}

.rockSaltCursive {
    font-family: 'Rock Salt', cursive;
}





</style>