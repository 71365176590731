<template>

    <el-input
        v-if="!showFormattedCode"
        :autosize="{ minRows: 3, maxRows: 12 }"
        type='textarea' 
        :placeholder="'enter programming code here'"
        :input-style="{

            minWidth:'100%',
            fontSize:'medium',
            paddingTop:'10px',
            paddingLeft:'8px',
            borderRadius:'0',
            backgroundColor: 'transparent',
            border: 'none',
            borderTop: '1px solid #D3D3D3'
        }"/>
    <pre v-else class="text-start mx-3 border" style="width:100%">
        <code>{{ submittedCode }}</code>
    </pre>
  
</template>



<script>


    export default {

        props: {
            showFormattedCode: Boolean,
            submittedCode: String
        },

    setup(props) {




        
        return {  }

    }

    }

</script>

<style>

</style>