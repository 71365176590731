<template>
  <el-button class="m-1 border-radius-none front-page-button bold-font" type="danger" @click = "isModalOpen = true">{{ buttonText }}</el-button>
    <Teleport to="#modal">     
        <div class="modal-bg" v-if="isModalOpen" @click="isModalOpen=false">
          <div class="modal-alt" ref="modal">
            <button @click="isModalOpen=false" class="button-close">close</button>
             <!-- Click outside this modal to close it -->
             <video :class="{'p-3':mobileDevice()}"  width="640" height="480" controls autoplay>
                <source :src="videoURL" type="video/mp4">
            </video>
          </div>
        </div>
    </Teleport>
</template>

<script>


import { ref } from 'vue'
import mobileDevice from '../composables/mobileDevice.js'
import { onClickOutside } from '@vueuse/core'

export default {

    props: [ "buttonText", "videoURL" ],

    setup(){

      const isModalOpen = ref(false)
      const modal = ref(null)
      
    // const vidURL = "https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/Expert5%20Promo%20vid%201.2%20(alt).mp4?alt=media&token=53bd48cc-6863-4455-812c-591f926a2a4ehttps://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/Expert5%20Promo%20vid%201.2%20(alt).mp4?alt=media&token=53bd48cc-6863-4455-812c-591f926a2a4e"

    //   onClickOutside(modal, () => { isModalOpen.value = false})

      return { isModalOpen, modal, mobileDevice }
    }

}

</script>


<style scoped>

.modal-bg {

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  
  display: flex;
  justify-content: center;
  align-items: center;

}


.modal-alt{
  position: relative;
  background: white;

  /* padding: 100px; */
  /* border-radius: 5px; */
  /* box-shadow: 0px 10px 5px 2px rgba(0, 0, 0, 0.1); */
}

.button-close {

    position: absolute;
    top: 10px;
    right: 10px;

    background: none;
    border: none;
    cursor: pointer;
    color: grey;
    z-index: 5;

}

.button-width P{
  width:250px
}



</style>

