<template>

    <!-- <div :class="{'write-send-button-container d-flex align-items-start justify-content-end py-3 px-3': mobile}">   -->
    <div class="write-send-button-container d-flex align-items-start justify-content-end py-3 px-3">  
        <div class="d-flex flex-row justify-content-between" style="width:100vw">

            <div class="d-flex flex-column">

            <div v-if="getPagePath() === 'ask' && !showTimer">   

                <div class="d-flex flex-row">
                    <div>
                        <div v-if="video_selected" class="my-1 selected-text extra-small-font" style="width: 18px;">ON</div>
                        <div v-else class="my-1 selected-text extra-small-font" style="width: 18px">OFF</div>                    
                    </div>                    
                    <ControlButton 
                        @click="selectConnectionType(), $emit('updateConnectionType')" 
                        :width="25"
                        :viewBox="'-1 4 28 28'"
                        :path="camIconPath1" 
                        :path2="camIconPath2"
                        :inverted="!inverted"
                        
                    />
            
                </div>
            </div>
            
                    <ControlButton v-if="!mobile && getPagePath() === 'ask' && !showTimer"
                        @click="$emit('codeButtonClick')"
                        :width="22"
                        :path="codeIcon"
                        :viewBox="'0 0 640 512'"
                        :inverted="inverted"
                        :color="fill"
                        style="margin-left:18px"
                        class="my-1"
                    />

            </div>

            <!-- <div >connection</div> -->
            <div class="mx-2">

                    <el-button type="danger" round 
                        v-if="!hangUpButton && getPagePath() === 'ask' && mobile"
                        @click='sendQuestion'>
                        SEND
                    </el-button>

                    <!-- hangup button for 'ask' page -->
                    <ControlButton v-if="hangUpButton && getPagePath() === 'ask' && mobile"
                        type="text" 
                        @click="stopVideoStream(), hideButtonsOnSessionEnd()"
                        :width="25" 
                        :viewBox="'0 50 700 700'"
                        :path="hangUp1Icon" 
                        :path2="hangUp1Icon2" 
                        :inverted="!inverted" 
                        class="control-button-background-color nudge-down-1"
                        :class="{'extra-margin-top' : mobile}"
                        style="border: 2px solid white"
                        />

                    <!-- hangup button for 'answer' page -->
                    <ControlButton v-if="showTimer && getPagePath() === 'answer' && mobile"
                        type="text" 
                        @click="$emit('stopVideoStreamFromAnswerPage')"
                        :width="25" 
                        :viewBox="'0 50 700 700'"
                        :path="hangUp1Icon" 
                        :path2="hangUp1Icon2" 
                        :inverted="!inverted" 
                        class="control-button-background-color nudge-down-1"
                        :class="{'extra-margin-top' : mobile}"
                        style="border: 2px solid white"
                        />
                    
            </div> 

        </div>

    </div>
    <div v-if="mobile" class="session-status-info d-flex align-items-start justify-content-start px-3" >
        <div v-if="questionSent" 
            class="smallFont mx-1 red-caps"
            :class="{'display-none': hideSessionStatusUpdate}">
                {{ sessionStatusUpdate }}                 
            </div>
            <Loader v-if="questionSent && !hideSessionStatusUpdate" class="mx-3" />
        <div v-if="!questionSent"  @click='putInFocus' class="mediumFont mx-1"></div>      
    </div>
  

</template>

<script>

import { ref, onMounted, watchEffect } from 'vue'
import ControlButton from '../components/ControlButton.vue'
import svgpath from '../composables/getSVGpath.js'
import isMobileDevice from '../composables/mobileDevice'
import stopVideoStream from '../composables/stopVideoStream.js'
import Indicator from  '../components/Indicator.vue'
import FACodeSolid from '../components/icons/FACodeSolid.vue'
import Loader from  '../components/Loader.vue'
import FullScreen from '../components/icons/FullScreen.vue'
import PictureInPicture from '../components/icons/PictureInPicture.vue'
import ScreenShare from '../components/icons/ScreenShare.vue'
import getPagePath from '../composables/getPagePath.js'

export default {

    components: { ControlButton, Indicator, FACodeSolid, Loader, FullScreen, PictureInPicture, ScreenShare, ScreenShare },

    props: [ 'putInFocus', 'sendQuestion', 'sendMobileButtonHidden', 'questionSent', 'sessionStatusUpdate', 'page', 'showTimer' ],

    setup(props){

        const video_selected = ref(true)
        const connectionType = ref('video')
        const video_call = ref('video call')
        const audio_call = ref('voice call')
        const mobile = ref(null)
        const hangUpButton = ref(false)
        const sendButton = ref(false)
        const viewBox = ref('-175 -100 800 900')
      
        // THE INDEX REPRESENTS THE NUMBER OF PATHS NEEDED TO MAKE-UP THE ICON (SOME HAVE 1 PATH OTHERS HAVE SEVERAL PATHS)
        const sendWriteIcon = svgpath('sendWriteIcon', 0)
        const paperPlaneIcon = svgpath('paperPlaneIcon', 0)
        const videoSolidIcon = svgpath('videoSolidIcon', 0) 
        const microphoneLineIcon = svgpath('microphoneLineIcon', 0)
        const microphoneLineIcon2 = svgpath('microphoneLineIcon', 1)
        const hangUp1Icon = svgpath('hangUp1Icon', 0) // PATH 1
        const hangUp1Icon2 = svgpath('hangUp1Icon', 1) // PATH 2
        const call = svgpath('call', 0) // PATH 2
        const videoCamLines = svgpath('videoCamLines', 0)
        const videoOff  = svgpath('videoOff', 0)
        const videoOff2  = svgpath('videoOff', 1)
        const codeIcon  = svgpath('codeIcon', 0)
        const fullScreenIcon = svgpath('fullScreen', 0)
        const fullScreenIcon2 = svgpath('fullScreen', 1)

        const camIconPath1 = ref(videoCamLines)
        const camIconPath2 = ref(null)

        const video_icon_path = ref(videoSolidIcon)
        const inverted = ref(false)
        const fill = ref('white')
        const hideSessionStatusUpdate = ref(false)
        

        const hideButtonsOnSessionEnd = () => {

            hangUpButton.value = false
            sendButton.value = false
            props.showTimer = false
            props.sendMobileButtonHidden = false

        }

        

        const selectConnectionType = () => {

                if(video_selected.value === true){
                    video_selected.value = false
                    camIconPath1.value = videoOff
                    camIconPath2.value = videoOff2
                    console.log('video_selected.value', video_selected.value)

                } else {
                    video_selected.value = true
                    camIconPath1.value = videoCamLines
                    camIconPath2.value = null
                    console.log('video_selected.value', video_selected.value)

                }
            }

        // const stopVideoStreamFromAnswerPage = () => {

        //     console.log("stopVideoStreamFromAnswerPage")

        // }

        watchEffect(() => {

            // if(props.sendMobileButtonHidden === false) {sendButton.value = true }
            if(props.sessionStatusUpdate === 'Connected'){ 
                hangUpButton.value = true, 
                sendButton.value = false 
                hideSessionStatusUpdate.value = true
                }
            
        })

        onMounted(() => {

            mobile.value = !isMobileDevice()
      

        })

        return {sendWriteIcon, paperPlaneIcon, microphoneLineIcon, microphoneLineIcon2, video_selected, connectionType, video_call, audio_call, selectConnectionType, video_icon_path, inverted, fill, svgpath, isMobileDevice, mobile, hangUp1Icon, hangUp1Icon2, hangUpButton, sendButton, stopVideoStream, hideSessionStatusUpdate, hideButtonsOnSessionEnd, call, viewBox, videoSolidIcon, videoCamLines, videoOff, videoOff2, camIconPath1, camIconPath2, codeIcon, fullScreenIcon, fullScreenIcon2, getPagePath }

    }

}

</script>

<style scoped>

.connection-type-selected {
    color: #696969;
    font-weight: bold;
}

.connection-type-not-selected {
    color: #A0A0A0
}

.nudge {
    margin-left: 10px
}

.this-border {
    border: solid #AE2D68 2px
}

.this-opacity {
    opacity: 0.5;
}

.control-button-background-color {

    background-color: #ae2d68;
}

.this-border-color {
    border-color: #ae2d68;
}

.nudge-down-1 {
    padding-top: 15px;
    
}

.extra-margin-top{
    margin-top: 6vh
}


.display-none {
    display: none;
}

.rotate-30 {
    transform: rotate(-30deg)
}

.indicator-magin-video-icon {
    margin-right:75px;
}

.indicator-magin-voice-icon {
    margin-left:30px;
}

/* .nudge-up-small {
    padding-bottom: 20px
} */

.selection-type-font {
    font-size: x-small;
    /* font-weight: bold; */
}

.grey-text {
    color: grey
}

.selected-text {
    color: black;
    font-weight: bold;
    background-color: white;
}

.red-caps {
    text-transform: uppercase;
    color:grey
}

</style>