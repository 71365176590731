<template>

    <!-- <div class="display-4 p-5">APPLY FORM</div> -->

    <div class="d-flex justify-content-center"
        style="margin-top:50px;"
        v-if="!selectionMade"
    >
    
            <el-form class="marginTop">
                <div class="welcome fs-1"> Welcome {{ currentUserDisplayName }}</div>
                <p class="fw-bold m-4"> Which of these subjects are an expert in: </p>
                <el-button type="text" class="m-2" v-for="subject in theSubjects"
                    :key="subject"
                    @click="subjectClick(subject)"
                    :id="buttonID(subject)"
                > {{ subject }}
                </el-button>
               
                <p>
                   <el-button 
                    class="elbutton m-4" 
                    @click="createSubjectInputs()">next
                </el-button>
                </p>    
                
            </el-form>
          
    </div>

            <div v-else style="margin-top:100px;">
                <div class="row">
                <div class="col-sm-4"></div>
                <div v-show="showSubjects" class="col-sm-4">
                    <p class="m-4 fw-bold">Tell us what makes you an expert in these subjects?</p>
                    <p class="m-3">Examples:</p>
                    <div class="m-3">"My coding YouTube channel has over 30,000 subscribers." </div>
                    <div class="m-3">"I've been teaching Javascript for 10 years. I teach at schools and colleges, and have online clients all over the world." </div>
                    <p class="m-3">"I use React at work. It's a great framework, and I love teaching people how use it." </p>
                </div>
                <div class="col-sm-4"></div>
                </div>


                <div v-show="showSubjects" class="m-3">
                    <StatementInput              
                        v-for="imput in chosenSubjectsArray"
                        :minrows="2"
                        :maxrows="4"
                        :key="imput"
                        :subject="imput"
                        :maxlength="100" 
                        :paddingbottom="'30px'"           
                    />

                    <p>
                        <el-button 
                        class="elbutton my-1" 
                        @click="upateUserData('subjects', chosenSubjectsArray)">submit
                        </el-button>
                    </p>

                </div>

                <div v-show="!showSubjects">
                    <div>
                        <p class="m-4 fw-bold">
                            Please enter links to your social media (max 3). These will be forwarded to the callers after you speak to them.
                        </p>

                    </div>
                    <StatementInput              
                        v-for="imput in socialMediaPlatforms"
                        :key="imput"
                        :subject="imput"
                        :minrows="1"
                        :maxrows="1" 
                        :paddingbottom="'7px'"            
                    />  

                    <p>
                        <el-button 
                        class="elbutton my-1" 
                        @click="upateUserData('socials', socialMediaPlatforms)">submit
                        </el-button>
                    </p>   

                </div>           

            </div>

    


</template>

<script>

import firebase from 'firebase'
import { useRouter } from 'vue-router'
import { timestamp } from '../firebase/config'
import subjects from '../data/subjects.json'
import { ref } from 'vue'
import StatementInput from '../components/StatementInput.vue'



export default {

    components: { StatementInput },

    setup(){

        const db = firebase.firestore();

     
        const router = useRouter() // get a reference to our vue router
        const signedIn = ref(null)
        const theSubjects = ref(subjects)
        const currentUserEmail = ref(null)
        const currentUserDisplayName = ref(null)
        const registered = ref(false)
        const chosenSubjectsArray = ref([])
        const chosenSocialsArray = ref([])
        const selectionMade = ref(false)
        const clicked = ref(false)
        const showSubjects = ref(true)
        const statementinput = ref('')

        const socialMediaPlatforms = ref([
   
            "Facebook",
            "Instagram",
            "Twitter",
            "TikTok",
            "YouTube",
            "WeChat",
            "WhatsApp",
            "LinkedIn",
            "Snapchat",
            "Zoom",
            "Website",
            "Other"

        ])

        const buttonID = (button) => {
            return button + "button"
        }


        firebase.auth().onAuthStateChanged((user) => {

                    if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User

                    //   var uid = user.uid;
                    console.log("USER SIGNED IN")
                    signedIn.value = true
                    currentUserEmail.value = user.email

                    const docRef = db.collection("users").doc(user.email);

                        docRef.get().then((doc) => {
                        if (doc.exists) {
                            console.log("Document data first name:", doc.data().firstname);
                            currentUserDisplayName.value = doc.data().firstname;

                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                    }).catch((error) => {
                        console.log("Error getting document:", error);
                    });


                    // ...
                    } else {
                    // User is signed out
                    // ...
                    console.log("NO USER SIGNED IN")
                    signedIn.value = false
                    }
            });

        

        const register = () => {

            firebase

                    .auth() // get the auth api
                    .createUserWithEmailAndPassword(email.value, password.value) // need .value because ref()
                    .then((data) => {
                    console.log('Successfully registered!');
                    
                    registered.value = true 
                    })
                    .then(onSuccess => {
                        console.log("SUCCESS")
                        createUserProfile(email.value, firstName.value, lastName.value, tel.value)
                        router.push('/validatephoneno')
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });
                }


        const createUserProfile = (email, firstName, lastName, tel) => {

                    const uid = firebase.auth().currentUser.uid

                    const phone_verification_code = () => {
                        return Math.floor(Math.random() * 900000) + 100000;
                        }
                

                    db.collection("users").doc(email).set({
                        firstname: firstName,
                        lastname: lastName,
                        email: email,
                        // tel: tel,
                        uid: uid,
                        instructor: false,
                        joined: timestamp(),
                        phone_verification_code: phone_verification_code()
                    })
                    .then(() => {
                        console.log("Document successfully written!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

            }

         
        const signOut = () => {

            firebase.auth()
                    .signOut()
                    .then((data) => {
                    console.log('Successfully Sign-out!');
                    router.push('/') // redirect to the feed
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }


        const subjectClick = (theSubject) => {

            chosenSubjectsArray.value.push(theSubject)
            console.log("chosenSubjectsArray: ", chosenSubjectsArray.value)
            let ID = theSubject + "button"
            document.getElementById(ID).classList.add("clickedClass")

        }

        const createSubjectInputs = () => {
            selectionMade.value = true
        }



        const upateUserData = (databaseReference, array) => {
           
            array.forEach(element => {

                let response = document.getElementById(element)
                if(response.value !== ''){ 
                    // here push to Firestore to update user profile
                    console.log( element + "Answer = " + response.value )

                                            // get current user here
                     db.collection("users").doc(currentUserEmail.value).set({

                    // reference is either 'subjects' or 'socials'
                         [databaseReference]:{

                             [element]:response.value

                         }
     
                    },{merge:true}) // update with 'set' then 'merge:true' to prevent overwrites.
                    .then(() => {
                        console.log("Document successfully udpated!");
               
                        showSubjects.value = false

                        if(array === socialMediaPlatforms.value){
                            router.push("/")
                        }
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

                    }

            });  

        }



        return { registered, register, router, createUserProfile, signOut, 
        signedIn, theSubjects, subjectClick, clicked, buttonID, socialMediaPlatforms,
        upateUserData, currentUserEmail, currentUserDisplayName, chosenSubjectsArray, chosenSocialsArray, createSubjectInputs, selectionMade,
        statementinput, showSubjects,  }

    }


}

</script>

<style scoped>

/* button {
    background-color: coral;
    color:cyan
} */

.welcome {
    font-size: 200%;
}

.marginTop {
    margin-top: 50px
}

.myform {
    width: 300px;
}

.elbutton {
    width:200px
}

.myborder {
    border:red;
    border-width: 3px;
}

.clickedClass {
    color:red
}



</style>