import { ref, onMounted } from 'vue'

const useConfirmConnectionType = ( isVideoConnection ) => {

    const defaultSelection = ref(true) // default selection is 'video'

    if( defaultSelection.value === isVideoConnection){
        console.log("USE HAS NOT CHANGED DEFAULT CONNECTION 'VIDEO'")
   
            // let check = confirm("Click 'OK' to confirm video 'ON'. Click 'Cancel' to turn video 'OFF'")
            let check = confirm("Click 'OK' to confirm video 'ON' for a video call.\nClick 'Cancel' to turn video 'OFF' for a voice only call.")

            if(check === true){
                console.log("Video connection CONFIRMED by student. Use 'Video' connection")
            } else {
                console.log("Video connection CANCELLED by student. Turn video off, and use 'Audio Only' connectiion")
                isVideoConnection = false
            }

    } else {
        console.log("USE HAS CHANGED THE CONNECTION TO 'VOICE ONLY")
    }


    return { defaultSelection }

}

export default useConfirmConnectionType