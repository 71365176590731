<template>

           
             <div class="col-sm-12 d-flex flex-column"
                :class="{'image-padding-desktop': isMobileDevice(), 'image-padding-mobile text-start': !isMobileDevice()}"
             >
                <div class="display-6 p-5">Enter a question or request....</div>
              <img :src="pic1" class="img-fluid">
            </div>
            
            <div class="col-sm-12 d-flex flex-column"
                :class="{'image-padding-desktop': isMobileDevice(), 'image-padding-mobile text-start': !isMobileDevice()}"
            >
                <div class="display-6 p-5">...and another user will answer, by video or voice, in moments.</div>
              <img :src="pic2" class="img-fluid">
            </div>
            <div class="display-6"
            :class="{'image-padding-desktop': isMobileDevice(), 'image-padding-mobile': !isMobileDevice()}"
            >That's it!.</div>


            <div class="col-sm-5">
              <h2 class="headingFont my-4 bold-font">{{ theHeading }}</h2>
              <div class="display-6 my-4">{{ furtherinfo }}</div>
                <div class="x-large-font">{{ paragraph }}</div>
                <ul class="noBullets p-1 x-large-font">
                    <li v-for="point in bulletpoints" :key="point">
                    {{ point }}
                    </li>
         
                </ul>
            </div>
        
  

</template>

<script>

import { ref, onMounted} from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'

export default {

    props: ['pic1', 'pic2', 'bulletpoints', 'theHeading', 'furtherinfo', 'paragraph', 'width'],

        setup(){

        const mobileDevice = ref(false)
        // const points = ref(["one", "two", "three"])
        

        onMounted (() => {
            mobileDevice.value = isMobileDevice()
        })


        return { isMobileDevice, mobileDevice }
    }

}
</script>

<style>

.padding {
    padding-top:10%; 
    padding-bottom:10%
}

.textColor{
    color:white
}

.largerFont {
    font-size: 135%
}

.image-padding-desktop {
    padding:5% 20% 5% 20%
}

.image-padding-mobile {
    padding:10% 0% 10% 0%
}





</style>