<template>

<!-- <div class="pb-2 border-bottom"> -->

<div class="border border-secondary d-flex align-items-center my-2 mx-4 px-2 borderRadius25px white-background" 
    :class="{'chat-input-width-mobile':!isMobileDevice(), 'chat-input-width-desktop':isMobileDevice()}"
    > 
    <svg class="icon m-2" width="15" height="15" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" data-v-365b8594=""><path fill="gray" :d="chatIcon"></path></svg>
        <el-input   
                        v-model="chatMessage"
                        :input-style="{
                                
                                border: '0px',
                                // fontSize: 'small',
                                // paddingTop:'15px',
                                // paddingBottom:'15px',
                                borderRadius:'20px',
                                backgroundColor: 'white',
                                minWidth: '100%'

                    }"
                 />

            
    <!-- <svg class="icon m-2" width="15" height="15" viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg" data-v-365b8594=""><path fill="gray" :d="paperPlaneIcon"></path></svg> -->
    <el-button @click="sendChatMessage" type="text" size="small" class="mx-2">send</el-button>

  </div>

  <!-- </div> -->


</template>

<script>

import { ref, watchEffect } from 'vue'
import svgpath from '../composables/getSVGpath.js'
import isMobileDevice from '../composables/mobileDevice.js'
import firebase from 'firebase'
import { timestamp } from '../firebase/config'
import useCurrentSessionID from '../composables/useCurrentSessionID.js'

export default {

    props: ['sessionID', 'isAskPage'],

    setup(props){

        const chatMessages = firebase.firestore().collection("chatMessages")
        const sessionsCollection = firebase.firestore().collection("sessions")
        const chatIcon = ref(svgpath('chatMessageIcon', 0))
        const paperPlaneIcon = ref(svgpath('paperPlaneIcon', 0))
        const chatMessage = ref('')
        const uid = firebase.auth().currentUser.uid
        const currentUserEmail = firebase.auth().currentUser.email
        const currentUserFirstName = ref(null)
        const session_ID_Array = ref([])
        const currentSessionID = ref(null)
        const role = ref(null)
        const previousID = ref("0")
        const chatRecipientUID = ref(null)
        const chatRecipientFirstName = ref(null)
        const chatSenderFirstName = ref(null)
        const dateNow = Date.now()
        const chatMessageSenderRole = ref(null)


  

        const sendChatMessage = () => {

            const theCorrectSessionID = useCurrentSessionID()

            console.log("currentPage isAskPage", props.isAskPage)
            console.log("theCorrectSessionID", theCorrectSessionID)

            if(props.isAskPage === true){
                chatMessageSenderRole.value = "student" 
            } else {
                chatMessageSenderRole.value = "teacher" 
            }

            

            sessionsCollection.doc(theCorrectSessionID).get().then((doc) => {

                console.log("Working here!!")

                if (doc.exists) {
                    
                    console.log("doc.exists")
                    const { sessionID, ...rest} = doc.data()
              
                    if(uid === rest.studentUID){
                        console.log("I AM STUDENT - MESSAGE", chatMessage.value)
                        // current user is studnet 
                        chatSenderFirstName.value = rest.studentFirstName
                        chatRecipientUID.value = rest.teacherUID
                        chatRecipientFirstName.value = rest.teacherFirstName
                    } else {
                        console.log("I AM TEACHER - MESSAGE", chatMessage.value)
                        // current user is teacher 
                        chatSenderFirstName.value = rest.teacherFirstName
                        chatRecipientUID.value = rest.studentUID
                        chatRecipientFirstName.value = rest.studentFirstName


                    }

                chatMessages.add({

                    chatMessage: chatMessage.value,
                    chatSenderEmail: currentUserEmail,
                    chatSenderUID: uid,
                    chatSenderFristName: chatSenderFirstName.value,
                    chatMessageSenderRole: chatMessageSenderRole.value,
                    sessionID: theCorrectSessionID,
                    recipientFirstName: chatRecipientFirstName.value,
                    recipientUID: chatRecipientUID.value,
                    timeChatSentInMillices: dateNow,
                    timestamp: timestamp(),
                    
                })

                chatMessage.value = ""


                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });


            
        }

        

        return { chatIcon, paperPlaneIcon, isMobileDevice, chatMessage,  sendChatMessage, session_ID_Array, currentSessionID, currentUserFirstName, role, previousID, chatRecipientUID, chatRecipientFirstName, chatSenderFirstName, chatMessageSenderRole, useCurrentSessionID }
    }

}
</script>

<style>

.chat-input-width-mobile {
    width: 95vw;
}

.chat-input-width-desktop {
    width: 50vw;
}

</style>