<template>
    <div class="row d-flex align-items-center"
        :class="{padding: mobileDevice}"
    >
            <div class="col-sm-2"></div>
              <div v-if="mobileDevice" class="col-sm-4 d-flex align-items-start">
                <Phone />
              </div>
                    <div class="col-sm-1"></div>
                    <div class="col-sm-3 text-start testShadow p-5" 
                    :class="{borderRadius25px: mobileDevice, grad2: mobileDevice }"
                    >
                        <h2 class="headingFont bold-font">HOW TO GET AN EXPERT</h2>
                        <hr>
                        <h4 class="headingFont">Text "Help" to</h4>
                        <h4 class="headingFont bold-font">+1 860 EXPERT 5</h4>
                        <h4 class="headingFont my-4">Tell us what language, program or framework you're using</h4>
                        <h4 class="headingFont my-4">Tell us your question - we'll forward it our experts</h4>
                        <h4 class="headingFont my-4">You'll receive a message from one or more experts.</h4>
                        <h4 class="headingFont my-4">Choose who want to speak to, and select Zoom meeting or phonecall.</h4>
                    <div class="large-font"></div>
                </div>
                <div v-if="!mobileDevice" class="col-sm-4 d-flex align-items-start">
                    <Phone class="phonePaddingMobile"/>
              </div>      
    </div>
  
</template>

<script>

import Phone from './Phone.vue'
import { ref, onMounted} from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'

export default {

    components: { Phone },

    setup(){

        const mobileDevice = ref(false)

        onMounted (() => {
            mobileDevice.value = isMobileDevice()
        })


        return { isMobileDevice, mobileDevice }
    }

}
</script>

<style>
.phonePaddingMobile {
    padding-left:10%;
    padding-right:10%;
}

</style>