<template>
    <Nav />

    <div class="marginTop" v-if="!signedIn">

        <p> <input type='text' placeholder="First Name" v-model='firstName'/> </p>
        <p> <input type='text' placeholder="Last Name" v-model='lastName'/> </p>
        <p> <input type='text' placeholder="Email" v-model='email'/> </p>
        <p> <input type='tel' placeholder="Tel" v-model='tel'/> </p>
        <p> <input type='password' placeholder="Password" v-model='password'/> </p>

        <p> <button @click="register"> register </button> </p>
        <p> <button @click="sigIn"> Sign In </button> </p>
        
    </div>

     <div class="marginTop" v-else >
        <p> <button @click="signOut"> Sign Out </button> </p>
        <div class="welcome"> Welcome {{ currentUserDisplayName }}</div>
        <p> Are you an expert in any of the following: </p>
        <button class="mx-3" v-for="subject in theSubjects"
            :key="subject"
            @click="subjectClick(subject)"
        > {{ subject }}</button>
        <button @click="upateUserSubjects()">submit</button>
    </div>
   
   

</template>

<script>

import { ref, onMounted } from 'vue'
import firebase from 'firebase'
import { useRouter } from 'vue-router'
import { timestamp } from '../firebase/config'
import subjects from '../data/subjects.json'
import Nav from '../components/Nav.vue'

export default {

    components: { Nav },

    setup(){

        const db = firebase.firestore();

        const firstName = ref('')
        const lastName = ref('')
        const email = ref('')
        const password = ref('')
        const tel = ref(null)
        const router = useRouter() // get a reference to our vue router
        const signedIn = ref(null)
        const theSubjects = ref(subjects)
        const currentUserEmail = ref(null)
        const currentUserDisplayName = ref(null)

        firebase.auth().onAuthStateChanged((user) => {

                if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                //   var uid = user.uid;
                console.log("USER SIGNED IN")
                signedIn.value = true
                currentUserEmail.value = user.email

                const docRef = db.collection("users").doc(user.email);

                    docRef.get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data first name:", doc.data().firstname);
                        currentUserDisplayName.value = doc.data().firstname;

                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });




                // ...
                } else {
                // User is signed out
                // ...
                console.log("NO USER SIGNED IN")
                signedIn.value = false
                }
        });

        

        const register = () => {

            firebase

                    .auth() // get the auth api
                    .createUserWithEmailAndPassword(email.value, password.value) // need .value because ref()
                    .then((data) => {
                    console.log('Successfully registered!');
                    // router.push('/') 
                    })
                    .then(onSuccess => {
                        console.log("SUCCESS")
                        createUserProfile(email.value, firstName.value, lastName.value, tel.value)
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });
                }


        const createUserProfile = (email, firstName, lastName, tel) => {

                    const uid = firebase.auth().currentUser.uid

                    db.collection("users").doc(email).set({
                        firstname: firstName,
                        lastname: lastName,
                        email: email,
                        tel: tel,
                        uid: uid,
                        instructor: false,
                        joined: timestamp()
                    })
                    .then(() => {
                        console.log("Document successfully written!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

            }

         
        const sigIn = () => {

            firebase.auth()
                    .signInWithEmailAndPassword(email.value, password.value)
                    .then((data) => {
                    console.log('Successfully Signed In!');
                    })
                    // .then(onSuccess => {
                    //     signedIn.value = true
                    // })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }

        const signOut = () => {

            firebase.auth()
                    .signOut()
                    .then((data) => {
                    console.log('Successfully Sign-out!');
                    router.push('/') 
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }

        const subjectClick = (theSubject) => {

                    let text = document.createTextNode("What makes you an expert in " + theSubject + "?")
                    let textBox = document.createElement("DIV");
                    textBox.appendChild(text)
                    textBox.classList.add("m-3")
                    document.body.appendChild(textBox)

                    let answerBox = document.createElement("input");
                    answerBox.placeholder = "Answer"
                    answerBox.classList.add("mb-3", "mx-3")
                    answerBox.id = theSubject + "Answer"
                    answerBox.type="text"
                    answerBox.maxLength = 200 
                    document.body.appendChild(answerBox)

        }

        const upateUserSubjects = () => {
           
            theSubjects.value.forEach(element => {

                let response = document.getElementById(element + "Answer")
                if(response){ 
                    // here push to Firestore to update user profile
                    console.log( element + "Answer = " + response.value )

                                            // get current user here
                     db.collection("users").doc(currentUserEmail.value).collection("subjects").doc(element).set({
                        response: response.value
                        
                    })
                    .then(() => {
                        console.log("Document successfully udpated!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

                    }

            });
        }



        return { register, email, password, router, createUserProfile, signOut, 
        sigIn, firstName, lastName, tel, signedIn, theSubjects, subjectClick, 
        upateUserSubjects, currentUserEmail, currentUserDisplayName }

    }


}

</script>

<style scoped>

button {
    background-color: coral;
    color:cyan
}

.welcome {
    font-size: 400%;
    color:red;
}

.marginTop {
    margin-top: 50px
}

</style>