import { ref } from 'vue'
import firebase from 'firebase'
import { timestamp } from '../firebase/config'


    const questionsCollection = firebase.firestore().collection("questions");
    const usersCollection = firebase.firestore().collection("users");
    const user = ref(null) // the current user (responder)
    const senderPhoneNo = ref(null)
    const senderFristName = ref(null) // sender first name may not be available, if user texts before signing-up
    const questionPosted = ref(null)
    const questionSubject = ref(null)
    const subjectStatement = ref(null)

    const notifyStudentExpertIsAvailable = (thePostID, emailNeeded) => {

        console.log("SINGLE POST ID", thePostID)

        // This first line disables this function if current user has already clicked on this post.
        // 'emailNeeded' returns 'true' if the current user's email is present as a key in the Firestore 'questionDoc' for this question.
        // It returns 'undefined' if the email is not present, meaning the expert has not already clicked on this post, so the function
        // can run.
        if(emailNeeded === true){return}

        user.value = firebase.auth().currentUser;

        questionsCollection.doc(thePostID).get().then((doc) => {
       
                  if (doc.exists) {

                      const { sender, senderTel, question, subject  } = doc.data();

                      console.log("Sender Name:", sender);
                      console.log("Sender Tel:", senderTel);
                      senderPhoneNo.value = senderTel;
                      questionPosted.value = question;
                      questionSubject.value = subject;
                      senderFristName.value = sender; // sender first name may not be available, if user texts before signing-up

                  } else {
                      console.log("No such document!");
                  }
                  }).catch((error) => {
                      console.log("Error getting document 1", error);
                  });

         

          // Here current user details are gathered from Firestore "users" collection, by checking their email address (user.value.email)
          // users are ordered (stored) by email address in Firestore
          usersCollection.doc(user.value.email).get().then((doc) => {

                  if (doc.exists) {

                        const { subjects, firstname, tel, email } = doc.data()
  
                        let subjectArray = Object.keys(subjects) //an array of all the expert's subjects [ "Vue", "React", etc ]

                        if(subjectArray.includes(questionSubject.value)){

                              let index = subjectArray.indexOf(questionSubject.value)
                              subjectStatement.value = Object.values(subjects)[index]

                              console.log(firstname + "'s " + questionSubject.value +  " statement: '" + subjectStatement.value + "'")
                      }

                      createResponse( thePostID, tel, email, firstname, senderPhoneNo.value, questionPosted.value, questionSubject.value, subjectStatement.value )

                  } else {
                      console.log("No such document 2");
                  }
                  }).catch((error) => {
                      console.log("Error getting document:", error);
                  });
        

        }


          // Here info about the responder (current user, who has clicked on the question to respond to it),
          // is sent to the Firestore "responders" collection inside the question doc he responding to
          const createResponse = (thePostID, responderPhoneNo, responderEmailAddress, responderFirstName, senderTelephone, theQuestionPosted, theQuestionSubject, theSubjectStatement ) => {

                  const respondersCollection = questionsCollection.doc(thePostID).collection("responders")
                          
                          const getNoOfResponders = async () => {
                              const snapshot = await respondersCollection.get();
                              const count = snapshot.size;
                                  respondersCollection.doc(user.value.email).set({
                                         responderNum: count
                                  },{merge:true})
                                }      
                  
                  respondersCollection.doc(user.value.email).set({

                          responderName: responderFirstName,
                          responderTel: responderPhoneNo,
                          responderEmail: responderEmailAddress,
                          senderTel: senderTelephone,
                          questionSent: theQuestionPosted,
                          questionSubject: theQuestionSubject,
                          responderStatement: theSubjectStatement,
                          timeOfResponse: timestamp()
                          

                  })


                  questionsCollection.doc(thePostID).set({
                        hasResponders: true,
                        [responderEmailAddress]:true
                        },{merge:true})


                  .then(() => {             
                        getNoOfResponders();
                        console.log(responderFirstName + " is responding to " + theQuestionSubject + " question: '" + theQuestionPosted + "'");           
                    })

                   .then(() => { 
                        // A record of all the posts the expert has responded to is updated here.
                        // PostID's are added to firestore array 'respondedTo'
                        // info on updating firestore arrays here: https://firebase.google.com/docs/firestore/manage-data/add-data
                        let docRef = usersCollection.doc(user.value.email) 
                        console.log("thePostID thePostID thePostID", thePostID)
                        docRef.update({
                            respondedTo: firebase.firestore.FieldValue.arrayUnion(thePostID)
                        })    

                    })

                  .catch((error) => {console.log("Error writing document: ", error);
                  });

          }

      
  export default notifyStudentExpertIsAvailable