<template>
    <el-card 
    shadow="never"
    :body-style="{
      padding: '0px'
    }"
    style="margin:-1px"
    >

      <Nav class="example" />  
      <SalesPitch1 class="example" />
      <PageFooter class="example" />
      <NavBottom/>
    </el-card>
</template>

<script>


import Nav from '../components/Nav.vue'
import NavBottom from '../components/NavBottom.vue'
import SalesPitch1 from '../components/SalesPitch1.vue'
import PageFooter from '../components/PageFooter.vue'

export default {

  components: { SalesPitch1, Nav, PageFooter, NavBottom },

  setup() {


  }




}
</script>

<style>


.example {

  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>