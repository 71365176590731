import firebase from 'firebase'
import { timestamp } from '../firebase/config'
const screenShareAccessCollection = firebase.firestore().collection("screenShareAccess")


const twilioScreenShareConnect = async (token, roomID) => {
           
    const { connect, LocalVideoTrack } = Twilio.Video;
    const stream = await navigator.mediaDevices.getDisplayMedia(
       { mediaSource: 'window'}
    );
    const screenTrack = new LocalVideoTrack(stream.getTracks()[0]);

    const room = await connect(token, {
        // name: 'screenshare',
        tracks: [screenTrack],
        name: roomID, // room ID
    });
    

}

const useToken = (roomID) => {

    screenShareAccessCollection.doc(roomID).onSnapshot((doc) => {

            try {
                const { accessToken } = doc.data()
                if(accessToken) {

                        console.log("screenshare access token arrived!!")
                        document.getElementById("screenshare").remove()
                        console.log(accessToken)
                        twilioScreenShareConnect(accessToken)          
                    }
            } catch (err){ console.log(err)}
            });
            }

const screenShare = (roomID, uid, personFirstName) => {

    screenShareAccessCollection.doc(roomID).set({

        firstName: personFirstName,
        RoomID: roomID,
        uid: uid,
        datenow: Date.now(),
        timestamp:timestamp(),
        roomstatus: "connected"

    })

    useToken(roomID);

}

export default screenShare