<template>
    <div class="row d-flex align-items-center this-page-height grad">
          <!-- :class="{padding:mobileDevice, onehunderedviewheight:mobileDevice }"> -->
            <!-- <div class="col-sm-2"></div> -->
          <div class="d-flex align-items-start py-5">
            <div class="slogan-style">Instant Help from a Real Person</div>
          </div>
              <div>
                
                <img :src="src" class="img-fluid" :class="{ 'extra-margin-mobile': !mobileDevice, 'p-4': mobileDevice}">
              </div>
              <!-- <div class="col-sm-2"></div> -->


            <div class="col-sm-3 text-start p-5 white-font">
              <h2 class="headingFont bold-font" :slogan="slogan">{{ slogan }}</h2>
              <h5 class="headingFont my-4" :paragraph1="paragraph1"> {{ paragraph1 }}</h5>
              <h5 class="headingFont my-4" :paragraph2="paragraph2"> {{ paragraph2 }}</h5>
              <h5 class="headingFont my-4" :paragraph3="paragraph3"> {{ paragraph3 }}</h5>
            </div>      
    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'
import MobileExtra from './MobileExtra.vue'

export default {

    props: ['src', 'slogan', 'paragraph1','paragraph2', 'paragraph3'],

    components: { MobileExtra },

    setup(){

        const mobileDevice = ref(false)

        onMounted (() => {
          mobileDevice.value = isMobileDevice()
        })

        return { isMobileDevice, mobileDevice  }

    }

}
</script>

<style>

.padding {
    /* padding-top:11%; 
    padding-bottom:10% */
    padding: 25%
}

.extraPadding {

  padding-top:10%; 
  padding-bottom:10%

}

.this-page-height {
  min-height: 100vh;
}

.extra-margin-mobile {
  margin-top: 15vh
}



</style>