<template>

  <nav class="navbar navbar-expand-lg mobile-nav-background fixed-top d-flex justify-content-between align-items-center smallFont px-3" 
        
        style="min-height:50px"
        >
      <router-link :to="{ name: 'Ask' }">
        <img :src="theLogoWhite" class="logo-width-mobile">
        <!-- <div class="white-font temp-logo">HelpSesh</div> -->
      </router-link> 
      <!-- <div class="d-flex justify-content-end px-2" style="position: fixed; margin-top:12vh; color:red; font-weight: bold; width:96vw">
        <div>BETA</div>
      </div> -->

      <div>
        <div v-if="showRoute">
            <router-link class="mx-2 white-font" :to="{ name: 'SignIn' }">SIGN-IN</router-link> 
            <router-link class="mx-2 white-font" :to="{ name: 'SignUp' }">SIGN-UP</router-link>`
        </div> 

        <div v-else class="d-flex align-items-center filter-invert">
                <div>{{ firstName }}</div>
                  <router-link class="px-1 nudge-right" :to="{ name: 'Profile' }">
                    <ProfileIcon style="height: 23px" />
                  </router-link>
                  
              <a class="cursor-pointer" @click="signOut()"><FASignOut/></a>   
        </div>     
      </div>
         
  </nav>
  





</template>

<script>

import firebase from 'firebase'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'
import ProfileIcon from '../components/icons/ProfileIcon.vue'
import FASignOut from '../components/icons/FASignOut.vue'
import getPagePath from '../composables/getPagePath.js'
import MenuItems from '../components/MenuItems.vue'
import { useUserSignedIn } from '../composables/useUserSignedIn.js'



export default {

    components : { ProfileIcon, MenuItems, FASignOut },


    setup(){

            const router = useRouter()
            const showRoute = ref(null)
            const topNav = ref(true)
            const hideWhiteBG = ref(false)
            const firstName = ref(null)
            const hideMobileNav = ref(true)
            const theLogo = ref(require('../assets/Headset_Logo1.png'))
            const theLogoWhite = ref(require('../assets/HelpSeshQuestionMarkLogo1_BETA.png'))

            
            const hideMobileNavClick = () => {
              hideMobileNav.value = false
            }

            const isAnswerPage = () => {
              if(getPagePath() === 'answer'){
                return true
              }
            }

            const getFirstName = (email) => {

                  const docRef = firebase.firestore().collection("users").doc(email);

                        docRef.get().then((doc) => {      
                            if (doc.exists) {
                                let { firstname } = doc.data()
                                firstName.value = firstname
                              
                            } else {
                                console.log("No such document")
                            }

                        })

                  }

            const signOut = () => {

                  firebase.auth()
                          .signOut()
                          .then((data) => {
                          console.log('Successfully Sign-out!');
                          router.push('/') // redirect to the feed
                          })
                          .catch(error => {
                          console.log(error.code)
                          alert(error.message);
                          });

                  }

            const isUserSignedIn = () => {

                          firebase.auth().onAuthStateChanged((user) => {
                              if (user) {
                              showRoute.value = false 
                              hideWhiteBG.value = false
                            } else {
                              showRoute.value = true
                              hideWhiteBG.value = true
                            }
                            });

                            }

            onMounted(() => {

                isUserSignedIn()

                firebase.auth().onAuthStateChanged((user) => {

                              if (user) {
                                
                                const email = user.email

                                getFirstName(user.email)
                            
                            } else {
                            
                            }
                      });

            })

            


      return { signOut, isUserSignedIn, showRoute, topNav, isMobileDevice, hideWhiteBG, getFirstName, firstName, getPagePath, isAnswerPage, hideMobileNav, hideMobileNavClick, theLogo, theLogoWhite, useUserSignedIn }
    }


  }




</script>

<style scoped>
.boxShadow {
-webkit-box-shadow: 0px 2px 26px -18px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 2px 26px -18px rgba(0,0,0,0.75);
box-shadow: 0px 2px 26px -18px rgba(0,0,0,0.75);
}

.navRightMargin {
  margin-right: 35px;
}

.navLefttMargin {
  margin-left: 30px;
}

.transparentBackground {
  background-color: transparent;
}

.whiteBackground {
  background-color: white;
}

.displayNone {
  display: none;
}

.opacityZero {
  opacity: 0;
}

.borderBotton {
  border-bottom: 1px solid #dcdcdc
}

.invert {
  filter: invert(100%);
  width: 20px;
}

.hideNav {
  background-color: transparent;
  color: transparent
}

.cursor-pointer {
  cursor:pointer
}

.nudge-right {
  margin-right: 2vw
}

.mobile-nav-background {
  background-color: #280659;
}

.temp-logo {
  text-decoration: none;
  font-family: 'Russo One', sans-serif;
  font-size: large;
  
}

</style>