
import firebase from 'firebase'

    
   const getFirstName = (email) => {

    const docRef = firebase.firestore().collection("users").doc(email);

    docRef.get().then((doc) => {      
        if (doc.exists) {
            let { firstname } = doc.data()
            console.log("firstname: " + firstname)
            return firstname
           
        } else {
            console.log("No such document")
        }

    })

  }


export default getFirstName