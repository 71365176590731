<template>
    <Nav />
    <ApplyForm />
</template>

<script>

import ApplyForm from '../components/ApplyForm.vue'
import LoginSignUp from '../components/LoginSignUp.vue'
import Nav from '../components/Nav.vue'
import TestVModel from '../components/TestVModel.vue'


export default {

    components: { TestVModel, LoginSignUp, ApplyForm, Nav }

}
</script>

<style>

</style>