<template>
    <svg width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <path style="text-indent:0;text-align:start;line-height:normal;text-transform:none;block-progression:tb;-inkscape-font-specification:Sans" d="M 6 6 C 3.8026661 6 2 7.8026661 2 10 L 2 28 C 2 30.197334 3.8026661 32 6 32 L 16 32 L 16 40 C 16 42.197334 17.802666 44 20 44 L 44 44 C 46.197334 44 48 42.197334 48 40 L 48 22 C 48 19.802666 46.197334 18 44 18 L 34 18 L 34 10 C 34 7.8026661 32.197334 6 30 6 L 6 6 z M 6 8 L 30 8 C 31.116666 8 32 8.8833339 32 10 L 32 18 L 20 18 C 17.802666 18 16 19.802666 16 22 L 16 30 L 6 30 C 4.8833339 30 4 29.116666 4 28 L 4 10 C 4 8.8833339 4.8833339 8 6 8 z M 20 20 L 32.8125 20 L 33.21875 20 L 44 20 C 45.116666 20 46 20.883334 46 22 L 46 40 C 46 41.116666 45.116666 42 44 42 L 20 42 C 18.883334 42 18 41.116666 18 40 L 18 31.1875 A 1.0001 1.0001 0 0 0 18 30.78125 L 18 22 C 18 20.883334 18.883334 20 20 20 z" overflow="visible" font-family="Sans"/>
    </svg>
</template>

<script>
export default {

}
</script>

<style>

</style>