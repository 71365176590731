<template>
  <div class="shadowFilter py-5" :class="{phoneCase:!mobileDevice}">   
        <div class="row d-flex justify-content-end">
            <div class="col-sm-1 studentbubble m-1 p-2" style="width:100px">Help</div>
        </div>
        <div class="row text-start">
            <div class="col-sm-1 expertbubble m-1 p-3 grad5" style="width:250px ">Welcome to Expert 5. Tell us which language, framework or program you're using - e.g. 'Javascript', 'Vue', 'Photoshop'</div>
        </div>
        <div class="row d-flex justify-content-end">
            <div class="col-sm-1 studentbubble m-1 p-2" style="width:100px">Vue</div>
        </div>
        <div class="row text-start text-start">
            <div class="col-sm-1 expertbubble m-1 p-3 grad5" style="width:250px">How we can help you with Vue? You can send a screenshot or a picture too.</div>
        </div>
        <div class="row d-flex justify-content-end">
            <div class="col-sm-1 studentbubble m-1 p-3 text-start" style="width:250px">Getting an error I don't understand. Too much to go through when I Google the problem. Just need someone to look at my code.</div>
        </div>
        <div class="row text-start justify-content-start">
            <div class="col-sm-10 expertbubble m-1 p-3 grad5">Ok. Contacting users. Please wait.</div>
        </div>      
  </div>


</template>

<script>

import { ref, onMounted } from 'vue'


export default {

    setup(){

        const mobileDevice = ref(false)

        onMounted(() => {

            if((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)){ 

              mobileDevice.value = true 

            }
          })


        return {mobileDevice}

    }


}
</script>

<style>

.phonewidth {
    width:100vvw

}
.phoneCase {
	width: 450px;
	height: auto;
	background-color: transparent;
	border-left: 20px solid #f54952;
	border-right: 20px solid #f54952;
	border-top: 65px solid #f54952;
	border-bottom: 65px solid #f54952;
	border-radius: 25px;
    padding-right: 20px;
    padding-left: 20px;
}

.studentbubble {
	background-color: #660f56;
	color: white;
	border-radius: 25px;
	font-size: medium;
	font-weight: bold;
}

.expertbubble {
	/* background-color: #f54952; */
	color: white;
	border-radius: 25px;
	font-size: medium;
	font-weight: bold;
}


</style>