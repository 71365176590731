<template>
  <div style="height:50px">
      <div style="width:90vw" class="text-start p-4">
      <small>**Expert 5 is an SMS based service. By texting our number you are opting-in to receive SMS messages from Expert 5. To stop receiving SMS messages, text “STOP” to our number, or sign-up / log-in at expert5.com and change your notification settings</small>
    </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>