<template>
  <div>
    <div>
      <!-- <p class="font-bold text-white text-lg">{{ currentTime.toLocaleString() }}</p> -->
      <p>{{ currentTime.toLocaleTimeString() }}</p>
      <p>{{ currentTime.toLocaleDateString() }}</p>
    </div>
  </div>
</template>
<script>
import useCurrentTime from "../composables/useCurrentTime.js";
export default {
  name: "CurrentTimeExample",
  setup() {
    const { currentTime } = useCurrentTime();
    console.log(useCurrentTime());
    return { currentTime };
  },
};
</script>