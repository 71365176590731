<template>
    <div class="border-radius-50percent answered-indicator"></div>
</template>

<script>
export default {

}
</script>

<style>

</style>