<template>
  <ul>
    <div
        v-for="(name, i) in names"
        :key="name"
        :ref="el => elements[i] = el"
        >
        <div>
        {{ name }}
        </div>


      <button @click="refClick(name, i)">click</button>
    </div>
  </ul>

  
</template>

<script>
import { ref, onMounted } from 'vue'
export default {
  setup () {
    const names = ref(['Matt', 'John', 'Jane'])
    const elements = ref([])
    
    const refClick = (string, index) => {

        if(string === "Jane"){
            console.log("i: ", index)
            elements.value[index].style.color = "red"
        }
        console.log("refClick")
        // elements.value[0].textContent = "FRED"
    }

    onMounted(() => {
      console.log("elements.value:", elements.value) // [li, li ,li]
      console.log("element:", elements.value[0]) // [li, li ,li]
    })
    return {
      names,
      elements,
      refClick
    }
  }
}
</script>