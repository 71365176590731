const getCurrentSessionID = (someVal) => {

    const current_session_ID = document.getElementById("current_session_ID")

            if(current_session_ID != null){
                console.log("current_session_ID", current_session_ID.textContent)
                current_session_ID.textContent = someVal
            }  

    }

export default getCurrentSessionID