<template>
    <div class="col-sm-1">
        <img :src="pic" :width="width">
    </div>
</template>


<script>

import { ref } from 'vue'
export default {

    props: [ 'pic', 'width', 'zindex'],

  
}

</script>

<style scoped>
    /* .dropShadow {
        filter: drop-shadow(-5px 0px 40px  black); 
        } */

</style>



