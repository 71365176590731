import _Space from 'element-plus/lib/el-space';
import firebase from 'firebase'
import { timestamp } from '../firebase/config'
import mobileDevice from '../composables/mobileDevice.js'
import getParticipantDetails from '../composables/getParticipantDetails.js'

const studentAccessCollection = firebase.firestore().collection("studentAccess");
const teacherAccessCollection = firebase.firestore().collection("teacherAccess");
const sessionsCollection = firebase.firestore().collection("sessions")


const desktop_video_dimensions = { 

        width: 1280,
        height: 720, 
        frameRate: 24 

    }
const mobile_video_dimensions = { 

        width: 720,
        height: 720, 
        frameRate: 24 

    }


const Video = Twilio.Video;

const twillioVideoConnect = (theAccessToken, theRoomDocRef, role, question_sent_from_mobile_device, isVideoConnection) => {

    console.log("@@@@@@@ isVideoConnection isVideoConnection isVideoConnection", isVideoConnection)
    
    console.log(" ********** is LOCAL user on mobile divice? ***********", !mobileDevice())

    let correct_dimensions;

    const get_video_dimensions = () => {

        if(role === 'teacher'){

            console.log("TEACHER correct_dimensions runs here")

            if(!mobileDevice() == true && question_sent_from_mobile_device == true){
                console.log("1 student & teacher both MOBILE. Use desktop_video_dimensions", desktop_video_dimensions)
                correct_dimensions = desktop_video_dimensions
            } else if (!mobileDevice() == false && question_sent_from_mobile_device == false){
                console.log("2 student & teacher both DESKTOP. Use desktop_video_dimensions",desktop_video_dimensions)
                correct_dimensions = desktop_video_dimensions
            } else if (!mobileDevice() == true && question_sent_from_mobile_device == false){
                console.log("3 teacher mobile, student desktop. USE mobile_video_dimensions", mobile_video_dimensions)
                correct_dimensions = mobile_video_dimensions
            } else if (!mobileDevice() == false && question_sent_from_mobile_device == true){
                console.log("4 teacher desktop, student mobile. USE mobile_video_dimensions",  mobile_video_dimensions)
                correct_dimensions = mobile_video_dimensions
            }


        } else { // IF 'role' = STUDENT

            if(!mobileDevice() == true){
                correct_dimensions = mobile_video_dimensions
            } else {
                correct_dimensions = desktop_video_dimensions
            }
        }

    }

    get_video_dimensions()


    let params

    const setParams = (isVideoConnection) => {

        if(isVideoConnection === true){

            console.log("VIDEO PARAMS")
            params = { 

                audio: true,
                name: theRoomDocRef,
                video: correct_dimensions
            
            } 
            
        } else {

            console.log("AUDIO ONLY PARAMS")
            params = { 

                audio: true,
                name: theRoomDocRef,
                // video: false
                
                }

            }
        }
    

    setParams(isVideoConnection)
    
    Video.connect(theAccessToken, params).then(room => {
        
        console.log(' ******* Connected to Room "%s"', room.name, ", FROM COMPOSABLE");
        const teacherVideoDiv = document.getElementsByClassName(theRoomDocRef)[0]
        const studentVideoDiv = document.getElementById("videoBox")

       
        ////////////////////////////////////////////////////////////
        /////////////////////// VIDEO FUNCTIONS ////////////////////
        ////////////////////////////////////////////////////////////
        function participantConnected(participant) {

        // CREATE 'SESSIONS' REF HERE
        sessionsCollection.doc(room.name).set({

            sessionStartInMillisecs:Date.now(),
            sessionStartTimeStamp: timestamp(),
            sessionID:room.name,
            sessionStatus: "connecting",

        })

            // HERE 'SESSIONS' DATABASE IS UPDATED WITH PARTICIPANTS DETAILS ONCE CONNECTED

                // getParticipantDetails(studentAccessCollection, sessionsCollection, room.name, role)
                // getParticipantDetails(teacherAccessCollection, sessionsCollection, room.name, role)
            
        
            /// DON'T DO THIS IS AUDIO ONLY CALL

            console.log('ONE ++++ Participant "%s" connected', participant.identity);
            console.log("participant.sid", participant.sid)
            document.getElementsByClassName("videoButton").forEach(element => element.style.display = "block")

            const displayTeacherNameOnVoiceOnlyConnection = () => {

                try {

                const connectedExpert = participant.identity
                // 'participant.identity' IS TEACHER HERE
                const textNode = document.createTextNode("You are connected to " + connectedExpert)
                const thisTextNodeHolder = document.createElement('div');
                thisTextNodeHolder.appendChild(textNode)
                thisTextNodeHolder.style.fontWeight = "bold"
                document.getElementById("connectedExpert").appendChild(thisTextNodeHolder)

                } catch(error){console.warn(error)}

            }

            if(!isVideoConnection) {
                displayTeacherNameOnVoiceOnlyConnection()
            }

            const div = document.createElement('div');

            if(isVideoConnection){

                
                const screenshare = document.getElementById("screenshare")
                const avatarHolder = document.getElementById("avatarHolder")
                
                div.id = participant.sid;
                div.style.position = "relative"

                if(mobileDevice() == true) { div.classList.add("p-3")}
                
                const nameTextNode = document.createTextNode(participant.identity)
            
                participant.on('trackSubscribed', track => trackSubscribed(div, track, participant));
                participant.on('trackUnsubscribed', trackUnsubscribed);
            
        
                // CODE FOR PLACING NAME HOLDER //   
                const videoBar = document.createElement('div');
                videoBar.id = "videoBar"
                const nameTextHolder = document.createElement('div');
                nameTextHolder.id = "nameTextHolder"
                const localMediaContainer = document.createElement('div');

                // THIS IS THE 'SELF' VIDEO
                const localVideoTrack = Array.from(room.localParticipant.videoTracks.values())[0].track;

                // **** TO DO - REPLACE JAVASCRIPT STYLING WITH CSS CLASSES 
                // CREATE CLASSES IN 'VIDEOS' AND 'SCREENSHARE' SECTIONS IN MAIN.CSS ***** //
            
                videoBar.style.position = "absolute"

                nameTextHolder.classList.add("nameTextHolder")

                

                const cropLogo1 = require('../assets/cropLogo1.png')
                const logoBadge = document.createElement("iMG")
                logoBadge.src = cropLogo1
              
                logoBadge.setAttribute("width", "20");
                logoBadge.setAttribute("height", "20");
                logoBadge.classList.add("img-fluid", "m-1")
                // nameTextHolder.appendChild(logoBadge)
                nameTextHolder.appendChild(nameTextNode)

                const selfVideo = localVideoTrack.attach()
                selfVideo.id = "selfVideo"
                selfVideo.style.objectFit = "cover";

                console.log("!mobileDevice() == true", !mobileDevice() == true)

                if(!mobileDevice() == true) {
                    selfVideo.style.width = "6em"
                    selfVideo.style.height = "6em"
                    selfVideo.style.borderRadius = "3em"
                    selfVideo.style.marginLeft = "10px"
                } else {
                    selfVideo.style.width = "7em"
                    selfVideo.style.height = "7em"
                    selfVideo.style.borderRadius = "3.5em"
                    selfVideo.style.marginLeft = "-18px"
                }
              
                selfVideo.style.borderColor = "white";
                selfVideo.style.borderStyle = "solid";
                selfVideo.style.borderWidth = "2px";

                // selfVideo.style.marginTop = "25px"
                

                localMediaContainer.appendChild(selfVideo)
                
                // localMediaContainer.classList.add("col-sm-4")
                nameTextHolder.classList.add("d-flex", "flex-row", "justify-content-center", "p-3", "mx-3", "align-items-center", "nameTextHolder")

                
                videoBar.appendChild(localMediaContainer)
                videoBar.appendChild(nameTextHolder)
                // videoBar.appendChild(useCreateInput())
                
                videoBar.classList.add("d-flex", "flex-row", "justify-content-between")
                videoBar.style.marginTop = "3%"
                videoBar.style.width = "100%"
                div.appendChild(videoBar)

            } else {

                participant.on('trackSubscribed', track => trackSubscribed(div, track, participant));
                participant.on('trackUnsubscribed', trackUnsubscribed);


                sessionsCollection.doc(room.name).update({

                    sessionStatus: "started"
        
                },{merge:true})

            }
            
        }

        function participantDisconnected(participant) {

            console.log('Participant "%s" disconnected', participant.identity);
            

            const updateSessonInfo = () => {
            
            sessionsCollection.doc(room.name).update({

                sessionEndTimeStamp: timestamp(),
                sessionEndInMillisecs: Date.now(),
                sessionStatus: "ended"
    
            },{merge:true})

            }

            updateSessonInfo()

            
            if(document.getElementById(participant.sid)){
                document.getElementById(participant.sid).remove();
                setTimeout(() => { location.reload()}, 500)
            }

            if(screenshare){
                screenshare.remove();
            }

            if(isVideoConnection == false ){
                setTimeout(() => { location.reload()}, 500)
            }
        }

        function trackSubscribed(div, track, participant) {

            // SCREENSHARE CODE SO FAR -  NEEDS FINISHING AND CLEANING-UP
            if(participant.identity.includes("screen_share")){

                try {
                    const screenshare = document.getElementById("screenshare")
                    const screenshareTrackHolder = document.createElement("div")

                    // 'screenshareTrackHolder' CLASS FOUND IN 'SCREENSHARE' SECTION IN MAIN.CSS 
                    screenshareTrackHolder.classList.add("p-4", "m-3", "screenshareTrackHolder")
                    const screenshareTrack = track.attach()
                              
                    screenshareTrack.controls = true

                    console.log("ROLE: ", role)

                    const placeStudentVidInsideScreenshareScreen = () => {
                
                        teacherVideoDiv.style.position = "absolute"
                        document.getElementById("theVideo").classList.add("theVideo")

                        teacherVideoDiv.classList.add("my-5")
                        teacherVideoDiv.style.marginTop = "10px"
                        teacherVideoDiv.style.zIndex = "4"
                        
                        document.getElementById("nameTextHolder").classList.add("display-none")
                        document.getElementById("selfVideo").classList.add("display-none")
                        screenshareTrackHolder.appendChild(teacherVideoDiv)

                        }

                    if(role === "teacher"){

                        placeStudentVidInsideScreenshareScreen()

                    }
                
                    screenshareTrackHolder.appendChild(screenshareTrack)

                    if(screenshare){

                        screenshare.appendChild(screenshareTrackHolder)

                    }

                    return

                }catch(err){
                    console.log("trackSubscribed err (screenshare)" + err)
                    return
                }
            }

            const remoteUserTrack = track.attach() // track.attach() is the remote user's video
            // remoteUserTrack.classList.add('croppedVideoFromMobile')
            remoteUserTrack.style.objectFit = "cover";
            div.appendChild(remoteUserTrack);

            if(mobileDevice() == true ){
                remoteUserTrack.style.borderRadius = "15px 15px 15px 15px"
                remoteUserTrack.classList.add("box5")
            }
            
        
            const showStudentVid = () => {

                getParticipantDetails(teacherAccessCollection, sessionsCollection, room.name, role)
                getParticipantDetails(studentAccessCollection, sessionsCollection, room.name, role)

                if(isVideoConnection){
                studentVideoDiv.appendChild(div)
                studentVideoDiv.classList.add('animate__animated', 'animate__fadeIn') // FOR SPACING AROUND REMOTE USER VIDEO, ADD 'M-3' CLASS HERE.
                studentVideoDiv.style.setProperty('--animate-duration', '1s');

                // ******** HERE UPDATE QUESTION REF IN FIRESTORE DATABASE CONFIRMING SESSION HAS STARTED ?????? ****** //
                console.log("******* SESSION STARTED - USERS ARE CONNECTED (THIS IS STUDENT) *********")
                // div.scrollTop="700";

                }

            }

            const showTeacherVid = () => {

                getParticipantDetails(teacherAccessCollection, sessionsCollection, room.name, role)
                getParticipantDetails(studentAccessCollection, sessionsCollection, room.name, role)

                if(isVideoConnection){
                    teacherVideoDiv.appendChild(div) 
                     

                    teacherVideoDiv.classList.add('animate__animated', 'animate__fadeIn') // FOR SPACING AROUND REMOTE USER VIDEO, ADD 'M-3' CLASS HERE.
                    teacherVideoDiv.style.setProperty('--animate-duration', '1s');
                    console.log("******* SESSION STARTED - USERS ARE CONNECTED (THIS IS TEACHER) *********")
                    // window.scrollTo(0, 2000);

                    sessionsCollection.doc(room.name).update({

                        sessionStatus: "started"
            
                    },{merge:true})

                }
            }

            // NEW CODE FOR PLACING VID
            const isTeacher = role === "teacher" ? 
            showTeacherVid(): showStudentVid()

            if(track.kind == 'video'){
                // STYLE VIDEOS HERE //
                console.log("TRACK.KIND 'VIDEO'")
                remoteUserTrack.id = "theVideo"
            
            }
        }

        function trackUnsubscribed(track) {
            track.detach().forEach(element => element.remove());
        }



        
        ////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////

        const stopMediaStream = (role) => {

            let ref;
            const isTeacher = role === "teacher" ? 
            ref = studentAccessCollection : ref = teacherAccessCollection

            ref.doc(room.name)
                .onSnapshot((doc) => {

                try {

                const {roomstatus} = doc.data()

                if(roomstatus == "disconnected"){

                    // THIS REMOVES THE VIDEO ELEMENTS FROM THE PAGE
                    room.participants.forEach(participantDisconnected);
                    
                    // THIS STOPS THE MEDIA STREAM - INFO HERE:
                    // https://stackoverflow.com/questions/51947368/twilio-room-does-not-disconnect-webcam-led-remains-on
                    room.localParticipant.tracks.forEach(publication => {
                            publication.track.stop();               
                    });

                } else {
                    console.log("Roomstatus", roomstatus)
                    
                }
                } catch(err) { "Error @ stopMediaStream (" + role + ") " + err}

                });

        }

        stopMediaStream("teacher")
        stopMediaStream("student")


        room.participants.forEach(participantConnected);
        room.on('participantConnected', participantConnected);

        });

}

export default twillioVideoConnect