<template>
  
        <div id="headerImage" class="grad" :class="{smallerViewHeight: !mobileDevice}" style="width:100vw;">

                <div class="row mt-5 pt-3">
                    <div class="col-sm-1"></div>
                    <FlashingText v-if="mobileDevice" class="headingFont display-5 col-sm-10 white-font text-start mt-3 mx-4" style="font-size:320%" />
                </div>

            <div class="row d-flex align-items-center justify-content-start my-3">
                <div class="col-sm-1"></div>
                        <div class="col-sm-7 text-start m-3 bold-font">

                                    <h1 v-if="mobileDevice" class="headingFont display-1" style="color:#2e5ea2; font-weight:bold;">Expert 5</h1>
                                    <h1 v-if="!mobileDevice" class="headingFont " style="color:#2e5ea2; font-weight:bold; font-size: 450%">Expert 5</h1>
                                    <h5 style="color:#2e5ea2; font-weight:bold;"
                                        :class="{ mediumFont: mobileDevice }" 
                                    >Five (Free) Minutes With An Expert 
                                    </h5>
                                    <!-- <h5 class="expert5blue bold-font" >Post a question - Connect Instantly </h5> -->
                                    <!-- <Info v-if="mobileDevice" /> -->

                        </div>
                        
                        <!-- <BecomeExpert v-if="mobileDevice" /> -->
                        
            </div>
            <AvatarGroup v-if="mobileDevice" />
        </div>  

</template>

<script>

import { ref, onMounted, watch } from 'vue'
import { projectFirestore, timestamp } from '../firebase/config'
import firebase from 'firebase'
import { useRouter } from 'vue-router'
import MySignUpButton from './MySignUpButton.vue'
import BecomeExpert from './BecomeExpert.vue'
import AvatarGroup from './AvatarGroup.vue'
import isMobileDevice from '../composables/mobileDevice.js'
import FlashingText from './FlashingText.vue'
import Info from './Info.vue'


export default {

        components: { MySignUpButton, AvatarGroup, FlashingText, Info, BecomeExpert },

    
        setup(){

        const firstName = ref('')
        const email = ref('')
        const message = ref('')
        const subject = ref('get unstuck')
        const classExit = ref(false)
        const classEntrance = ref(false)
        const mobileDevice = ref(true)
        const signUpForm = ref(false)
        const router = useRouter() // get a reference to our vue router
        const apply = ref("SIGNUP")
        const page1 = ref("Apply")
        const applyCallTo = ref("Become of our experts!")
        const page2 = ref("EmailAuth")
        const signUp = ref("")
        const signUpCallTo = ref("Here's why!")
        
        const handleSubmit = async () => {

            const emailValidationTest = /\S+@\S+\.\S+/.test(email.value)

            if(emailValidationTest){

                const user = {

                    name: firstName.value,
                    email: email.value,
                    createdAt: timestamp()

                }
                    console.log('VALID EMAIL - User: ', user)

                    await projectFirestore.collection('users').add(user)
                    message.value = 'Thanks ' +  firstName.value + '! Please check your email.'
                    firstName.value = ''
                    email.value = ''
                    

            } else {
                console.log(email.value, 'is not a valid email.')
                message.value = 'Please enter a valid email.'
            }
 
            }

            const showSignUpForm = () => {
                console.log("show sign-up form")
                signUpForm.value = true
            }

            const hideSignUpForm = () => {
                signUpForm.value = false
            }

                    onMounted(() => {

                        mobileDevice.value = isMobileDevice()

                    })

            
            // ####### WARNING - THIS CAUSES PROBLEMS WITH SIGN-UP, SO COMMENTED-OUT FOR NOW #####

            // here if a user is signed go straight to the feed (don't show front page)

            // firebase.auth().onAuthStateChanged((user) => {

            //     if (user) {
                              
            //         console.log("FRONT PAGE USER EMAIL: ", user.email)
            //         router.push('/feed')
            //     } else {  
            //         console.log("USER SIGNED IN: FALSE")
            //     }
            // });


            

            return { page1, page2, signUpCallTo, applyCallTo, signUp, apply, firstName, email, 
            handleSubmit, message, subject, classEntrance, classExit, mobileDevice, 
            showSignUpForm, signUpForm, hideSignUpForm, isMobileDevice }

        }

        

}
</script>

<style>


.myFadeOut {
  animation: fadeOutDown; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s; /* don't forget to set a duration! */

}

.myFadeIn {

  animation: fadeInDown; 
  animation-duration: 1s; 

}

.the-bold-text {
    font-weight: bold;
}

.mailing {
    background-color: #ae2d68;
    width:30em;
    height:60px;
    border-radius: 30px;
}

.applyFont {
    font-size: medium;
    color:white;
    text-decoration: none;
}

.cornersUp {
    border-radius: 10px 10px 30px 30px;
}

.cornersDown {
    border-radius: 30px 30px 10px 10px;
}

.cornersEqual {
    border-radius: 5px 5px 5px 5px;
}




</style>
