<template>

        <div class="d-flex align-items-end my-1">

                <router-link v-for="(item, index) in naviItems" :key="index"
                    class="smallFont darkGray" 
                    :class="{'mobile-route-width': !isMobileDevice(), 'desktop-route-width' :isMobileDevice() }" 
                    :to="{ name: item.name }">
                    <svg xmlns="http://www.w3.org/2000/svg" :class="{'icon-border nudge':item.border, 'invert': item.invert}" :width="item.width" :height="item.height" viewBox="0 0 640 512"><path :d='item.iconpath1'/><path :d='item.iconpath2'/><path :d='item.iconpath3'/> <path :d='item.iconpath4'/>  </svg>
                    <div>{{ item.heading }}</div>
                </router-link>
                
        </div>

  
</template>

<script>

import { ref } from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'
import svgpath from '../composables/getSVGpath.js'

export default {


    setup() {

        const naviItems = ref([

            { name: 'FrontPage', heading: 'home',  border: false, invert: false,  width: "24px", height: "24px", 
                    
                    iconpath1: svgpath('homeIcon', 0) 
             
            },

            { name: 'Pricing', heading: 'pricing', border: false, invert: false,  width: "35px", height: "25px",
            
                    iconpath1:svgpath('paperMoneyIcon', 0), 
                    iconpath2:svgpath('paperMoneyIcon', 1) 
             
            },

            { name: 'Subjects', heading: 'subjects', border: false, invert: false, width: "25px", height: "25px",  
                
                    iconpath1:svgpath('bookIcon', 0) 
            },

            { name: 'Answer', heading: 'answer', border: false, invert: false, width: "25px", height: "25px",  
                    iconpath1:svgpath('chatMessageIcon', 0) 
                
            },

            { name: 'Ask', heading: 'ask', border: true, invert: false, width: "21px", height: "21px", 
            
                    iconpath1:svgpath('infoIcon', 0)        
                
            },
                
        ])


        return { isMobileDevice, naviItems, svgpath }
    }

}
</script>

<style scoped>
    .mobile-route-width {
        width:20vw
    }

    .desktop-route-width {
        width: 7em
    }

    .icon-border {
        border: solid black 2px;
        border-radius: 50%;

    }

    .nudge {
        padding-left: 3px;
        margin-bottom: 3px
    }

    .invert {
        filter: invert();
    }

  


</style>