const isMobileDevice = () => {

if((typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)){ 

    return false

  } else {

    return true

  }

}

export default isMobileDevice