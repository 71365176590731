<template>

<div class="d-flex justify-content-center" style="margin-top:100px;">

        <el-form class="marginTop" label-width="100px" v-if="!signedIn">
            <p class="fs-3">SIGN-IN</p>

            <el-form-item label="Email"> 
                    <el-input type='email' v-model='email'></el-input> 
            </el-form-item>

            <el-form-item label="Password"> 
                    <el-input type='password' v-model='password'></el-input> 
            </el-form-item>

            <p>
                <el-button 
                    class="elbutton m-4" 
                    @click="sigIn">sign-in
                </el-button>
            </p>       
        </el-form>
    </div>
    <NavBottom />
  

</template>

<script>

import { ref } from 'vue'
import firebase from 'firebase'
import { useRouter } from 'vue-router'
import { timestamp } from '../firebase/config'
import subjects from '../data/subjects.json'
import Nav from '../components/Nav.vue'
import NavBottom from '../components/NavBottom.vue'

export default {

    components: { Nav, NavBottom },

    setup(){

        const db = firebase.firestore();

        const firstName = ref('')
        const lastName = ref('')
        const email = ref('')
        const password = ref('')
        const tel = ref(null)
        const router = useRouter() // get a reference to our vue router
        const signedIn = ref(null)
        const theSubjects = ref(subjects)
        const currentUserEmail = ref(null)
        const currentUserDisplayName = ref(null)
        // const isInstructor = ref('false')

        firebase.auth().onAuthStateChanged((user) => {

                if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                //   var uid = user.uid;
                console.log("USER SIGNED IN")
                signedIn.value = true
                currentUserEmail.value = user.email

                const docRef = db.collection("users").doc(user.email);

                    docRef.get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data first name:", doc.data().firstname);
                        console.log("Instructor: ", doc.data().instructor);

                    
                        // if(doc.data().instructor === false){

                        // }
                        
                        currentUserDisplayName.value = doc.data().firstname;

                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });




                // ...
                } else {
                // User is signed out
                // ...
                console.log("NO USER SIGNED IN")
                signedIn.value = false
                }
        });

        const checkIfInstructor = () => {

            const user = firebase.auth().currentUser;

            if (user) {

                    const docRef = db.collection("users").doc(user.email);

                    docRef.get().then((doc) => {
                    if (doc.exists) {
                        console.log("Instructor: ", doc.data().instructor);

                       
                        
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
           
            // ...
            } else {
            // No user is signed in.
            }

        }

        


        const sigIn = () => {

            firebase.auth()
                    .signInWithEmailAndPassword(email.value, password.value)
                    
                    .then((data) => {
                    // checkIfInstructor()    
                    console.log('Successfully Signed In!')
                    router.push("/ask") // redirect to the feed
                    })
                    // .then(onSuccess => {
                    //     signedIn.value = true
                    // })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }

  







        return { email, password, router, 
        sigIn, firstName, lastName, tel, signedIn, theSubjects, 
        currentUserEmail, currentUserDisplayName }

    }


}

</script>

<style scoped>



.welcome {
    font-size: 400%;
    color:red;
}

.marginTop {
    margin-top: 50px
}

</style>