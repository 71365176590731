<template>
    <Nav />
    <div v-if="!phoneNumberSubmitted" class="p-5 my-3" style="height:120px; font-weight:bold">Please enter your phone number</div>
    <div v-else class="p-5 my-3" style="height:120px; font-weight:bold">A verification code has been sent to your phone. Please enter it when you recieve it</div>
<div class="row">
    <div class="col-sm-3"></div>
    <el-form class="col-sm-3" v-show="!phoneNumberSubmitted" style="height:250px">
        <el-row class="p-4 d-flex justify-content-center">               
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            Country Code<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item v-for="value in countriesObject" :key="value" @click="getCountryCode(value.phone)">
                                {{ value.name }}: +{{ value.phone }}, {{ value.emoji }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                        </template>
                    </el-dropdown>
        </el-row>
                <el-row class="d-flex justify-content-center">               
                        <el-input v-model="numberEntered" placeholder="tel" style="border-color:red" @keydown.enter.prevent="makePhoneNo()"/>
                </el-row>
                <el-row class="d-flex justify-content-center">               
                        <div style="height:30px" class="p-5"> {{ codeToDial }} {{ numberEntered }}</div>                     
                </el-row>     
                <el-row class="d-flex justify-content-center">
                        <el-button type="warning" @click.prevent="makePhoneNo()">submit</el-button>
                </el-row>
        </el-form>
    
        <el-form class="col-sm-3" v-show="phoneNumberSubmitted" style="height:250px">         
                <el-row class="d-flex justify-content-center">
                        <el-input v-model="verification_code" placeholder="enter code" @keydown.enter.prevent="verifyPhoneNumber()"/>
                </el-row>    
                <el-row class="d-flex justify-content-center">
                    <el-button type="warning" @click="verifyPhoneNumber()">verify</el-button>
                </el-row>
        </el-form>

        <div class="p-5 col-sm-3">
            <img src="../assets/AfroFemCircled.png" alt="" class="img-fluid">
        </div>
    </div>

</template>

<script>

import Nav from '../components/Nav.vue'
import { ref } from 'vue'
import countrieslist from '../data/countrieslist.json'
import firebase from 'firebase'
import { useRouter } from 'vue-router'



export default {

    components: { Nav },

    setup(){

        const db = firebase.firestore();
        const numberEntered = ref('')
        const codeToDial = ref(null)
        const phoneNumberString = ref('')
        const countriesObject = ref(Object.values(countrieslist))
        const verification_code = ref(null)
        const router = useRouter()
        const phoneNumberSubmitted = ref(false)
        const user = firebase.auth().currentUser;
        const enterClickTest = () => {
            console.log("enterClickTest")
        }


        const verifyPhoneNumber = () => {

            console.log(verification_code.value)

            
            const docRef = db.collection("users").doc(user.email);

                    docRef.get().then((doc) => {
                    if (doc.exists) {
        
                        const { phone_verification_code, phone_number_to_verify } = doc.data()

                        console.log(phone_verification_code)

                       if(verification_code.value == phone_verification_code){

                           console.log("CODE MATCH. PHONE NUMBER VERIFIED")

                                docRef.update({

                                    tel:phone_number_to_verify,
                                    phone_number_to_verify:firebase.firestore.FieldValue.delete(),
                                    phone_verification_code:firebase.firestore.FieldValue.delete()

                                    },{merge:true})
                                                
                                    .then(() => {
                                            console.log("Phone no updated in database");
                                            router.push('/apply')
                                    })
                                    .catch((error) => {
                                            console.error("Error writing document: ", error);
                                    });
                       }else {

                           console.log("INCORRECT CODE ENTERED")
                           alert("Incorrect code")
                       }

                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });
        }

        const digitsOnly = (phone_number_entered) => {

            // from here: https://www.codegrepper.com/code-examples/javascript/regex+only+numbers+javascript
            return phone_number_entered.replace(/[^\d]/g, "")
        }

        const getCountryCode = (code) => {
            console.log("COUNTRY CODE:", code)
            codeToDial.value = "+" + code
        }

        const makePhoneNo = () => {

            console.log("phoneNumberSubmitted.value set to True" )
            

            console.log("Phone Number enter, digits only", digitsOnly(numberEntered.value))

                if(codeToDial.value != null){
                    if(numberEntered.value != ''){
                        if(numberEntered.value === digitsOnly(numberEntered.value)){

                            console.log("NUMBER IS GOOD")
                            phoneNumberString.value = codeToDial.value + numberEntered.value
                            console.log("Phone number string: " + phoneNumberString.value )
                            

                                     firebase.auth().onAuthStateChanged((user) => {

                                        if(user){
                                            
                                            db.collection("users").doc(user.email).set({
                                            phone_number_to_verify:phoneNumberString.value
                                            },{merge:true})
                                            
                                            
                                            .then(() => {
                                                console.log("Document successfully written!");
                                                phoneNumberSubmitted.value = true
                                                
                                            })
                                            .catch((error) => {
                                                console.error("Error writing document: ", error);
                                            });


                                        }

                                    })

                            
                        } else {
                            console.log("PLEASE ENTER DIGITS ONLY NO SPACES, LETTERS OR SPECIAL CHARACTERS")
                            alert("Numbers only. No letters, spaces or special characters")
                        }
                    } else {
                        console.log("PLEASE ENTER YOUR NUMNER")
                        alert("Please enter your number")
                    }

                } else {
                    console.log("PLEASE SELECT A COUNTRY CODE")
                    alert("Please select a country code")
                }

        }




        return { enterClickTest, numberEntered, countriesObject, getCountryCode, codeToDial, makePhoneNo, phoneNumberString, verification_code, verifyPhoneNumber, router, phoneNumberSubmitted }

    }

}
</script>

<style scoped>

.el-button {
    margin: 10px;
    width:200px
}

.el-input {

    margin: 10px;
    width:200px

}

</style>

