<template>
    <div class="row d-flex align-items-center grad4"
          :class="{padding:mobileDevice, onehunderedviewheight:mobileDevice, whiteShade:mobileDevice, grad:!mobileDevice }">
            <div class="col-sm-2"></div>
              <div class="col-sm-3 grad4 d-flex align-items-center smooth-oblong-right innerBox someheight">
                <!-- <img :src="src" class="img-fluid"> -->
                <FlashingText class="white-font"/>
              </div>
              <!-- <div class="col-sm-3 grad4 d-flex align-items-center smooth-oblong-right innerBox someheight">
                <img :src="src" class="img-fluid">
              </div> -->
              <div class="col-sm-2"></div>

            <!-- <MobileExtra v-if="!mobileDevice" /> -->

            <div class="col-sm-3 text-start testShadow p-5 grad2"
              :class="{ borderRadius25px: mobileDevice }"
            >
              <h2 class="headingFont bold-font" :slogan="slogan">Text 'help' to</h2>
              <h4 class="headingFont my-4" :paragraph1="paragraph1"> +1 860 EXPERT 5</h4>
              <h4 class="headingFont my-4" :paragraph2="paragraph2"> +1 860 397 3785</h4>
              <h4 class="headingFont my-4" :paragraph3="paragraph3"> {{ paragraph3 }}</h4>
            </div>      
    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'
import MobileExtra from './MobileExtra.vue'
import FlashingText from './FlashingText.vue'

export default {

    props: ['src', 'slogan', 'paragraph1','paragraph2', 'paragraph3'],

    components: { MobileExtra, FlashingText },

    setup(){

        const mobileDevice = ref(false)

        onMounted (() => {
          mobileDevice.value = isMobileDevice()
        })

        return { isMobileDevice, mobileDevice  }

    }

}
</script>

<style>

.padding {
    padding-top:11%; 
    padding-bottom:10%
}

.extraPadding {

  padding-top:10%; 
  padding-bottom:10%

}

.someheight {
    height: 450px;
    font-size: 400%;
}




</style>