<template>
    <el-button type="text" class="AnswerVideoClass">
        <FAVideoSolid />
            <!-- <div v-if="!answered">answer</div>
        <transition name="el-fade-in-linear">
            <div v-if="answered">end</div>
        </transition> -->
    </el-button> 
</template>

<script>

import VideoCamera from '../components/icons/VideoCamera.vue'
import FAVideoSolid from '../components/icons/FAVideoSolid.vue'

export default {

    components: { VideoCamera, FAVideoSolid },

    props: ['answered'],

            setup(){
                        
                        return {  }
                    
                    }

                }


</script>

<style>


</style>