<template> 

        <el-card 
            :id="id" :mobile_device="mobile_device" shadow="never" style="border:none; border-radius:0" body-style="padding:0">

            <div id="postHolder"
                    :class="{  
                    'postBorderDesktop borderRadius25px p-4': mobile, 
                    postBorderMobile: !mobile,                    
                    }"
                    >

                    <div class="text-start">
                        
              
                        <!-- place image here -->
                        <div v-if="mediaUrl" class="my-3">
                            <img :src="mediaUrl">
                        </div>

                        <!-- this div is for the video when teacher and student are connected -->
                        <div :class="id" id="videoBox"></div>
                  
                        <div class="d-flex justify-content-between align-items-center">

                                <SubjectHeader :sender="sender" :subject="subject" class="my-2"/>

                                <div v-if="FeedPage" class="d-flex align-items-center">
                                  
                                    
                                    <div :class="{'col-sm-2' : mobile}" v-if="!showLoader">
                                        <div v-if="isVideoConnection">
                                            <AnswerVideo 
                                                :disabled="disabled" 
                                                @click="confirmResponse(isVideoConnection, subject, sender), $emit('stopFeedQuestions')"
                                                :answered="onCall"
                                                v-if="!onCall" 
                                                :class="{ 'endVideoClass': onCall, 'responded': callOver }"
                                            />  

                                        </div> 

                                        <div v-else class="mx-3">

                                            <el-button 
                                                v-if="!onCall"
                                                class="d-flex justify-content-center write-send-button" type="text" :disabled="disabled"
                                                @click="confirmResponse(isVideoConnection, subject, sender), $emit('stopFeedQuestions')"
                                                >
                                                <svg xmlns="http://www.w3.org/2000/svg" width='23' viewBox="60 0 500 512">
                                                    <path stroke="black" stroke-width="1" :d='microphoneLineIcon'/>
                                                    <path stroke="black" stroke-width="1" :d='microphoneLineIcon2'/>
                                                    <!-- <rect x="100" y="100" width="100%" height="100%"/> -->
                                                </svg>


                                            <!-- <ControlButton v-if="!onCall" :width="23" :viewBox="'60 0 500 512'" :path="microphoneLineIcon" :path2="microphoneLineIcon2" :color="'black'" 
                                                :disabled="disabled"
                                                @click="confirmResponse(isVideoConnection, subject, sender), $emit('stopFeedQuestions')"/>   -->
                                                
                                                
                                            </el-button>

                                        </div>
                                    </div>

                                    <Indicator v-if="disabled && !showLoader && !onCall" class="indicator-margin-mobile"/>  
                                    <div v-if="showLoader" class="px-4  d-flex justify-content-end"><Loader /></div>
                      
                                </div>

                                <Timer v-if="showTimer" :showTimer="showTimer" :session_ID="id" class="mx-3" />

                                
                        </div>

                        <div class="postTopMargin">


                                <ChatOutput v-if="showTimer" />
                            

                            <!-- timer here is for 'voice' connections - maybe place somewhere more appropriate for video calls -->
                            <div class="my-2">
                                
                               <!-- <Timer v-if="showTimer" :showTimer="showTimer" :session_ID="id" /> -->
                               <div v-if="VideoPage" id="connectedExpert"></div>
                            </div>

                            <!-- <hr> -->
                            <div v-if="!isAskPage()" class="row my-2">

                                <!-- ****** PLACE QUESTION INPUT HERE!!???? ******* -->
                                <!-- <QuestionInput v-model="message" :placeholder="'Temp plaeholder'" /> -->
                                
                                <pre class="message-pre-tag">{{ message }}</pre>
                                <div v-if="!editing">
                                    <pre v-if="code" class="col-sm-12 border grayF3F3F3" 
                                    style="width:100%; font-size:small;">

                                    <!-- 'code' background-color only shows-up in development local - deplopyed version has no backgound-color -->
                                        <code>{{ code }}</code>
                                    </pre>
                                </div>
                                <el-input
                                    v-else v-model="code"
                                    @keydown="updateCodeLive(id)"
                                    :autosize="{ minRows: editMinRows }"
                                    type='textarea' 
                                    :input-style="{
                                            
                                            border: '0px',
                                            fontSize:'medium',
                                            paddingTop:'10px',
                                            paddingLeft:'8px',
                                            borderRadius:'0'
                                                        

                                }"/>

                                <LiveCodeEdit 
                                    v-if="onCall"
                                    :isCode="code"
                                    :id="id" 
                                    :index="index"
                                    :buttonLabel="buttonLabel"
                                    @click="liveEdit(id, index)" 
                         
                                    />

                                <LiveCodeEdit 
                                    v-if="isAskPage()"
                                    :isCode="code"
                                    :id="id"
                                    :buttonLabel="buttonLabel"
                                    @click="liveEdit(id)" 
                                />
                                
                            </div>                        

                        </div>
                    </div>
      
            </div>

        </el-card>
</template>

<script>

import { onMounted, ref, watch, watchEffect } from 'vue'
import firebase from 'firebase'
import ResponseButton from  '../components/ResponseButton.vue'
import FullScreenButton from  '../components/FullScreenButton.vue'
import PictureInPictureButton from  '../components/PictureInPictureButton.vue'
import ScreenShareButton from  '../components/ScreenShareButton.vue'
import AnswerVideo from  '../components/AnswerVideo.vue'
import EndVideoButton from  '../components/EndVideoButton.vue'
import notifyStudentExpertIsAvailable from '../composables/notifyStudentExpertIsAvailable.js'
import twillioVideoConnect from '../composables/twillioVideoConnect.js'
import createAccessTokenAndJoinRoom from '../composables/createAccessTokenAndJoinRoom.js'
import isMobileDevice from '../composables/mobileDevice.js'
import getPagePath from '../composables/getPagePath.js'
import SubjectHeader from  '../components/SubjectHeader.vue'
import openFullScreen from '../composables/requestFullScreen.js'
import requestPictureInPicture from '../composables/requestPictureInPicture.js'
import screenShare from '../composables/screenShare.js'
import LiveCodeEdit from '../components/LiveCodeEdit.vue'
import ChatInput from '../components/ChatInput.vue'
import ChatOutput from '../components/ChatOutput.vue'
import QuestionInput from '../components/QuestionInput.vue'
import ControlButton from '../components/ControlButton.vue'
import Timer from '../components/Timer.vue'
import startTimerOnConnection from '../composables/startTimerOnConnection.js'
import svgpath from '../composables/getSVGpath.js'
import Loader from  '../components/Loader.vue'
import Indicator from  '../components/Indicator.vue'
import getCurrentSessionID from '../composables/getCurrentSessionID.js'


export default {

    components: { ResponseButton, AnswerVideo, EndVideoButton, SubjectHeader, FullScreenButton, ScreenShareButton, LiveCodeEdit, PictureInPictureButton, QuestionInput, ControlButton, Timer, Loader, Indicator, ChatInput, ChatOutput },


    props: [ 'message', 'subject', 'sender', 'id', 'emailToCheck' , 'mediaUrl', 'source', 'theroom', 'uid', 'userFirstName', 'code', 'index', 'input_inside_post', 'mobile_device', 'isVideoConnection', 'questionSent'],

    setup(props){

       
        const teacherAccessCollection = firebase.firestore().collection("teacherAccess");
        const studentAccessCollection = firebase.firestore().collection("studentAccess");
        const screenShareAccessCollection = firebase.firestore().collection("screenShareAccess")
        const accessCollectionArray = [ teacherAccessCollection,  studentAccessCollection, screenShareAccessCollection]
        const questionsCollection = firebase.firestore().collection("questions")
        const sessionsCollection = firebase.firestore().collection("sessions")

        const getPostID = (theID) => {
            
                console.log("POST ID:", theID)
              }

    
        const liveVideo = ref(false)
        const isClicked = ref(false)
        const atClick = ref(false)
        const pic = ref(null)
        const onCall = ref(false)
        const callOver = ref(false)
        const currentPage = ref(null)
        const FeedPage = ref(false)
        const VideoPage = ref(false)
        const editing = ref(false)
        const editMinRows = ref(null)
        const buttonLabel = ref('EDIT CODE')
        const mobile = ref(null)
        const showTimer = ref(false)
        const hangUp1Icon = svgpath('hangUp1Icon', 0) // PATH 1
        const hangUp1Icon2 = svgpath('hangUp1Icon', 1) // PATH 2
        const microphoneLinesSolid = svgpath('microphoneLinesSolid', 0)
        const microphoneLinesSolidSlash = svgpath('microphoneLinesSolidSlash', 0)
        const microphoneLineIcon = svgpath('microphoneLineIcon', 0)
        const microphoneLineIcon2 = svgpath('microphoneLineIcon', 1)
        const cameraOff = svgpath('microphoneLineIcon', 0)
        const cameraOff2 = svgpath('microphoneLineIcon', 1)
        const endVoiceCall = svgpath('endVoiceCall', 0)
        const showLoader = ref(false)
        const disabled = ref(false)
        const teacherIsAnswering = ref(true)
        const chatMessage = ref('')
      
        const updateCodeLive = (id) => {

            // console.log("PROPS.CODE:", props.code)
            console.log("currentPage", currentPage.value)
            console.log("getPagePath()", getPagePath())
            const docRef = questionsCollection.doc(id)
                 
            docRef.get().then((doc) => {

                if (doc.exists) {
                    docRef.set({
                        code:props.code,
                        pageSentFrom: currentPage.value
                    },{merge:true})

                } else {
                    console.log("No such doc")
                }

            })

        }

        const stopScreenShareStream = (roomID) => {

            const docRef = screenShareAccessCollection.doc(roomID);
            docRef.get()
            
            .then((doc) => {

                if (doc.exists) {

                    docRef.set({
                        roomstatus: "disconnected",
                        accessToken: firebase.firestore.FieldValue.delete()
                    },{merge:true})

                } else {
                    console.log("NO SCREENSHARE THIS SESSION")
                }

            }).then(() => {
                    console.log("SCREENSHARE DISCONNETED")
            }).catch(error => {
                    console.log("@ stopScreenShareStream" + error)
                    });

        }

        // ONE CLICK STOPS ALL STREAMS AND REMOVES ACCESS TOKENS
        const stopVideoStream = (roomID) => {

            accessCollectionArray.forEach(element => { 
                element.doc(roomID).set({
                roomstatus: "disconnected",
                 accessToken: firebase.firestore.FieldValue.delete()
                }, {merge:true})
                 onCall.value = false

            })

        }


        const isOnCall = () => {
            if(onCall.value == false){
                onCall.value = true
                // console.log("onCall.value", onCall.value)
            } else {
               callOver.value = true 
            //    console.log("onCall.value", onCall.value)
            }
        }

        const isLiveVideo = () => {
            if(props.source === "web"){
                liveVideo.value = true
            } 
        }

        isLiveVideo()

        const beenClicked = () => {
            console.log("Clicked")
            isClicked.value = true
            atClick.value = true
            
            showLoader.value = true

        }

        const hasResonded = () => {
            if(props.emailToCheck){
                isClicked.value = true
                return "notified"
            } else {
                return "notify"
            }
        }

        const liveEdit = (id, index) => {

            if(editing.value === true){

                console.log("SAVE ON CLICK")
                editing.value = false
                buttonLabel.value ="EDIT CODE"
                
                return

            } else {

                editing.value = true
                buttonLabel.value ="SAVE"
                
                const questionDoc = questionsCollection.doc(id) 

                questionDoc.get().then((doc) => {
                    const { code } = doc.data()
                    props.code = code
                    editMinRows.value = code.split(/\r\n|\r|\n/).length
                    console.log(props.code)


                }).then(() => {
                    questionDoc.set({
                        editingCode: true,
                    },{merge:true})
                }).catch((err) => { console.log("Error @ liveEdit", err)}) 

            }

        }

        const isAskPage = () => {
            return currentPage.value === ""
        }

        isAskPage()




        const turnOnTimer = () => {
            showTimer.value = true 
            showLoader.value = false

        }

        
        // HERE LISTENING FOR QUESTION THAT HAVE BEEN RESPONDED TO
        // ANSWER BUTTON IS DISABLED.
        try {   teacherAccessCollection.doc(props.id).onSnapshot((doc) => {

                    if( doc.exists) {
                        let  { RoomID } = doc.data()
                            if(RoomID === props.id) { disabled.value = true }
                        }
                    }); 

        } catch (error){console.warn(error)}



        const answerClick = () => {

                if(teacherIsAnswering.value === true){

                        createAccessTokenAndJoinRoom(props.id, 'teacher', props.id, props.id, props.mobile_device, props.id, props.isVideoConnection), 
                        beenClicked(), 
                        isOnCall(), 
                        startTimerOnConnection(props.id, turnOnTimer)
                        getCurrentSessionID(props.id)

 
                        }

                }

            

    const confirmResponse = (isVideoConnection, subject, sender) => {
            
            let questionType;
            
            if(isVideoConnection == true){
                questionType = 'video chat'
            }else {
                questionType = 'voice chat'
            }

            let check = confirm("Start '" + subject + "' " + questionType + " with " + sender + "?")

            if(check == true) {
                console.log("1.Teacher is repsonding to "+ subject + " " + questionType + " question")
                answerClick()
            } else {
                console.log("2.Teacher is NOT repsonding to " + subject + " " + questionType + " question")
            
            }

        }

        

        watchEffect(() => {
            if(props.questionSent == true){
                console.log("QUESTION SENT WATCH EFFECT POST.VUE")
                startTimerOnConnection(props.id, turnOnTimer)
                showLoader.value = true
                
            }
        })

        onMounted(() => {

            currentPage.value = getPagePath()
            mobile.value = isMobileDevice()

           
            hljs.configure({
                ignoreUnescapedHTML: true
                });


            hljs.highlightAll();


            if(currentPage.value === 'answer'){
                FeedPage.value = true
            }
            if(currentPage.value === 'ask'){
                VideoPage.value = true
            }

                   
        })

       

        return { 

            isClicked, 
            beenClicked, 
            notifyStudentExpertIsAvailable, 
            hasResonded, 
            atClick, 
            getPostID, 
            twillioVideoConnect,
            createAccessTokenAndJoinRoom,
            liveVideo,
            isLiveVideo,
            onCall,
            isOnCall,
            callOver,
            isMobileDevice,
            teacherAccessCollection,
            stopVideoStream,
            currentPage,
            getPagePath,
            FeedPage,
            VideoPage,
            openFullScreen,
            requestPictureInPicture,
            screenShare,
            liveEdit,
            editing,
            editMinRows,
            updateCodeLive,
            isAskPage,
            buttonLabel,
            mobile,
            showTimer,
            startTimerOnConnection,
            turnOnTimer,
            svgpath,
            hangUp1Icon,
            hangUp1Icon2,
            microphoneLinesSolid,
            microphoneLinesSolidSlash,
            microphoneLineIcon,
            microphoneLineIcon2,
            confirmResponse,
            showLoader,
            disabled,
            answerClick,
            teacherIsAnswering,
            cameraOff,
            cameraOff2,
            endVoiceCall,
            chatMessage,
            getCurrentSessionID
           
             
            }

    }

}
</script>


<style scoped>


.responded {
  background-color: transparent;
  color: grey;
  cursor:not-allowed
}


img {
  width: 100%;
 display: block;
  /* object-fit: fill; */
}

.postTopMargin {
    margin-top:1px;
    margin-left:18px;
    margin-right:18px;
    /* margin-bottom:18px; */

}



.labelMarginDesktop {

    margin-top:12px;
    margin-left:18px;
    margin-right:18px;
    margin-bottom:12px;

}

.labelMarginMobile {

    margin-top:12px;
    margin-left:18px;
    margin-right:18px;
    margin-bottom:0px;

}

.fullSceenIcon {
    /* width: "30px"; */
    color: "red";
    background-color: "green";
    position: "absolute";
    margin: "40px"
}

.postOutline {
    outline: 1px solid white;
}

.pre {
        overflow-x: auto;
        font-family: "Times New Roman", Times, serif;
        font-size: 10px;
   
      }
.message-pre-tag {
        /* display: block;
        width: auto; */
        font-family: 'Arial', Helvetica, sans-serif; 
        font-size:medium; 
        white-space: pre-wrap;
        /* text-indent: -4px; */
}

.opacity-zero {
    opacity: 0
}

.low-opacity {
    opacity: 0.3
}

.height-vh3 {
    height:3vh
}





</style>
