<template>
    <button class="responseButton" @click="pushToFeed()">
        <Cellphone />
            <div v-if="!atClick">{{ response }}</div>
        <transition name="el-fade-in-linear">
            <div v-if="atClick">notified</div>
        </transition>
    </button> 
</template>

<script>

import { useRouter } from 'vue-router'
import VideoCamera from '../components/icons/VideoCamera.vue'
import Cellphone from '../components/icons/Cellphone.vue'

export default {

    components: { VideoCamera, Cellphone },

    props: ['response', 'atClick'],

            setup(){
                        const router = useRouter()

                        const pushToFeed = () => { 

                                // After the response button is clicked on the single question page
                                // the user is automatically taken to the feed,
                                // where he can view more questions

                                const pathArray = window.location.pathname.split('/');
                                console.log("pushToFeed pathArray: ", pathArray)

                                if(pathArray.includes("question")){
                                    console.log("Pushing to Answer")
                                    setTimeout(() => { router.push('/answer')}, 4000)
                                };

                                }

                        return { pushToFeed }
                    
                    }

                }


</script>

<style>

.responseButton {

    width:74px;
    height: 50px;
    background-color: transparent;
    color:#660f56;
    font-size: x-small;
   
}

.clickedClass {
    background-color: transparent;
    color:gray;
}

</style>