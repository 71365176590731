<template>
    <div class="grad">

        <div :class="{'padding-desktop' : mobileDevice() }">
            <div class="text-start slogan-style text-block"
               
                >{{ slogan }}</div>  
            
        </div>

            <div class="d-flex align-items-end" style="height:100vh">    
                <img class="img-fluid" :src="src">
            </div> 


        <!-- <div class="d-flex flex-row col-sm-3 text-start p-5 white-font">
              <h2 class="headingFont bold-font" :slogan="slogan">{{ slogan }}</h2>
              <h5 class="headingFont my-4" :paragraph1="paragraph1"> {{ paragraph1 }}</h5>
              <h5 class="headingFont my-4" :paragraph2="paragraph2"> {{ paragraph2 }}</h5>
              <h5 class="headingFont my-4" :paragraph3="paragraph3"> {{ paragraph3 }}</h5>
        </div>       -->
    </div>
</template>

<script>

import { ref, onMounted } from 'vue'
import mobileDevice from '../composables/mobileDevice.js'
import MobileExtra from './MobileExtra.vue'

export default {

    props: ['src', 'slogan', 'paragraph1','paragraph2', 'paragraph3'],

    components: { MobileExtra },

    setup(){

        const slogan = ref("Virtual help, from real people, instantly.")
        // const slogan = ref("Virtual help from real people, instantly.\n\nVirtual Helper = 'Velper'") 
        

        // const mobileDevice = ref(null)

        // onMounted (() => {
        //   mobileDevice.value = isMobileDevice()
        // })

        return { mobileDevice, slogan }

    }

}
</script>

<style scoped>

.padding-desktop {
    padding: 7%
}

.padding-mobile {
    padding: 20% 10%
}

.extraPadding {

  padding-top:10%; 
  padding-bottom:10%

}

.this-page-height {
  min-height: 100vh;
}

.extra-margin-mobile {
  margin-top: 15vh
}

.image-margin-left {    
    margin-left:35%
}

.image-max-width {
    max-width: 1200px;
    min-width: 1000px;
}

.image-adjust-mobile {

    width: 100vw; 
}

.slogan-style {
    z-index:3; 
    max-width: 600px; 
    color: white;
    font-size: 3em;
    line-height: 115%;
	/* font-family: 'Alfa Slab One', cursive; */
	font-family: 'Russo One', sans-serif;
}









</style>