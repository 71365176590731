const getParticipantDetails = (collection, sessions, theRoom, theRole) => {

    collection.where("RoomID", "==", theRoom).get()

            .then((querySnapshot) => {
                
                querySnapshot.forEach((doc) => {
               
                    const { RoomID, ...rest } = doc.data()

             
                    if(rest.studentFirstName){
                      
                        sessions.doc(theRoom).set({

                            studentEmail: rest.email,
                            studentUID: rest.uid,
                            studentFirstName: rest.studentFirstName
    
                        },{merge:true})
        
                    } 

                    if(rest.teacherFirstName){
                       
                        sessions.doc(theRoom).set({

                            teacherEmail: rest.email,
                            teacherUID: rest.uid,
                            teacherFirstName: rest.teacherFirstName
    
                        },{merge:true})


                    }
               
                 
            
                });

                
            }).catch((error) => {
                console.warn("collection", "Error getting document:", error);

    })

}

export default getParticipantDetails