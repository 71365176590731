<template>
        <!-- brightness fliter changes placeholder color (from grey to white) -->
        <el-input
                :autosize="{ minRows: 1, maxRows: 24 }"
                type='textarea' 
                :input-style="{
                        
                        border: '0px',
                        fontSize: 'medium',
                        paddingTop:'15px',
                        paddingBottom:'15px',
                        borderRadius:'0',
                        backgroundColor: 'transparent',
                        minWidth: '100%',
                        fontWeight: '500'
                        

                }"
            />

      

           
  
</template>

<script>

import FAEditRegular from '../components/icons/FAEditRegular.vue'
import mobileDevice from '../composables/mobileDevice.js'
export default {

        components: { FAEditRegular },
        props: ['inputMinWidth'],

        setup() {

                return { }

        }

}
</script>

<style>
.placeHolderWhite::placeholder {
  color: white;
  
}




</style>