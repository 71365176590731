<template>

<div class="text-end emailStyle mx-5">Hi {{ displayName }}</div>
  
</template>

<script>

import { ref, onMounted } from 'vue'
import firebase from 'firebase'

export default {
  

    setup(){

            const email = ref('')
            const uid = ref('')
            const displayName = ref('')


            firebase.auth().onAuthStateChanged((user) => {

            if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            displayName.value = user.displayName;
            email.value = user.email;
            uid.value = user.uid;
            // const photoURL = user.photoURL;
            // const emailVerified = user.emailVerified;

            // The user's ID, unique to the Firebase project. Do NOT use
            // this value to authenticate with your backend server, if
            // you have one. Use User.getToken() instead.
            
            }

            })

            return { email, uid, displayName } 
 
    }


}
</script>



<style scoped>

.emailStyle {
    margin-top:75px
}





</style>