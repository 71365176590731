import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyDtnbOZijiZ3FI9_PLAqsm9Ka-BCQVxwJ4",
  authDomain: "udemy-vue-firebase-sites-ea94e.firebaseapp.com",
  projectId: "udemy-vue-firebase-sites-ea94e",
  storageBucket: "udemy-vue-firebase-sites-ea94e.appspot.com",
  messagingSenderId: "779733155525",
  appId: "1:779733155525:web:845c0e19b7cffc63c2faac"


  // this is the OYF info - Firestore Database is inside OYF which is on 'blaze' plan
  // apiKey: "AIzaSyBwFlPuk7yeL2G_PGZoSPAbewpC2ww0Ydc",
  // authDomain: "on-your-frequency-notify.firebaseapp.com",
  // databaseURL: "https://on-your-frequency-notify.firebaseio.com",
  // projectId: "on-your-frequency-notify",
  // storageBucket: "on-your-frequency-notify.appspot.com",
  // messagingSenderId: "582512377222",
  // appId: "1:582512377222:web:c87df1497da0d607a60007"

};

  // init firebase
  firebase.initializeApp(firebaseConfig)

  const projectFirestore = firebase.firestore()
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectFirestore, timestamp }