<template>
    <textarea ref="myTextArea"  rows="1" v-model="content"></textarea>
</template>

<script>

import { ref, watch } from 'vue'

export default {


    setup(props, context){

        const content = ref("")
        const myTextArea = ref([]); // remove <...> if JS

        // const useTextArea = () => {
            
        //     console.log("LAJKLDSJKAL")
            
        // }

          watch(() => {

            if (content.value.length === 0) return;
            myTextArea.value.rows = 1; // depends on what you want initially
            const styles = window.getComputedStyle(myTextArea.value);
            const paddingTop = parseInt(styles.paddingTop);
            const paddingBottom = parseInt(styles.paddingBottom);
            const padding = paddingTop + paddingBottom;
            const currentHeight = parseInt(styles.height) - padding;
            const initialHeight =
            (parseInt(styles.height) - padding) / myTextArea.value.rows;
            const scrollHeight = myTextArea.value.scrollHeight - padding;
            const newRows = Math.ceil(scrollHeight / initialHeight);
            myTextArea.value.rows = newRows;
    });


        return { myTextArea, content }

        }

        
    }

</script>

<style>
textarea {
  min-width: 100%;
  max-width: 1000px;
  /* min-height: 100%; */
  /* height: 150px; */
  /* padding: 15px 15px; */
  /* box-sizing: border-box; */
  border: none;
  background-color: transparent;
  /* font-size: medium; */
  resize: none;
  border-radius: 0px;
  outline: none;
  font-weight: 400;
}

</style>