<template>
    <div>
        <h1> Create an Account </h1>
        <p> <input type='text' placeholder="Email" v-model='email'/> </p>
        <p> <input type='password' placeholder="Password" v-model='password'/> </p>
        <p> <button @click="register"> Submit </button> </p>
        <p> <button @click="sigIn"> Sign In </button> </p>
        <p> <button @click="signout"> Sign Out </button> </p>
        <p> <button @click="emailOfThisUser"> checkEmail </button> </p>
        <p> <button @click="addElement"> add button </button> </p>
        <p> <button @click="addToFireStore"> add to firestore </button> </p>
        <p> <button @click="QueryFireStore"> Query </button> </p>
        
    </div>
  
</template>

<script>

import { ref } from 'vue'
import firebase from 'firebase'
import { useRouter } from 'vue-router' // import router

export default {

    setup(){

        const db = firebase.firestore();

        const email = ref('')
        const password = ref('')
        const router = useRouter() // get a reference to our vue router

        const register = () => {

                firebase

                    .auth() // get the auth api
                    .createUserWithEmailAndPassword(email.value, password.value) // need .value because ref()
                    .then((data) => {
                    console.log('Successfully registered!');
                    router.push('/') 
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });
                }

        const sigIn = () => {

            console.log("SIGN IN")

            firebase.auth()
                    .signInWithEmailAndPassword(email.value, password.value)
                    .then((data) => {
                    console.log('Successfully Signed In!');
                    router.push('/') 
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }

        const signout = () => {

            firebase.auth()
                    .signOut()
                    .then((data) => {
                    console.log('Successfully Sign-out!');
                    router.push('/') // redirect to the feed
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }

        const emailOfThisUser = () => {
  
            if(firebase.auth().currentUser !== null){
                let theEmail = firebase.auth().currentUser.email
                console.log("user email: ", theEmail)
            } else {
                console.log("No User signed it")
            }
            
        }

        const addElement = () => {
                let btn = document.createElement("DIV");
                btn.innerHTML = "CLICK ME";
                btn.style.backgroundColor = "black";
                btn.style.width = "50%";
                document.body.appendChild(btn);
                }



        const addToFireStore = () => {

                var citiesRef = db.collection("cities");

                citiesRef.doc("SF").set({
                    name: "San Francisco", state: "CA", country: "USA",
                    capital: false, population: 860000,
                    regions: ["west_coast", "norcal"] });
                citiesRef.doc("LA").set({
                    name: "Los Angeles", state: "CA", country: "USA",
                    capital: false, population: 3900000,
                    regions: ["west_coast", "socal"] });
                citiesRef.doc("DC").set({
                    name: "Washington, D.C.", state: null, country: "USA",
                    capital: true, population: 680000,
                    regions: ["east_coast"] });
                citiesRef.doc("TOK").set({
                    name: "Tokyo", state: null, country: "Japan",
                    capital: true, population: 9000000,
                    regions: ["kanto", "honshu"] });
                citiesRef.doc("BJ").set({
                    name: "Beijing", state: null, country: "China",
                    capital: true, population: 21500000,
                    regions: ["jingjinji", "hebei"] });
                    
                }

        const QueryFireStore = () => {

               var docRef = db.collection("cities").doc("SF");

                docRef.get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data:", doc.data());
                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });

        }

// listening for changes in the "SF" item
        db.collection("cities").doc("SF")
            .onSnapshot((doc) => {
                console.log("Current data: ", doc.data());
            });

       

        
        return { email, password, router, register, signout, sigIn, emailOfThisUser, addElement, addToFireStore, QueryFireStore  }

    }

}
</script>

<style>

</style>