<template>
    <button class="endVideoClass">
        <ScreenShare />       
    </button>  
</template>

<script>

import ScreenShare from '../components/icons/ScreenShare.vue'

export default {
    components: { ScreenShare }

}
</script>

<style>

</style>