<template>  
    <Nav />
    <div class="p-5 my-3" style="height:120px; font-weight:bold">Please enter your details, and choose a password</div>


    <div class="d-flex justify-content-center align-items-center row">

        <el-form 
            class="col-sm-3 p-5" 
            v-if="!signedIn" 
            label-width="100px"        
            >

            <el-form-item label="First Name"> 
                <el-input type='text' v-model='firstName' @keydown.enter.prevent="register"></el-input> 
            </el-form-item>
            <el-form-item label="Last Name"> 
                <el-input type='text' v-model='lastName' @keydown.enter.prevent="register"></el-input> 
            </el-form-item>
            <el-form-item label="Email"> 
                <el-input type='email' v-model='email' @keydown.enter.prevent="register"></el-input> 
            </el-form-item>
            <!-- <el-form-item label="Tel"> 
                <el-input type='tel' v-model='tel'></el-input> 
            </el-form-item> -->
            <el-form-item label="Password"> 
                <el-input type='password' v-model='password' @keydown.enter.prevent="register"></el-input> 
            </el-form-item>
             <el-button @click.prevent="register" type="warning" round>next</el-button>
      
        </el-form>
        <div class="p-5 col-sm-3">
            <img src="../assets/AfroFemCircled.png" alt="" class="img-fluid">
        </div>

        </div>
    
</template>

<script>


import { ref } from 'vue'
import Nav from '../components/Nav.vue'
import firebase from 'firebase'
import { useRouter } from 'vue-router'
import { timestamp } from '../firebase/config'



export default {

    components: { Nav },

            setup(){
            
            const db = firebase.firestore();
            const firstName = ref('')
            const lastName = ref('')
            const email = ref('')
            const password = ref('')
            const router = useRouter()

            const register = () => {

                if((firstName.value != '') && (lastName.value != '')){

                firebase

                    .auth() // get the auth api
                    .createUserWithEmailAndPassword(email.value, password.value) // need .value because ref()
                    .then((data) => {
                    console.log('Successfully registered!');
                    // router.push('/')
                    // registered.value = true 
                    })
                    .then(onSuccess => {
                        console.log("SUCCESS")
                        createUserProfile(email.value, firstName.value, lastName.value )
                        router.push('/validatephoneno')
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });
                } else {
                    alert("Please enter your first and last name.")
                }
                }

            const createUserProfile = (email, firstName, lastName ) => {

                    const uid = firebase.auth().currentUser.uid
                    const phone_verification_code = () => {
                        return Math.floor(Math.random() * 900000) + 100000;
                        }

                    db.collection("users").doc(email).set({
                        firstname: firstName,
                        lastname: lastName,
                        email: email,
                        // tel: tel,
                        uid: uid,
                        instructor: false,
                        joined: timestamp(),
                        phone_verification_code:phone_verification_code()
                    })
                    .then(() => {
                        console.log("Document successfully written!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });

            }

                return { register, createUserProfile, firstName, lastName, email, password }

            }



}
</script>

<style>

</style>