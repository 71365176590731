<template>
  <div v-if="isCode">
    <el-button type="text" style="font-size:small">{{ buttonLabel }}</el-button>
  </div>

</template>

<script>

export default {

  props: [ 'buttonLabel', 'isCode'],

  // setup (){

  // }

}



</script>




<style>

</style>