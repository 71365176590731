// ALL TIMES  SHOULD BE CALCAUTED IN MILISECONS, THEN DISPLAYED LATER IN MINS AND SECS

// 7200000 milisecs = 2 hours
// 3600000 milisecs = 1 hour
// 60000 milisecs = 1 min

const millisToMinutesAndSeconds = (millis) => {

    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }
  
  
const timeExample = Math.floor((Math.random() * 3600000) + 60000);
// Test Examples
//   const timeExample = 300000; // 5 mins
//   const timeExample = 360000; // 6 mins
//   const timeExample = 420000; // 7 mins
//   const timeExample = 600000; // 10 mins
//   const timeExample = 900000; // 15 mins
  const discountExample = () => {
      const choice = Math.random().toFixed() // returns 0 or 1
      if(choice == 0){
            console.log('NO DISCOUNT')
            return 0
      } else {
            console.log('NO DISCOUNT')
            return 0
      }
  }

  const freeMinsExample = () => {
        // const choice2 = Math.random().toFixed() // returns 0 or 1
        // if(choice2 == 0){
        //     console.log('5 FREE MINS')
        //     return 5
        // } else {
        //     console.log('NO FREE MINUTES')
        //     return 0
        // }

        console.log('NO FREE MINUTES')
        return 0


}
  const expertPercentageExample = Math.floor((Math.random() * 11) + 20); // this returns a number betweem 20 - 30
  const costPerMillisecond = (costPerMinute) => { return costPerMinute / 60000 }
  let costForMinutesChargedOnly;


  
  const calculateSessionFees = (freeMinutes, sessionDuration, costPerMinute, percentageConnectionFee, defaultConnectionFee, percentageDiscount, percentageDeductionFromExpertsFee) => {
  
    console.log('costPerMillisecond: ', costPerMillisecond(costPerMinute))
    const minutesCharged = millisToMinutesAndSeconds((timeExample - (freeMinutes * 60000))) // calculate in milliseconds
    const minutesChargedInMillisecs = timeExample - (freeMinutes * 60000) // calculate in milliseconds
    costForMinutesChargedOnly = minutesChargedInMillisecs * costPerMillisecond(costPerMinute)
  
    let connectionFeeInDollars = (minutesChargedInMillisecs * costPerMillisecond(costPerMinute) / 100 * percentageConnectionFee).toFixed(2)
    let correctConnectionFeeInDollars

    if(Number(connectionFeeInDollars) < defaultConnectionFee)
        {
            correctConnectionFeeInDollars = defaultConnectionFee
            console.log('Connection fee ($' + connectionFeeInDollars + ') is LESS than default amount ($' + defaultConnectionFee + ')')
        }
        else {
            correctConnectionFeeInDollars = connectionFeeInDollars
            console.log('Connection fee ($' + connectionFeeInDollars + ') is GREATER than default amount ($' + defaultConnectionFee + ')')
        }
    
  
    const costIncudingFreeMinutes = (minutesChargedInMillisecs * costPerMillisecond(costPerMinute)).toFixed(2)
    const costBeforeDiscount = (Number(correctConnectionFeeInDollars) + Number(costIncudingFreeMinutes)).toFixed(2)
  
 
  
    const discountInDollars = (Number(costBeforeDiscount)) / 100 * percentageDiscount
    const totalCostOfMinutes = Number(costBeforeDiscount) - Number(discountInDollars)
    const finalCostToUSer = () => { if(totalCostOfMinutes < 15){ return 15} else { return totalCostOfMinutes } }
    
  
    let totalDiscountToUser = discountInDollars + freeMinutes     // (freeMinutes * costPerMinute).toFixed(2))
    

    const finalFeeFromExpert = () => { 
        if(totalCostOfMinutes < 15){ 
            costForMinutesChargedOnly = 15
            correctConnectionFeeInDollars = 0
            return 15 / 100 * percentageDeductionFromExpertsFee
        } else { 
            return feeFromInstructor } 
        }

    const feeFromInstructor = costForMinutesChargedOnly / 100 * percentageDeductionFromExpertsFee
    
    
  
    console.log('expertPercentageExample', expertPercentageExample)
    console.log('timeExample in milliseconds', timeExample)
    console.log('timeExample in mins and secs', millisToMinutesAndSeconds(timeExample) + ' mins')
    console.log('ORIGINAL COST: ' + millisToMinutesAndSeconds(timeExample) + ' mins @ $' + costPerMinute.toFixed(2) +' per min, costs $' + (timeExample * costPerMillisecond(costPerMinute)).toFixed(2))
    console.log('sessionDuration:', sessionDuration, 'mins')
    console.log('freeMinutes:', freeMinutes, 'mins')
    console.log('minutesCharged:', minutesCharged, 'mins')
  
    console.log('minutesChargedInMillisecs:', minutesChargedInMillisecs, 'millisecs')
    console.log('COST WITH FREE MINUTES: ' + millisToMinutesAndSeconds(minutesChargedInMillisecs) + ' mins @ $' + costPerMinute.toFixed(2) +' per min, costs $' + costIncudingFreeMinutes)
  
  
    console.log('value of free minutes $' + (freeMinutes * costPerMinute).toFixed(2))
  
    /////////// Put connection fee in dollars here
    console.log('Connection fee @', percentageConnectionFee + '%: $' + connectionFeeInDollars)
    console.log('Connection fee applied is $' + (defaultConnectionFee.toFixed(2)) + ' if original amount is less than $' + (defaultConnectionFee.toFixed(2)))
    console.log('Connection fee Applied $' + correctConnectionFeeInDollars)

    console.log('costBeforeDiscount ($' + Number(correctConnectionFeeInDollars) + ' + $' + Number(costIncudingFreeMinutes) + ') $' + costBeforeDiscount)
  
  
    console.log('session percentage discount (if any): ' + percentageDiscount + '%')
    console.log('discountInDollars: $' + discountInDollars.toFixed(2))
  
    console.log(' ')
    console.log('TOTAL COST TO USER')
    console.log(millisToMinutesAndSeconds(timeExample) + ' mins session with ' + freeMinutes + ' free minutes')
    console.log(millisToMinutesAndSeconds(minutesChargedInMillisecs) + ' mins charged.')
    console.log('* CHARGES: $' + totalCostOfMinutes.toFixed(2) + ' * (including connection fee of $' + correctConnectionFeeInDollars + ')' )
    console.log('---------------- ')
    console.log('If total charges are less than $15.00, user will be charged a flat fee of $15.00')
    console.log("$" + totalCostOfMinutes.toFixed(2), "is greater than $15.00: ", (totalCostOfMinutes > 15))
    console.log("****** Final Cost To USer: $" + finalCostToUSer().toFixed(2) + " ******")
    console.log(' ')
    console.log('---------------- ')
    console.log('EARNINGS FOR EXPERT')
    
    console.log('Expert5 percentage decucted from expert/instrutor pay: ' + percentageDeductionFromExpertsFee + '%')
    console.log('If total charges to user are less than $15.00, expert will be paid $15.00 minus fee to Expert5 of',  percentageDeductionFromExpertsFee + '%')
    console.log("$" + totalCostOfMinutes.toFixed(2), "is greater than $15.00: ", (totalCostOfMinutes > 15))

    // console.log('decucted Amount $' + feeFromInstructor.toFixed(2))
    console.log("Final Fee From Expert: $" + finalFeeFromExpert().toFixed(2))
    console.log('Instructor fees before deductions (does not include connection fee): $' + costForMinutesChargedOnly.toFixed(2), 'includes ' + freeMinutes + ' free minutes')
    
    console.log(' ')
    console.log('Expert Earnings for this session')
    console.log('******* $' + (costForMinutesChargedOnly - finalFeeFromExpert()).toFixed(2) + ' ******')
    console.log(' ')
    console.log('---------------- ')
    console.log('FEES TO EXPERT 5')
    console.log('Fee from instructor ($' + finalFeeFromExpert().toFixed(2) + '), plus Connection Fee ($' + correctConnectionFeeInDollars + ')')
    console.log('******* $' + (Number(finalFeeFromExpert()) + Number(correctConnectionFeeInDollars) - discountInDollars).toFixed(2) + ' ******')
    console.log(' ')
    console.log('---------------- ')
  
  
  }
  
//   calculateSessionFees(
//                         freeMinsExample(),    // freeMinutes,
//                         millisToMinutesAndSeconds(timeExample),   // sessionDuration
//                         2,  // costPerMinute
//                         15,   // percentageConnectionFee
//                         2,    // defaultConnectionFee
//                         discountExample(),   // percentageDiscount
//                         expertPercentageExample    // percentageDeductionFromExpertsFee
//                       )
  
export default calculateSessionFees