<template>
  <div>{{ zero_hr }}{{ hr }}:{{ zero_min }}{{ min }}:{{ zero_sec }}{{ sec }}</div>
</template>

<script>
import { ref } from 'vue';


export default {

    props: [ 'showTimer', 'session_ID' ],

    setup(props){

            const hr = ref(0);
            const min = ref(0);
            const sec = ref(0);
            const stoptime = ref(true);
            const zero_sec = ref('0')
            const zero_min = ref('0')
            const zero_hr = ref('0')

            const timerCycle = () => {

                    sec.value = sec.value + 1;

                    if (sec.value == 60) {
                        min.value = min.value + 1;
                        sec.value = 0;
                        }
                    if (min.value == 60) {
                        hr.value = hr.value + 1;
                        min.value = 0;
                        sec.value = 0;
                        }

                    if (sec.value < 10 || sec.value == 0) {
                        zero_sec.value = '0'
                    } else {
                        zero_sec.value = ''
                    }
                    if (min.value < 10 || min.value == 0) {
                        zero_min.value = '0'                       
                    } else {
                        zero_min.value = '' 
                    }
                    if (hr.value < 10 || hr.value == 0) {
                        zero_hr.value = '0'
                    } else {
                        zero_hr.value = ''
                    }
                
            
            }

                const startTimer = () => {
                    
                    if(props.showTimer === true)
                    setInterval(timerCycle, 1000);

                }

                const stopTimer = () => {
                if (stoptime.value == false) {
                    stoptime.value = true;
                }
                }

            
                startTimer()
            

                

        return { hr, min, sec, startTimer, stopTimer, timerCycle, zero_sec, zero_min, zero_hr }
    }

}
</script>



<style>

</style>