import { createRouter, createWebHistory } from 'vue-router'
import FrontPage from '../views/FrontPage.vue'
import Pricing from '../views/Pricing.vue'
import Subjects from '../views/Subjects.vue'
import Apply from '../views/Apply.vue'
import Feed from '../views/Feed.vue'
import Profile from '../views/Profile.vue'
import SignUp from '../views/SignUp.vue'
import SignIn from '../views/SignIn.vue'
import EmailAuth from '../views/EmailAuth.vue'
import Refs from '../views/Refs.vue'
import SingleQuestion from '../views/SingleQuestion.vue'
import ValidatePhoneNo from '../views/ValidatePhoneNo.vue'
import NotFound from '../views/NotFound.vue'
import Video from '../views/Video.vue'
import Fees from '../views/Fees.vue'
import InputTest from '../views/InputTest.vue'
import firebase from 'firebase'

const questionsCollection = firebase.firestore().collection("questions");
let pageRef

questionsCollection.get()
    .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            // console.log("doc.id", doc.id);
            
            pageRef = doc.id
            

        });
    })
    .catch((error) => {
        console.log("Error getting documents: ", error);
    });


const routes = [

  {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    component: NotFound
  },



  {
    path: '/pricing',
    name: 'Pricing',
    component: Pricing
  },

  {
    path: '/subjects',
    name: 'Subjects',
    component: Subjects
  },

  {
    path: '/',
    name: 'FrontPage',
    component: FrontPage
  },

  {
    path: '/apply',
    name: 'Apply',
    component: Apply
  },

  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },

  {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  },

  {
    path: '/answer',
    name: 'Answer',
    component: Feed
  },

  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },

  {
    path: '/refs',
    name: 'Refs',
    component: Refs
  },

  {
    path: '/emailauth',
    name: 'EmailAuth',
    component: EmailAuth
  },

  {
    path: '/question/:pageRef', // /:id
    name: 'Question',
    component: SingleQuestion
  },
  {
    path: '/validatephoneno',
    name: 'ValidatePhoneNo',
    component: ValidatePhoneNo
  },

  {
    path: '/ask',
    name: 'Ask',
    component: Video
  },

  {
    path: '/inputTest',
    name: 'InputTest',
    component: InputTest

  },

  {
    path: '/fees',
    name: 'Fees',
    component: Fees

  }
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
