<template>
  <Nav/>
  SUBJECTS PAGE
  <NavBottom />
</template>

<script>

import NavBottom from '../components/NavBottom.vue'
import Nav from '../components/Nav.vue'

export default {

    components: { NavBottom, Nav}

}
</script>

<style>

</style>