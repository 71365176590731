import firebase from 'firebase'
import mobileDevice from '../composables/mobileDevice.js'



const stopVideoStream = () => {

    const teacherAccessCollection = firebase.firestore().collection("teacherAccess");
    const studentAccessCollection = firebase.firestore().collection("studentAccess");
    const screenShareAccessCollection = firebase.firestore().collection("screenShareAccess")
    const accessCollectionArray = [ teacherAccessCollection,  studentAccessCollection, screenShareAccessCollection]
    const docIDArray = []
    let lastItem


    firebase.auth().onAuthStateChanged((user) => {

        if (user) {

            const email = user.email
       
            studentAccessCollection.where("email", "==", email).orderBy("datenow", "desc").limit(1)
            .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        console.log("Session to be ended:", doc.id);
                        lastItem = doc.id
                    })
                }).then(() => {

                    accessCollectionArray.forEach(element => { 

                        element.doc(lastItem).set({
                        roomstatus: "disconnected",
                        accessToken: firebase.firestore.FieldValue.delete()

                     },{merge:true})

                    })

                }).catch((error) => {
                    console.log("Error getting documents: ", error);
                });

 
        
    
        } else {
            console.log("No USer")
        }

    })
        
        
}



export default stopVideoStream