
<template>
    <p>
        <el-input 
            class="imputStyle"
            type="textarea"
            :minrows='minrows'
            :maxrows='maxrows'
            :autosize="{ minRows: minrows, maxRows: maxrows}"
            :placeholder="subject" 
            v-model="statementinput"
            :id="subject"
            :maxlength="maxlength"
            show-word-limit
            :paddingbottom="paddingbottom"
            :input-style="{'padding-bottom': paddingbottom}"
        >
        </el-input></p>
</template>

<script>

    import { defineComponent, ref } from 'vue'
    
    
    export default defineComponent ({

        props: ['subject', 'minrows', 'maxrows', 'maxlength', 'paddingbottom'],

        setup() {

            const statementinput = ref('')
        
        
            return {

                statementinput

            }
        }
        })
</script>

<style>

.imputStyle {
    width: 305px;
}
textarea {
    /* border-color: #909399 !important; */
    resize: none !important;
    -webkit-appearance: none;
    box-shadow: none !important;
    
    }

::placeholder { 
  color:#606266 !important;;
  opacity: 1;
}





</style>