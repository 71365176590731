<template>

    <Nav />
    <WriteSendButton 
        :page="getPagePath()"
        :showTimer="sessionConnected"
        :hangUpButton="sessionConnected"
        @stop-Video-Stream-From-Answer-Page="stopVideoStreamFromAnswerPage"
    />

    <DesktopControlButtons 
        v-if="sessionConnected"
        :stopVideoStream="stopVideoStreamFromAnswerPage"
        :openFullScreen="openFullScreen"
        :requestPictureInPicture="requestPictureInPicture"
        :screenShare="makeScreenShare"
        :room="theRoomID"
        :uid="currentUserUID"
        :userFirstName="currentUserFirstName"
         />
    
    <div class="greyWhite feedWidthMobile">

      <div class="screenShareClass" id="screenshare" style="margin-top:10vh;display:block"></div>
    
      <div id="current_session_ID" style="display:none"></div>

       
      <div class="d-flex justify-content-center my-5"
        :class="{'thisMarginTop' : isMobileDevice()}"
      >
          <div class="d-flex flex-column-reverse">
            <Post v-for="(post, i) in posts" 
              :message="post.question"
              :codeSelected="post.codeSelected"
              :code="post.code"
              :sender="post.sender"
              :subject="post.subject"
              :senderTel="post.senderTel"
              :emailToCheck="post[emailVar]"
              :mediaUrl="post.media_URL"
              :key="post.id"
              :id="post.id"
              :source="post.source"
              :class="{ boxWidthDesktop: desktopWidth }" 
              :theroom="post.id"   
              :uid="currentUserUID"
              :userFirstName="currentUserFirstName"
              :mobile_device="post.mobile_device" 
              :isVideoConnection="post.isVideoConnection"
              :index="i"
              @stop-feed-questions="stopTheFeed"
              class="mb-4 animate__animated animate__fadeIn animate__slow"
          
            />
          </div>
        </div>

        <div class="d-flex justify-content-center">
          <div class="d-flex flex-column-reverse mx-5">
            <Post 
              message="This is your feed. Questions on subjects you know, will appear here in real time. Click the camera or mic icon if you are able to answer the question, and have 5 mins to talk."
              subject="example post" 
              id="examplepostID"
              :class="{ boxWidthDesktop: desktopWidth }"  
            />
          </div>
        </div>
        </div>
        <NavBottom />

</template>

<script>

import Nav from '../components/Nav.vue'
import Post from  '../components/Post.vue'
import CurrentUser from '../components/CurrentUser.vue'
import firebase from 'firebase'
import { ref, onMounted, watchEffect } from 'vue'
import NavBottom from '../components/NavBottom.vue'
import isMobileDevice from '../composables/mobileDevice'
import { propsToAttrMap } from '@vue/shared'
import useCurrentSessionID from '../composables/useCurrentSessionID'
import WriteSendButton from '../components/WriteSendButton.vue'
import getPagePath from '../composables/getPagePath.js'
import DesktopControlButtons from '../components/DesktopControlButtons.vue'
import openFullScreen from '../composables/requestFullScreen.js'
import requestPictureInPicture from '../composables/requestPictureInPicture.js'
import screenShare from '../composables/screenShare.js'




export default {

  components: { Nav, Post, CurrentUser, NavBottom, WriteSendButton, DesktopControlButtons},
  

  setup(){

              const questionsCollection = firebase.firestore().collection("questions");
              const usersCollection = firebase.firestore().collection("users");
              const teacherAccessCollection = firebase.firestore().collection("teacherAccess");
              const studentAccessCollection = firebase.firestore().collection("studentAccess");
              const screenShareAccessCollection = firebase.firestore().collection("screenShareAccess")
              const sessionsCollection = firebase.firestore().collection("sessions")
              const accessCollectionArray = [ teacherAccessCollection,  studentAccessCollection, screenShareAccessCollection]
              const user = ref(null)
              const mobileDevice = ref(null)
              const mobileWidth = ref(null)
              const desktopWidth = ref(null)
              const posts = ref([])
              const mediaURLToShow = ref(null)
              const currentUserFirstName = ref(null)
              const currentUserUID = ref(null)
              const currentUserEmail = ref(null)
              const clicked = ref(false)
              const expertSubjects = ref([])
              const alreadyRespondedToThisQuestion = ref(false)
              const respondedclass = ref(false)
              const currentUserHasResponded = ref(false)
              const emailVar = ref(null)
              const loading = ref(true)
              const sessionConnected = ref(false)
              const theRoomID = ref(null)

              const makeScreenShare = () => {

                // LONG-WINDED FUNCTION, SCREENSHARE WORKS THIS WAY
                theRoomID.value = document.getElementById("current_session_ID").innerText
                return screenShare(theRoomID.value, currentUserUID.value, currentUserFirstName.value)

              }


         
              const confirmUsersAreConnected = () => {

                const thisSessionID = document.getElementById("current_session_ID").innerText

                sessionsCollection.doc(thisSessionID).onSnapshot((doc) => {

                        if(doc.exists){ 
                            setTimeout(() => { sessionConnected.value = true}, 1000)
                          
                          }
                    });
                  }
              
            

              
              // HERE POSTS ARE DISPLAYED ON CURRENT USERS ANSWER PAGE
              // FILTERED BY 'SUBJECTS' THEY ARE EXPERT IN
              // FIRESTORE REAL TIME LISTENER: https://firebase.google.com/docs/firestore/query-data/listen

              const removeQuestionsListener = questionsCollection.orderBy("datenow", "asc")

                  .onSnapshot((snapshot) => {

                  const user = firebase.auth().currentUser;
                  emailVar.value = user.email // this is used to dynamically set classes in the post 

                  usersCollection.doc(user.email).get().then((doc) => {

                        // HERE CURRENT USER'S EXPERT SUBJECTS ARE PLACED 'EXPERTSUBJECTS' ARRAY
                        const { firstname, subjects, email, uid } = doc.data()
                        currentUserFirstName.value = firstname
                        currentUserEmail.value = email
                        currentUserUID.value = uid
                        expertSubjects.value = Object.keys(subjects)

                        snapshot.docChanges().forEach((change) => {

                              const { question, subject, hasResponders, media_URL, id, imageAdded, source, code, editingCode, pageSentFrom } = change.doc.data()

                              // 'EXPERTSUBJECTS' ARRAY FILTERS QUESTIONS SO ONLY RELEVENT ONES ARE SHOWN TO CURRENT USER
                              if(expertSubjects.value.includes(subject)){

                                    if ((change.type === "added") && (question != null)){
                                          //this gets all the docs with a question and loads them onto the page
                                          posts.value.push(change.doc.data())
                                          console.log("** LOADING PAGE WITH ALL POSTS **")
                                         
                                          }

                                    if ((change.type === "modified") && (question != null) && (hasResponders === false)) {

                                                console.log("Live Code Update from page:", pageSentFrom) 
                                                if((editingCode == true) && (pageSentFrom != "answer")){
                                                  
                                                  
                                                  /// THIS CODE UPDATES/REPLACES ELEMENT WITH SPLICE METHOD - USED HERE FOR LIVE CODE UPDATE
                                                  posts.value.forEach((element) => {
                                                            if(element.id === id){
                                                                  posts.value.splice(posts.value.indexOf(element), 1, change.doc.data()); 
                                                                  console.log("** UPDATING LIVE CODE EDIT **") 
                                                                  
                                                                } 
                                                            })  
                                                  return
                                                }

                                                if((editingCode == true) && (pageSentFrom != "ask")){ return }

                           
                                                if(media_URL == null){

                                                      // THIS IS THE UPDATED POST WITH THE IMAGE 
                                                          posts.value.push(change.doc.data()); 
                                                          console.log("** ADDING LASTEST POST (TEXT ONLY) #### **")

                                                }
                                                                              
                                                else if((media_URL != null) && (imageAdded == null)){

                                                        if(posts.value.includes(change.doc.data())){
                                                          console.log("don't display post")
                                                        } else {
                                                          posts.value.push(change.doc.data());
                                                          console.log("** IMAGE AND TEXT SENT TOGETHER AND LOADED ON PAGE **")
                                                        }

                                                } else {

                                                        posts.value.forEach((element) => {
                                                            if(element.id === id){
                                                                  posts.value.splice(posts.value.indexOf(element), 1, change.doc.data()); 
                                                                  console.log("** ADDING IMAGE TO POST 2!! **") 
                                                                } 
                                                            })  

                                                }

                                            }


                                    if (change.type === "removed") {
                                        console.log("Removed: ", change.doc.data());
                                          }

                                    } 


                              });

                          })
                      })

              const stopTheFeed = () => {

                return removeQuestionsListener(), confirmUsersAreConnected()

              }

              const stopVideoStreamFromAnswerPage = () => {

                  theRoomID.value = document.getElementById("current_session_ID").innerText
                  
                  accessCollectionArray.forEach(element => { 

                    element.doc(theRoomID.value).set({
                    roomstatus: "disconnected",
                    accessToken: firebase.firestore.FieldValue.delete()
                    }, {merge:true})
                    onCall.value = false

               })


              }



  
             
              onMounted(() => {

                if(!isMobileDevice()){ 
                    
                    desktopWidth.value = false


                } else {
                    desktopWidth.value = true
                }

              })




          return { 
            
                    currentUserHasResponded, 
                    emailVar, 
                    currentUserEmail, 
                    user, 
                    respondedclass, 
                    questionsCollection, 
                    posts, 
                    currentUserFirstName, 
                    currentUserUID,
                    clicked, 
                    expertSubjects, 
                    alreadyRespondedToThisQuestion, 
                    mobileDevice, 
                    desktopWidth,
                    mobileWidth, 
                    mediaURLToShow,
                    isMobileDevice,
                    loading,
                    useCurrentSessionID,
                    stopTheFeed,
                    getPagePath,
                    sessionConnected,
                    stopVideoStreamFromAnswerPage,
                    theRoomID,
                    confirmUsersAreConnected,
                    openFullScreen,
                    requestPictureInPicture,
                    screenShare,
                    makeScreenShare
                    
                    
          
              }
  
          }

      }


</script>

<style>



.feedPost {
  background-color: #660f56;
}

.subjectsDiv {
  max-width: 600px;
  /* width:100%; */
  
}

.boxWidthMobile {
  width:100px
}

/* this is the width of the messages on the feed */
.boxWidthDesktop {
  width:49vw

}



</style>