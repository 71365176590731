<template>
  
    <div :class="{'row d-flex justify-content-between align-items-end margin-left-minus-150px': mobileDevice()}">
    <!-- <div class="row d-flex justify-content-between align-items-end" style="margin-left:-150px;"> -->
               
            <Avatar v-if="mobileDevice()" :pic="pic8" :width="425" />
            <Avatar v-if="mobileDevice()" :pic="pic1" :width="475" />
            <Avatar v-if="mobileDevice()" :pic="pic3" :width="550" />
            <Avatar v-if="mobileDevice()" :pic="pic10" :width="570" style="z-index:3" />
            <Avatar v-if="mobileDevice()" :pic="pic4" :width="475" style="z-index:2" />
            <Avatar v-if="mobileDevice()" :pic="pic9" :width="470" style="z-index:2" />
            <Avatar v-if="mobileDevice()" :pic="pic7" :width="450" />
            <Avatar v-if="mobileDevice()" :pic="pic6" :width="500" />
            <Avatar v-if="mobileDevice()" :pic="pic6" :width="400" style="margin-left:0px; opacity:0"/> 

            <!-- <Avatar v-if="!mobileDevice()" :pic="pic13" class="d-flex justify-content-end" :width="450"  /> -->


                        
    </div>


</template>

<script>

import Avatar from './Avatar.vue'
import { ref } from 'vue'
import mobileDevice from '../composables/mobileDevice.js'


export default {

    props: [ 'opacity'],

   components: { Avatar }, 

   setup () {

        const pic1 = ref(require('../assets/BlackFemaleAfro.png'))
        const pic2 = ref(require('../assets/Guy.png'))
        const pic3 = ref(require('../assets/SilverHairGirl.png'))
        const pic7 = ref(require('../assets/IndianManLaballed.png'))
        const pic5 = ref(require('../assets/BaldGuyLabelled.png'))
        const pic6 = ref(require('../assets/ProTools.png'))
        const pic4 = ref(require('../assets/YoungGuy2.png'))
        const pic8 = ref(require('../assets/RedDungarees.png'))
        const pic9 = ref(require('../assets/BlackGuy2.png'))
        const pic10 = ref(require('../assets/BeardGuyLogo.png'))
        const pic11 = ref(require('../assets/BradedBlackFemale.png'))
        const pic12 = ref(require('../assets/Expert5Guy.png'))
        const pic13 = ref(require('../assets/main_guy_mobile_side_crop.png'))

        return { pic1, pic2, pic3, pic3, pic4, pic5, pic6, pic7, pic8, pic8, pic9, pic10, pic11, pic12, pic13, mobileDevice }

   }

}

</script>

<style scoped>
    .margin-left-minus-150px{
        margin-left:-150px;
    }
    

</style>