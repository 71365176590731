<template>
        <router-link class="m-1 p-2 routerclass someFont" 
        :to="{ name: page }">
        <!-- <h6>{{ callToAction }}</h6> -->
        <small style="font-weight:lighter">{{ text }}</small>
        </router-link>  
</template>

<script>

import { useRoute} from 'vue-router'
import { ref, onMounted } from 'vue'

export default {

            props: [ 'callToAction', 'page', 'text' ],

            setup(){

                    const router = useRoute()

                }

        }

</script>


<style>

.theSignUpButton {
    background-color: red;
    width:300px;
    height:80px;
    border-radius: 2px;
}

.someFont {
    font-size: large;
    color:white;
    text-decoration: none;
    font-weight: bold;
}

/* a.joh {
    width:3000px
} */

.routerclass {
  display: inline-block; 
  /* width: 250px;
  height:90px; */
  
  background-color: #ae2d68;
}

</style>