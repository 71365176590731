<template>
    <div class="my-3 border-bottom">
        <div class="d-flex flex-row" v-for="chat in chatMessagesArray" :key="chat">
            <div class="bold-font">{{chat.chatSenderFristName}}:  </div>
            <div class="mx-2" 
                :class="{'teacher': sentByTeacher(chat.chatMessageSenderRole), 'student': !sentByTeacher(chat.chatMessageSenderRole) }"
                v-linkify:options="{target: '_blank', className: 'link-text'}">
            {{ chat.chatMessage}}
            </div>     
        </div>
    </div> 
</template>

<script>

import { ref } from 'vue'
import useCurrentSessionID from '../composables/useCurrentSessionID.js'
import firebase from 'firebase'

export default {

    props: ['onCall'],

    setup(props){
         
        const chatMessages = firebase.firestore().collection("chatMessages")
        const chatMessagesArray = ref([])
        const newChatMessage = ref(null)
        const sessionsCollection = firebase.firestore().collection("sessions")
        const teacherAccessCollection = firebase.firestore().collection("teacherAccess");
        const uid = firebase.auth().currentUser.uid
        const currentUserEmail = firebase.auth().currentUser.email
        const currentUserFirstName = ref(null)
        const session_ID_Array = ref([])
        const currentSessionID = ref(null)
        const role = ref(null)
        const ID_of_Session_Teacher_is_Currently_in = ref(null)
        const teacherSessionIDs = ref([])
        const theCorrectSessionID = useCurrentSessionID()
        const theTeacher = ref(null)
        const senderName = ref(null)
        const textWithLinks = ref(null)
        const teacherMessage = ref(null) //boloean

        const sentByTeacher = (val) => {
            if(val === "teacher"){
               return teacherMessage.value = true
            } else {
               return teacherMessage.value = false
            }
        }


            chatMessages.where("sessionID", "==", theCorrectSessionID).onSnapshot((snapshot) => {

                snapshot.docChanges().forEach((change) => {


                    const { chatMessage, ...rest } = change.doc.data()

                    if (change.type === "added") {

                        console.log("NEW CHAT MESSAGE: ", change.doc.data());
                        chatMessagesArray.value.unshift(change.doc.data())
                    }

                });



            });






        
        return { session_ID_Array, currentSessionID, currentUserFirstName, role, chatMessagesArray, newChatMessage, ID_of_Session_Teacher_is_Currently_in, teacherSessionIDs, useCurrentSessionID, theTeacher, senderName, textWithLinks, teacherMessage, sentByTeacher }


    }

}
</script>

<style scoped>

.link-text {
    text-decoration: none
}


/* CHANGE THESE CLASSES TO DIFFERENTIATE BETWEEN STUDENT AND TEACHER CHAT MESSAGES (CURRENTLY BOTH BLACK) */
.teacher {

    color: black

}

.student {
    color: black
}



</style>