<template>
    <div class="col-sm-3 bold-font">
        <div style="margin-top:2%">
            <div style="color:white; font-size:medium" class="p-3">Instructors, Influencers, Teachers, Coaches! Get more clients, followers, students!</div>
                <el-button type="primary" round>
                    <router-link class="mx-3 applyFont" :to="{ name: 'EmailAuth' }">BECOME ONE OF OUR EXPERTS</router-link> 
                </el-button>
            </div>
        </div>
</template>

<script>
export default {

}
</script>

<style>

</style>