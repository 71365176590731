/* Get the element you want displayed in fullscreen mode (a video in this example): */


/* When the openFullscreen() function is executed, open the video in fullscreen.
Note that we must include prefixes for different browsers, as they don't support the requestFullscreen property yet */
import isMobileDevice from '../composables/mobileDevice'

const openFullscreen = (element) => {

    console.log(element + " is on mobile: " + isMobileDevice())
  
    const elem = document.getElementById(element);
    console.log("openFullscreen")
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}

export default openFullscreen