<template>
    <nav v-if="userSignedIn" id="navBottom" 
        class="navbar fixed-bottom border-top d-flex bg-light justify-content-center"             
        :class="{'bottom-nav-mobile':!isMobileDevice() }">
 
            <div class="d-flex align-items-center">  
                <ChatInput :sessionID="theSessionID" :isAskPage="isAskPage"  v-if="showChatInput"/>
                <MenuItems v-else />
               
            </div>

        </nav>

</template>

<script>

import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import firebase from 'firebase'
import isMobileDevice from '../composables/mobileDevice.js'
import FACodeSolid from '../components/icons/FACodeSolid.vue'
import MenuItems from '../components/MenuItems.vue'
import getPagePath from '../composables/getPagePath.js'
import svgpath from '../composables/getSVGpath.js'
import getCurrentSessionID from '../composables/getCurrentSessionID.js'

import WriteSendButton from '../components/WriteSendButton.vue'
import ChatInput from '../components/ChatInput.vue'

export default {

    props: ['sessionStatusUpdate', 'showTimer'],


    components: { FACodeSolid, MenuItems, WriteSendButton, ChatInput },

    setup(props){

        const questionsCollection = firebase.firestore().collection("questions");
        const teacherAccessCollection = firebase.firestore().collection("teacherAccess");
        const sessionsCollection = firebase.firestore().collection("sessions")
        const email = ref(null)
        const uid = ref(null)
        const showChatInput = ref(false)

        const router = useRouter()
        const video_selected = ref(true)

        const connectionType = ref('video')
        const video_on = ref('on')

        const isAskPage = ref(null)

        const videoSolidIcon = svgpath('videoSolidIcon', 0) 
        const videoSolidSlashIcon = svgpath('videoSolidSlashIcon', 0) 

        const theSessionID = ref(null)

        
        
        const iconPath = () => {

            if(video_selected.value){
                return videoSolidIcon
            } else {
                return videoSolidSlashIcon
            }
        }

        const selectConnectionType = () => {

                if(connectionType.value == 'video' ){
                    connectionType.value = 'audio only'
                    video_on.value = 'off'
                    video_selected.value = false
                    console.log('Audio Only Selected')
                } else {
                    connectionType.value = 'video'
                    video_on.value = 'on'
                    video_selected.value = true
                    console.log('Video Selected')
                }

            }



// THIS FUNCTION LISTENS TO THE 'SESSIONS COLLECTION' TO CONFIRM IF SESSSION HAS STARTED
// IF IT HAS STARTED, THE CHAT INPUT IS SHOWN

        questionsCollection.orderBy("datenow", "desc").limit(1).onSnapshot((snapshot) => { 

                try {

                    
                    firebase.auth().onAuthStateChanged((user) => {

                    if (user) {
                    

                    // const user = firebase.auth().currentUser;
                    email.value =  user.email
                    uid.value = user.uid


                    snapshot.docChanges().forEach((change) => {

                            const { question, ...rest} = change.doc.data()

                            
                            theSessionID.value = rest.id

                            sessionsCollection.where("sessionID", "==", rest.id)
                            
                            .onSnapshot((querySnapshot) => {
                
                                    querySnapshot.forEach((doc) => {
                                
                                        const { sessionID, ...rest } = doc.data()

                                        console.log("SESSION INFO QUERY", 
                                            rest.studentFirstName, 
                                            rest.studentEmail, 
                                            rest.studentUID,
                                            rest.teacherFirstName, 
                                            rest.teacherEmail, 
                                            rest.teacherUID,
                                            rest.sessionStatus,
                                            "THIS ID: ", sessionID)

                                        if( uid.value === rest.teacherUID) {
                                            if(rest.sessionStatus === "started"){
                                                showChatInput.value = true
                                            }      
                                        }
                                        if( uid.value === rest.studentUID ) {
                                            if(rest.sessionStatus === "started"){
                                                showChatInput.value = true
                                            }      
                                        }

                                    })
                                    
                            })


                    })

                    }

                })

                }catch(error){console.warn(error)}
                    

            })


            const userSignedIn = ref(null)

                firebase.auth().onAuthStateChanged((user) => {

                            if (user) {
                                
                                userSignedIn.value = true
                            
                                } else {

                                userSignedIn.value = false

                                }
                            
                            })


        


        

            onMounted(() => {
                isAskPage.value = getPagePath() === ""
                getCurrentSessionID()
            })

            return { isMobileDevice, selectConnectionType, video_selected, connectionType, video_on, getPagePath, svgpath, isAskPage, iconPath, videoSolidIcon, videoSolidSlashIcon, questionsCollection, email, teacherAccessCollection, showChatInput, sessionsCollection, uid, theSessionID, getCurrentSessionID, userSignedIn }
    }

}
</script>

<style>



.bottom-nav-mobile {
    min-height: 55px;
}

.desktop-bottom-nav {
    width: 50vw
}

.small-nudge-up {
  padding-bottom: 6px;
}


</style>