
const getPagePath = () => {

    const pathArray = window.location.pathname.split('/');
    const lastPartOfURL = pathArray[pathArray.length - 1]
    // console.log("***** lastPartOfURL: ******", lastPartOfURL)
    return lastPartOfURL
                
    }

export default getPagePath