<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48"><path d="M4.02 42l41.98-18-41.98-18-.02 14 30 4-30 4z"/><path fill="none" d="M0 0h48v48h-48z"/></svg>
</template>

<script>
export default {

}
</script>

<style>

</style>

