<template>
        <el-input
                v-if="showInput"
                :disabled="testdisabled"
                v-model="data"
                type='textarea'
                placeholder='hello'
                @keydown.enter="changeElement"
                @paste="pasted"
                :autosize="{ minRows: 1, maxRows: 16 }" 
                :input-style="{
                        
                    color:'black',
                    backgroundColor: 'white',
                    border: '0px',
                    padding: '10px',
                    fontSize:'large',
                    width: '500px',
                    fontFamily: 'Arial, Helvetica, sans-serif'
            }"/>

            
            <!-- default element is DIV -->
            <div v-if="showDIV" class="testStyle text-start">{{ data }}</div>

            <!-- if user presses 'return' then show 'pre' tag and hide 'DIV' -->
            <pre v-if="showPreTag" class="testStyle text-start">{{ data }}</pre>

            <button @click="hideInput">send</button>
            <button @click="disableInput">disable</button>

</template>

<script>

import { ref } from 'vue'
export default {

    setup() {

        const data = ref('')
        const showInput = ref(true)
        const preTagSelected = ref(false)
        const divTagSelected = ref(true)
        const showPreTag = ref(false)
        const showDIV = ref(false) 
        const testdisabled = ref(false)

        const hideInput = () => {
            showInput.value = false

            if(preTagSelected.value === true){
                // show pre tag and hide Div
                showPreTag.value = true
            } else {
                showDIV.value = true
            }
        }

        const changeElement = () => {
            console.log("changeElement")
            preTagSelected.value = true
            divTagSelected.value = false

        }

        const pasted = () => {
            console.log("pasted pasted")
            console.log(data.value)

            divTagSelected.value = true
            preTagSelected.value = false
        }

        const disableInput = () => {
            testdisabled.value = true
        }

        return { data, showInput, hideInput, changeElement, showPreTag, showDIV, pasted, testdisabled, disableInput }

    }

}
</script>

<style>

    .testStyle {
        position: fixed;
        padding: 10px;
        font-size: large;
        width: 500px;
        height: 10px;
        font-family: Arial, Helvetica, sans-serif;
        color:black;
        overflow: visible;
    }

    .overflow-scroll {
        overflow: scroll
    }

</style>