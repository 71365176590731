<template>

    <div class="row d-flex align-items-center p-5">    
            <div class="col-sm-3"></div>

             <div class="col-sm-3 grad2 d-flex align-items-start smooth-oblong-right">
              <img :src="src" class="img-fluid">
            </div>

            <div class="col-sm-5 text-start">
              <h2 class="headingFont my-4 bold-font">{{ theHeading }}</h2>
              <div class="display-6 my-4">{{ furtherinfo }}</div>
                <div class="x-large-font">{{ paragraph }}</div>
                <ul class="noBullets p-1 x-large-font">
                    <li v-for="point in bulletpoints" :key="point">
                    {{ point }}
                    </li>
         
                </ul>
            </div>
        
    </div>
  

</template>

<script>

import { ref, onMounted} from 'vue'
import isMobileDevice from '../composables/mobileDevice.js'

export default {

    props: ['src', 'bulletpoints', 'theHeading', 'furtherinfo', 'paragraph'],

        setup(){

        const mobileDevice = ref(false)
        // const points = ref(["one", "two", "three"])
        

        onMounted (() => {
            mobileDevice.value = isMobileDevice()
        })


        return { isMobileDevice, mobileDevice }
    }

}
</script>

<style>

.padding {
    padding-top:10%; 
    padding-bottom:10%
}

.textColor{
    color:white
}

.largerFont {
    font-size: 135%
}





</style>