<template>
  <el-button class="d-flex justify-content-center write-send-button" type="danger">
    <svg xmlns="http://www.w3.org/2000/svg" :width='width' :height="height" :class="{'filter-invert': inverted }" :viewBox="viewBox" :fill='color' :stroke='stroke' :stroke-width='strokeWidth'>
      <path stroke="black" stroke-width="1" :d='path'/>
      <path stroke="black" stroke-width="1" :d='path2'/>
      <!-- <rect x="100" y="100" width="100%" height="100%"/> -->
    </svg>
    {{ buttonText }}
  </el-button>
</template>

<script>

import isMobileDevice from '../composables/mobileDevice.js'

export default {

props: [ 'path', 'path2', 'inverted', 'color', 'width', 'height', 'viewBox', 'stroke', 'strokeWidth', 'buttonText' ], // 'inverted determines if the icon symbol color is 'inverted' or not'

setup(){

  return { isMobileDevice }

}


}
</script>

<style>

</style>