<template>
    <button class="endVideoClass">
        <FAVideoSolidSlash />
            <!-- <div>end</div> -->
        <!-- <transition name="el-fade-in-linear">
            <div v-if="answered">ended</div>
        </transition> -->
    </button>  
</template>

<script>

import VideoCameraFilled from '../components/icons/VideoCameraFilled.vue'
import FAVideoSolidSlash from '../components/icons/FAVideoSolidSlash.vue'

export default {
    components: { VideoCameraFilled, FAVideoSolidSlash }

}
</script>

<style>

</style>