<template>
  
        <div class="row d-flex justify-content-center align-items-center px-1 grad4 white-font" style="padding-top:10%; padding-bottom:10%;">
            <div class="col-sm-3 text-start p-5">
              
              <h2 class="headingFont bold-font text-end">CALLING ALL INFLUENCERS, INSTRUCTORS, COACHES, EXPERTS!</h2>
              <!-- <h2 class="headingFont my-4" style="font-weight:bold">JOIN US!</h2> -->
            </div>

            <div class="col-sm-3 grad3 d-flex align-items-end smooth-oblong-left innerBox">
              <img src='../assets/GirlFX2.png' class="img-fluid" style="object-fit:scale-down;">
            </div>

            <div class="row d-flex justify-content-center">
              <div class="col-sm-4 d-flex justify-content-center"><BoxCard :sometext="'HEADING 1'" :moreinfo="getClients"/></div>
              <div class="col-sm-4 d-flex justify-content-center"><BoxCard :sometext="'HEADING 2'" :moreinfo="yourRate" /></div>
              <div class="col-sm-4 d-flex justify-content-center"><BoxCard :sometext="'HEADING 3'" :moreinfo="entireFee"/></div>
          </div>  
          <el-button type="text" round>
                    <router-link class="mx-3 applyFont" :to="{ name: 'EmailAuth' }">BECOME ONE OF OUR EXPERTS</router-link> 
          </el-button>


    </div>

    

</template>

<script>

import BoxCard from './BoxCard.vue'
import { ref } from 'vue'

export default {

    components: { BoxCard },

    setup(){

        const getClients = ref(
          "Viverra ipsum nunc aliquet bibendum enim. Neque ornare aenean euismod elementum nisi. Est velit egestas dui id ornare arcu. Elit ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at augue. Morbi tincidunt ornare massa eget.")
        const yourRate = ref("Ut tristique et egestas quis ipsum suspendisse ultrices. Viverra mauris in aliquam sem fringilla ut morbi. Ut tortor pretium viverra suspendisse potenti nullam ac tortor. Mauris commodo quis imperdiet massa tincidunt nunc. Nulla facilisi cras fermentum odio eu. Sit amet consectetur adipiscing elit duis. Massa vitae tortor condimentum lacinia quis.")
        const entireFee = ref(
          "Auctor urna nunc id cursus metus. Rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Elit ullamcorper dignissim cras tincidunt lobortis feugiat."
          )

        return { getClients, yourRate, entireFee }

    }

}
</script>

<style>

</style>