<template>
      <div v-if="isMobileDevice()" 
        class="desktop-controls-position d-flex flex-column justify-content-start">
            <el-button v-for="(button) in controlButtons" 
                :key="button"
                @click="button.action(button.param1, button.param2, button.param3)"
                type="danger" 
                class="desktop-send-end-button m-1">
                <FullScreen v-if="button.name == 'fullscreen'" :fill="'white'" :stroke="white" :strokewidth="2" style="margin-left:-2px"/>  
                <HangUp v-if="button.name == 'end'" :fill="'white'" width="35" viewBox="0 0 700 500"  style="margin-left:-7px"/> 
                <!-- <span v-if="button.name == 'end'" style="margin-left:-7px; font-size: medium;">END</span>  -->
                <PictureInPicture v-if="button.name == 'pic-in-pic'" :fill="'white'" style="margin-left:-2px"/>
                <ScreenShare v-if="button.name == 'screenshare'" :fill="'white'" stroke="white" stroke-width="2" style="margin-left:-5px" />
            </el-button>

    </div>
</template>

<script>

import {ref} from 'vue'
import FullScreen from '../components/icons/FullScreen.vue'
import PictureInPicture from '../components/icons/PictureInPicture.vue'
import HangUp from '../components/icons/HangUp.vue'
import ScreenShare from '../components/icons/ScreenShare.vue'
import isMobileDevice from '../composables/mobileDevice.js'

export default {

    components : { FullScreen, PictureInPicture, HangUp, ScreenShare },

    props : [ 'screenShare', 'openFullScreen', 'requestPictureInPicture', 'room', 'uid', 'userFirstName', 'showTimer', 'stopVideoStream' ],

    setup(props) {

        const controlButtons = ref([

            { name:"end", action: props.stopVideoStream}, 
            { name:"screenshare", action: props.screenShare, param1: props.room, param2: props.uid, param3: props.userFirstName},
            { name:"fullscreen", action: props.openFullScreen, param1: "theVideo"}, 
            { name:"pic-in-pic", action: props.requestPictureInPicture, param1: "theVideo"}, 

            ])

        return { isMobileDevice, controlButtons }

    }

}
</script>

<style>

</style>