<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

 
  <path d="m667.1 164.64c-178.45-178.41-467.73-178.39-646.03 0.019531-43.609 43.602-5.9922 166.05-5.9922 166.05 3.2695 10.738 14.945 17.473 25.898 14.918l167.46-38.953c10.953-2.5078 19.902-13.812 19.902-25.047l0.035156-89.488c0-11.234 9.1992-20.434 20.434-20.434h201.15c11.262 0 20.461 9.1992 20.461 20.434l-0.023438 84.27c0.019531 11.234 9.1055 22.016 20.152 23.934l185.34 31.949c11.074 1.9453 21.195-5.6406 22.496-16.801 0.003906 0.007813 12.332-107.25-31.285-150.85z"/>
  <!-- <path d="m392.8 251.5h-84.949v106.57h-63.73l105.91 183.43 105.95-183.43h-63.184z"/> -->

   
</svg>

  
</template>

<script>
export default {

}
</script>

<style>

</style>