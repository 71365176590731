<template>
<!-- <svg width="28px" height="28px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M5.58578644,7 L3,7 L3,17 L15,17 L15,16.4142136 L5.58578644,7 Z M16.6766123,18.0908259 C16.3197659,18.6381832 15.7021364,19 15,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,7 C1,5.8954305 1.8954305,5 3,5 L3.58578644,5 L0.292893219,1.70710678 L1.70710678,0.292893219 L23.7071068,22.2928932 L22.2928932,23.7071068 L16.6766123,18.0908259 Z M17,8.38196601 L23,5.38196601 L23,18.370101 L21,16.370101 L21,8.61803399 L17,10.618034 L17,13.0007865 L15,11.0007865 L15,7 L10.9992135,7 L8.99921354,5 L15,5 C16.1045695,5 17,5.8954305 17,7 L17,8.38196601 Z"/>
</svg> -->


<svg width="28px" height="28px" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.28033 2.21967C2.98744 1.92677 2.51257 1.92677 2.21967 2.21966C1.92678 2.51255 1.92677 2.98742 2.21967 3.28032L4.52132 5.58201C3.07724 5.9128 2 7.2056 2 8.75V19.25C2 21.0449 3.45507 22.5 5.25 22.5H15.7523C17.2959 22.5 18.5882 21.4238 18.9198 19.9807L24.7194 25.7805C25.0123 26.0734 25.4872 26.0734 25.7801 25.7805C26.073 25.4876 26.073 25.0127 25.7801 24.7198L3.28033 2.21967ZM17.5023 18.5632V19.25C17.5023 20.2165 16.7187 21 15.7523 21H5.25C4.2835 21 3.5 20.2165 3.5 19.25V8.75C3.5 7.7835 4.2835 7 5.25 7H5.93929L17.5023 18.5632Z" fill="#212121"/>
<path d="M8.68185 5.5L10.1818 7H15.7523C16.7188 7 17.5023 7.7835 17.5023 8.75V14.3205L19.0023 15.8206V12.1455L24.0021 8.72623V19.2765L19.1028 15.9212L24.1828 21.0012C24.8564 21.0372 25.5021 20.5119 25.5021 19.7508V8.25239C25.5021 7.24731 24.3761 6.65323 23.5464 7.22059L19.0023 10.3283V8.75C19.0023 6.95508 17.5472 5.5 15.7523 5.5H8.68185Z" fill="black"/>
</svg>
</template>

<script>
export default {

}
</script>

<style>

</style>