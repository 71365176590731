<template>
    <button class="endVideoClass">
        <FullScreen />       
    </button>  
</template>

<script>

import FullScreen from '../components/icons/FullScreen.vue'

export default {
    components: { FullScreen }

}
</script>

<style>

</style>