import firebase from 'firebase'
import { timestamp } from '../firebase/config'
import twillioVideoConnect from '../composables/twillioVideoConnect.js'
import getFirstName from '../composables/getFirstName.js'


import mobileDevice from '../composables/mobileDevice.js'
import getPagePath from './getPagePath'

const studentAccessCollection = firebase.firestore().collection("studentAccess");
const teacherAccessCollection = firebase.firestore().collection("teacherAccess");

let theUID;
let theEMAIL;
let studentName;
let teacherName;
let personFirstName

let thisPage;

const getThisPagePath = () => {

    const pathArray = window.location.pathname.split('/');
    const lastPartOfURL = pathArray[pathArray.length - 1]
    // console.log("***** lastPartOfURL: ******", lastPartOfURL)
    if(lastPartOfURL === 'ask'){
        thisPage = 'ask'
    } else {
        thisPage = 'answer'
    }
          
    }




// CURRENT USER INFO COLLECTED HERE, SO CAN BE USED AGAIN IN OTHER FILES
firebase.auth().onAuthStateChanged((user) => {

    if (user) { 
            
            theEMAIL = user.email 
            theUID = user.uid 
            
            const docRef = firebase.firestore().collection("users").doc(user.email);

            docRef.get().then((doc) => {  

                if (doc.exists) {

                    const { firstname } = doc.data()
                    personFirstName = firstname
              
                   
                } else {
                    console.log("No such document")
                }
        
                })
                

        } else {
            console.log("@ COMPOSABLE.JS - NO USER")
        }
    })

const createAccessToken = (role, email, uid, roomDocRef, room, collectionRef, isVideoConnection) => {


    // IF TEACHER IS CONNECTING CHECK IF 'STUDENT' ACCESS REF EXISTS FIRST
    // SOMETIMES 'TEACHER ACCESS TOKEN' IS CREATED ON VIDEO PAGE LOAD 

    
    console.log(role + " createAccessToken function starts here")
    console.log("theVidConnection theVidConnection", isVideoConnection)
    // STUDENT / TEACHER ERROR STARTS HERE
    // IF PAGE === VIDE0, THEN 'ROLE' MUST BE STUDENT
    // IF PAGE === ANSWER, THEN ROLE MUST BE TEACHER


    const newRoomID = room 
    const docID = newRoomID.toString() // THE DOC ID MUST BE A STRING (NUMBERS DON'T WORK FOR FIRESTORE DOC ID'S). 
    roomDocRef = docID 
    const nameKey = role + "FirstName"
    
    getThisPagePath()

    // const isStudent = thisPage === "video" ? 
    const isStudent = role === "student" && thisPage === "ask" ? 
    collectionRef = studentAccessCollection.doc(docID) : 
    collectionRef = teacherAccessCollection.doc(docID)

    console.log(role, " joining room '" + newRoomID + "'" + " from '" + thisPage + "' page" )

    collectionRef.set({

        [nameKey]: personFirstName,
        RoomID: newRoomID,
        uid: uid,
        email: email,
        datenow: Date.now(),
        timestamp:timestamp(),
        role:role,
        roomstatus: "connected",
        mobile_device: !mobileDevice(),
        isVideoConnection: isVideoConnection


        }).then(() => {
            // HERE CONFIRM THAT ACCESS COLLECTION REF HAS BEEN CREATED - CONNECTION FAILS IF NOT
                
            if(collectionRef && thisPage === 'ask'){
               
                if(role === 'student'){

                    // HERE PERHAPS PLACE INDICATORS IN UI INSTEAD OF ALERTSTO CONFIRM CORRECT DB REFS & TOKENS HAVE BEEN CREATED //
                     console.log(role + "AccesCollection Ref Created on " + thisPage + " page 'GOOD!': " + newRoomID)
                    } else {
                        // ERROR HAPPENS HERE
                    
                    console.warn(role + "AccesCollection Ref Created on " + thisPage + " page 'BAD!': " + newRoomID)
                    alert("Access Token Error 0001. Refresh Page")
                    }

            } 

            if(collectionRef && thisPage === 'answer'){

                if(role === 'teacher'){

                    console.log(role + "AccesCollection Ref Created on " + thisPage + " page 'GOOD!': " + newRoomID)
                    } else {
                        //alert("Access Token Error. Please Refresh Page.")
                    console.warn(role + "AccesCollection Ref Created on " + thisPage + " page 'BAD!': " + newRoomID)
                    alert("Access Token Error 0002. Refresh Page")
                    }

            }
        }).catch((err) => console.log("AccessCollecton Ref Creation Eff", err))

}

const createAccessTokenAndJoinRoom = (roomCreated, role, roomDocRef, room, question_sent_from_mobile_device, collectionRef, isVideoConnection) => {
    

    console.log("createAccessTokenAndJoinRoom function starts here")
    console.log("isVideoConnection isVideoConnection", isVideoConnection)
    console.log("question_sent_from_mobile_device", question_sent_from_mobile_device)
                     
    const studentRoomRef = studentAccessCollection.doc(roomCreated)
    const teacherAccessRef = teacherAccessCollection.doc(roomCreated)
    let dbRef;

    // FIRST CHECK IF THERE IS A ROOM WITH THE CURRENT ROOM REF (roomCreated)
    studentRoomRef.get().then((doc) => {

        // IF 'thisPage' === 'video' THEN THIS THE STUDENT, THE 'STUDENT' ACCESS TOKEN IS CREATED
        // IF 'thisPage' != 'video' THEN THIS THE 'ANSWER' PAGE, SO THE TEACHER ACCESS TOKEN IS CREATED

        console.log("doc.exists:", doc.exists) // THIS IS NOT RELIABLE, SO REPLACED WITH PAGE QUERY
    
        getThisPagePath()
        console.log("PAGE", thisPage)

        // ***** const docExists = doc.exists ? ****** // THIS is NOT RELIABLE, SO REPLACED WITH PAGE QUERY
        const isVideoPage = thisPage != 'ask' ?
        createAccessToken('teacher', theEMAIL, theUID, roomDocRef, room, collectionRef, isVideoConnection, 'answer') : 
        createAccessToken('student', theEMAIL, theUID, roomDocRef, room, collectionRef, isVideoConnection, 'video')

    }).then(() => {

    const isStudent = role === "student" ? dbRef = studentRoomRef : dbRef = teacherAccessRef
    
        dbRef.onSnapshot((doc) => {

            try {

                const { accessToken, mobile_device, isVideoConnection } = doc.data()

                // WHEN THE ACCESS TOKEN ARRIIVES, THEN CONNECT TO THE VIDEO ROOM
                console.log("CHECKING FOR ACCCESS TOKEN")
                if(accessToken) {


                    console.log("***** " + role + ", from mobile_device " + mobile_device + ", " + room + ", " + personFirstName + ". Access Token *****" + "\n" + accessToken)
                    // console.log("// ******* HOLD HERE - DO NOT USE 'twillioVideoConnect' FUNCTION ******* //")

                    if(role != "teacher"){
                        document.getElementById("accessTokenTrue").textContent = accessToken
                    }else {

                        // here find out if student using mobile device //
                        console.log("ROLE ROLE", role, "sent from mobile", question_sent_from_mobile_device,  "Video Connention", isVideoConnection )
                        twillioVideoConnect(accessToken, room, role, question_sent_from_mobile_device, isVideoConnection)
                    }
            
                    }else{
                        console.log("NO '" + role +  "' TOKEN FOUND")
                    }

                }catch(err){console.warn("AccessToken error - not arrived in Firestore DB ref yet", err)}

            });

        }).catch((error) => {
                console.warn("Error @ createAccessTokenAndJoinRoom:", error);
        })
 }

 export default createAccessTokenAndJoinRoom