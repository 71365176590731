<template>

   
    <Landing/>
    
    <!-- <About  id="aboutdiv" 
            :src="youngGuyGlassesLogo" class="offwhitebackground"
            furtherinfo="HelpSesh is for people with questions they'd like answered by a live human, right now. Use us when..."
            :bulletpoints="useUsWhen"
            /> -->

        <About id="aboutdiv"
            :src="youngGuyGlassesLogo" class="offwhitebackground"
            furtherinfo="Using HelpSesh is like doing a Google search, or posting on Quora, but with a live person answering your question."
            />

        <AboutHorizontal  v-if="isMobileDevice()"
            :pic1="questionimage"
            :pic2="AvatarVideoCall"/>

        <AboutHorizontal v-else 
            :pic1="questionimage_mobile"
            :pic2="AvatarVideoCall_Mobile"/>

          <!-- <About 
            :src="youngGuyGlassesLogo" class="offwhitebackground"
            furtherinfo="Use us when..."
            :bulletpoints="useUsWhen"
            /> -->

        <!-- <About  id="aboutdiv" 
            :src="GirlFX2" class="offwhitebackground"
            :bulletpoints="useUsWhen" 
            :furtherinfo="'Use us when....'"
            /> -->

    <!-- <About  :src="main_guy" class="offwhitebackground" :bulletpoints="potentialUsers" 
            :furtherinfo="'You May Be....'"
            /> -->

        <!-- <About  :src="redDungarees" class="offwhitebackground" 
            :furtherinfo="whoTheExpertsAre"
            /> -->

        <!-- <About  :src="baldGuy" class="offwhitebackground" :bulletpoints="reasonsToRespond" 
            :furtherinfo="'You can respond to a question if you....'"
            /> -->

    <!-- <About  :src="afroFem" class="offwhitebackground" 
            :bulletpoints="startingSubjects"
            :furtherinfo="'Our goal is to help with any subject, but starting with computer science, and coding, specifically...'"
            /> -->
        
        <!-- <About  :src="IndianMan" class="offwhitebackground" 
            :furtherinfo="'HelpSesh is free to use while in Beta.'"
            /> -->




    <!-- <ImageAndSlogan :src="afroFem" :slogan="slogan2" :paragraph1="sometext2" :paragraph2="moretext2" :paragraph3="joinus"/> -->
    
    <!-- <HowTo /> -->
    <!-- <ImageAndSlogan2 /> -->

    <!-- <OutReach />   -->
    
</template>

<script>

import { ref, onMounted } from 'vue'
import BoxCard from './BoxCard.vue'
import About from './About.vue'
import AboutHorizontal from './AboutHorizontal.vue'
import ImageAndSlogan from './ImageAndSlogan.vue'
import ImageAndSlogan2 from './ImageAndSlogan2.vue'
import ImageAndSlogan3 from './ImageAndSlogan3.vue'
import Landing from './Landing.vue'
import Phone from './Phone.vue'
import HowTo from './HowTo.vue'
import OutReach from './OutReach.vue'
import TopRow from './TopRow.vue'
import isMobileDevice from '../composables/mobileDevice.js'





export default {

      components: { BoxCard, About, ImageAndSlogan, ImageAndSlogan2, ImageAndSlogan3, Phone, HowTo, OutReach, TopRow, Landing, AboutHorizontal },

      setup(){

        const mobileDevice = ref(true)
        const expert5Guy = ref(require('../assets/Expert5Guy.png'))
        const guy = ref(require('../assets/BeardGuyLogo.png'))
        const videoicon = ref(require('../assets/groupmobile.png'))
        const redDungarees = ref(require('../assets/RedDungarees.png'))
        const youngGuyGlassesLogo = ref(require('../assets/YoungGuyGlasses.png'))
        const baldGuy = ref(require('../assets/BaldGuyLabelled.png'))
        const main_guy = ref(require('../assets/main_guy.png'))
        const IndianMan = ref(require('../assets/IndianMan.png'))
        const GirlFX2 = ref(require('../assets/GirlFX2.png'))
        const questionimage = ref(require('../assets/questionimage.png'))
        const AvatarVideoCall = ref(require('../assets/AvatarVideoCall.png'))
        const questionimage_mobile = ref(require('../assets/questionimage_mobile.png'))
        const AvatarVideoCall_Mobile = ref(require('../assets/AvatarVideoCall_Mobile.png'))
        
        
        
        const afroFem = ref(require('../assets/AfroFem.png'))
        const afroFemCircled = ref(require('../assets/AfroFemCircled.png'))
        const slogan1 = ref("NEW LANDING PAGE")
        const slogan2 = ref("COMMUNITY")
        const paragraph1 = "Post a request - an expert will connect with you're in moments, via video or voice. That's it."
        // const paragraph1 = "Currently we have experts in 'Javascript', 'Vue.js' and 'React'. Much more coming soon!"
        const paragraph2 = "Sed odio morbi quis commodo odio aenean sed adipiscing. Volutpat sed cras ornare arcu dui vivamus. Enim nulla aliquet porttitor lacus luctus accumsan tortor."
        const paragraph3 = ""
        const moretext2 = "They could be a new follower, subscriber, customer, or client."
        const sometext2 = "Are you an expert in something? Do you have 5 minutes to help someone from time to time?"
        const joinus = "JOIN US!"
        const weCover = "WHAT WE COVER"
       
        const weCover2 = "Launching with experts in Software Development. Learning a programming language, or framework, building your first app, or have other coding / wed-dev issues? We can help."
        const weCover3 = "In the future we aim to provide a real person via video or voice, to help with almost anything, instantly.  From music production, video production, and graphics, to social media, nfts, crypto, cars, fashion and sport. "
        const weCover4 = ""

        const useUsWhen = ref([
                        "you're stuck",
                        "you want feedback",
                          "you want an opinion",
                        //   "you just want to speak to a real person",
                          
                        //   "you're in a hurry",
                        //   "you can't find the answer online",
                        //   "there are too many answers online",
                        //   "you don't understand the answers online",

                        //   "a video isn't enough",
                          
                        //   "email is too slow",
                        //   "you don't need a whole hour lesson ",
                        //   "it's hard to find someone to help",
                        //   "the people you find are too expensive",

                          
                        //   "you’re looking for a teacher",
                        //   "you want to hire someone"


                          ])
        const reasonsToRespond = ref([
                "definitely know the answer",
                "are available right now",
                // "want to make new friends or connections",
                "want new friends, clients, followers, subscribers etc",
                // "just want to help"
        ])

        // const startingSubjects = ref([])
        const startingSubjects = ref(["Javascript", "Vue.js", "React", "...more soon"])
        const thirdpoints = ref(["A Student", "An Instructor", "An Influencer", "A Teacher", "A Coach", "A Self-learner", ])
        const whoTheExpertsAre = ref("You will be answered by another user, who has read your question, and is available now.")
        const potentialUsers = ref([
                "A Student",
                "An Instructor",
                "An Influencer",
                // "A Self-learner",
                "An Entrepreneur",
                // "A Hobbyist",
                // "An Enthusiast",
                // "A Business Person",
                // "A Parent",
                "Something Else"
                ])
        
   
          onMounted(() => {

             return mobileDevice.value = isMobileDevice()  

          })

          return {  expert5Guy, 
                    guy, 
                    afroFem, 
                    slogan1, 
                    slogan2, 
                    paragraph2, 
                    moretext2, 
                    paragraph1, 
                    sometext2, 
                    paragraph3, 
                    joinus, 
                    afroFemCircled, 
                    youngGuyGlassesLogo,
                    weCover,
                    weCover2,
                    weCover3,
                    weCover4,
                    redDungarees,
                    videoicon,
                    useUsWhen,
                    startingSubjects,
                    thirdpoints,
                    whoTheExpertsAre,
                    potentialUsers,
                    baldGuy,
                    reasonsToRespond,
                    main_guy,
                    IndianMan,
                    GirlFX2,
                    questionimage,
                    AvatarVideoCall,
                    questionimage_mobile,
                    AvatarVideoCall_Mobile,
                    isMobileDevice

                  
                    
                    }

      }

}
</script>

<style>

</style>