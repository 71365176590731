


<template>
<Nav />

<div class="thisMarginTop greyWhite">
    <div class="d-flex justify-content-center my-2">
        <el-card class="box-card marginTop text-start">
            <div class="text item m-4 pageWidth">
                <!-- <p>Full Name: {{ profileArray.firstname + " " + profileArray.lastname  }}</p> -->
                <p><span class="fw-bold">Full Name:</span> {{ profileArray.firstname + " " + profileArray.lastname  }}</p>
                <p><span class="fw-bold">Email:</span> {{ profileArray.email }}</p>
                <p><span class="fw-bold">Tel:</span> {{ profileArray.tel }}</p>  
            </div>
            <el-button class="m-4" type="text">edit</el-button>
        </el-card>
    </div>


    <div class="d-flex justify-content-center my-2">
        <el-card class="box-card text-start">
            <div class="fw-bold m-4">SUBJECTS</div>

            <div v-for="(val,key, i) in subjectsObject" :key="val" class="m-4">
                <div class="fw-bold">{{key}}</div>
                <div :ref="el => subjectStatements[i] = el">
                    {{val}}
                </div>

                <textarea
                    class="p-2" 
                    :ref="el => subjectStatementInputs[i] = el"
                    :class="{displayNone: !isVisible}"
                    type='text' 
                    :value="val"
                > 
                </textarea>
            
                <div :ref="el => editSubjectStatements[i] = el" class="my-3">
                    <el-button 
                        type="text"                    
                        @click="editProfileData( subjectStatements[i], subjectStatementInputs[i], editSubjectStatements[i], updateSubjectStatements[i] )">
                        edit
                    </el-button> 
                </div>
    
                <div :ref="el => updateSubjectStatements[i] = el" class="displayNone my-3">
                    <el-button 
                        type="text"           
                        @click="updateUserData(editSubjectStatements[i], updateSubjectStatements[i], subjectStatementInputs[i], key, subjectStatements[i], subjectsObject, 'subjects')">
                        save
                    </el-button>
                </div>


            </div>
            
        </el-card>
    </div>


    <div class="d-flex justify-content-center my-2">
        <el-card class="box-card text-start">
            <div class="fw-bold m-4">SOCIAL MEDIA</div>
            
            <div v-for="(val, key, i) in socialsObject" 
                :key="key"          
                class="m-4 pageWidth" >
                
                <div class="fw-bold">{{key}}</div>
                <div 
                    :ref="el => socialmediaURLs[i] = el"
                    :class="{displayNone: isVisible }">
                    {{val}}
                </div>

                <textarea
                    :ref="el => socialmediaInputs[i] = el"
                    :class="{displayNone: !isVisible}"
                    type='text' 
                    :value="val"
                > 
                </textarea> 


                <div :ref="el => editSocialsButton[i] = el" class="my-3">
                    <el-button 
                        type="text"                    
                        @click="editProfileData( socialmediaURLs[i], socialmediaInputs[i], editSocialsButton[i], updateSocialsButton[i] )">
                        edit
                    </el-button> 
                </div>
    
                <div :ref="el => updateSocialsButton[i] = el" class="displayNone my-3">
                    <el-button 
                        type="text"           
                        @click="updateUserData(editSocialsButton[i], updateSocialsButton[i], socialmediaInputs[i], key, socialmediaURLs[i], socialsObject, 'socials')">
                        save
                    </el-button>
                </div>


            </div>
           
        </el-card>
    </div>
</div>


</template>


<script>

    import Nav from '../components/Nav.vue'
    import { onMounted, ref, watchEffect } from 'vue'
    import firebase from 'firebase'

export default {

    components: { Nav },

    setup(){

        const usersCollection = firebase.firestore().collection("users");

        const profileArray = ref([])
        const subjectKeys = ref([])
        const subjectsObject = ref(null)
        const socialsObject = ref(null)
        const inputEdit = ref(false)
        const isVisible = ref(false)
        const addTestClass = ref(false)
        const socialmediaURLs = ref([])
        const socialmediaInputs = ref([])
        const editSocialsButton = ref([])
        const updateSocialsButton = ref([])
        const subjectStatements = ref([])
        const subjectStatementInputs = ref([])
        const editSubjectStatements = ref([])
        const updateSubjectStatements = ref([])
        const dataOffSetHeight = ref(null)
        const dataOffSetWidth = ref(null)

        const editProfileData = (data, input, edit, update ) => {

            // THE EDIT BUTTON HIDES THE DIV WITH THE CURRENT VALUE,
            // AND UN-HIDES THE INPUT, TO ENTER THE NEW VALUE.
            // 'dataOffSetHeight' AND 'dataOffSetWidth' USE WATCHEFFECT TO GET THE DIMENSIONS OF DIV HOLDING 
            // THE CURRENT VALUE (TEXT), AND SETS THE INPUT (TEXTAREA)
            // TO THAT SIZE. THIS WAY EDITNG FITS WELL.
            data.style.display = "none"
            input.style.display = "block"
            input.style.height = (dataOffSetHeight.value * 1.5) + "px" // 1.5 adds some padding here
            input.style.width = dataOffSetWidth.value + "px"
            // HERE CHARACTER LIMIT TO 100 - maxLength not working
            
            input.focus()

            edit.style.display = "none"
            update.style.display = "block"
            
            }

        const getOffSetHeight = (data) => {
            console.log("Eleement offSetHeight: ", data.offsetHeight)
        }


        const updateUserData = (edit, update, input, param, url, dataObject, dbref) => {

                        edit.style.display = "block"
                        update.style.display = "none"

                        const user = firebase.auth().currentUser

                        console.log("UPDATE")
                        const updateVal = input.value
                        console.log("updateVal:", updateVal)

                        usersCollection.doc(user.email).set({
                            
                            [dbref]:{
                                [param]: updateVal
                            }
                        },{merge:true})
                        .then(() => {

                            dataObject[param] = updateVal
                            url.style.display = "block"
                            input.style.display = "none"
                      
                        })
                        .catch((error) => {
                                console.error("Error writing document: ", error);
                        });

                        }

        

            firebase.auth().onAuthStateChanged((user) => {
                if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                usersCollection.doc(user.email).get().then((doc) => {

                        profileArray.value = doc.data()

                        const { firstname, lastname, subjects, socials, email } = doc.data()
                        console.log(firstname + " " + lastname)
                        // console.log(Object.entries(socials))
                        subjectKeys.value = Object.keys(subjects)
                        subjectsObject.value = subjects

                        if(socials != null ){
                            socialsObject.value = socials
                        } else {
                            console.log("No Social media listed")
                        }

                })
                
            
                } else {
             
                console.log("USER SIGNED IN: FALSE")
                
                }
            });

            watchEffect(() => {

                        subjectStatements.value.forEach(element => 
                        dataOffSetHeight.value = element.offsetHeight,
                                );

                        subjectStatements.value.forEach(element => 
                        dataOffSetWidth.value = element.offsetWidth
                                );
                        
                        }, 
                        {
                            flush: 'post'
                        })


            return {    profileArray, subjectKeys, subjectsObject, socialsObject, 
                        editProfileData, inputEdit, updateUserData, isVisible, addTestClass, socialmediaURLs, 
                        socialmediaInputs, editSocialsButton, updateSocialsButton, subjectStatements, 
                        subjectStatementInputs, editSubjectStatements, updateSubjectStatements, getOffSetHeight,
                        dataOffSetHeight, dataOffSetWidth
                        
                    }


    }

}
</script>

<style>

.marginTop {
    margin-top: 50px

}

  .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
    
  .text {
    font-size: 14px;
  }

  .item {
    margin-bottom: 18px;
  }

  .box-card {
    /* width: 100%; */
    max-width: 600px;
    min-width: 350px;
  }

  .pageWidth {
      width: 600px;
  }

  .labelWidth {
      width: 500px
  }

  .displayNone {
      display: none;
  }

  .testClass {
      background-color: red;
      color: blue;
      border-width: 10px;
  }

  textarea {
      min-width: 300px;
      max-width: 500px
  }

  


</style>