import { createApp } from 'vue'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import './assets/main.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import 'animate.css';
import Vue3linkify from "vue-3-linkify";
// import vueCodeHighlight from 'vue-code-highlight';
// import "vue-code-highlight/themes/duotone-sea.css";
// import "vue-code-highlight/themes/prism-funky.css";
// import "vue-code-highlight/themes/prism-tomorrow.css";
// import "vue-code-highlight/themes/prism-twilight.css";
// import "vue-code-highlight/themes/prism-dark.css";
// import "vue-code-highlight/themes/prism-coy.css";
// import "vue-code-highlight/themes/prism.css";
// import "vue-code-highlight/themes/prism-okaidia.css";
// import "vue-code-highlight/themes/prism-solarizedlight.css";
// import "vue-code-highlight/themes/window.css";

// INFO ON NPM "vue-code-highlight"
// https://www.npmjs.com/package/vue-code-highlight




createApp(App)
// .use(vueCodeHighlight)
.use(Vue3linkify)
.use(router)
.use(ElementPlus)
.mount('#app')
