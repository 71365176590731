import firebase from 'firebase'
const sessionsCollection = firebase.firestore().collection("sessions")

// 'turnOnTimer' param is a function - fires correctly when returned with parentheses
const startTimerOnConnection = (id, turnOnTimer) => { 

        sessionsCollection.doc(id).onSnapshot((doc) => {

                if( doc.exists) {

                        try{
   
                        if(doc.data().sessionStatus == 'started'){
                            // 'turnOnTimer' returned here with parentheses
                            return turnOnTimer()
                        } else {
                            console.log("Session", doc.data().sessionStatus)
                        }

                    }catch(error){console.warn(error)}

                    }
                

            }); 
                 
            
        };

export default startTimerOnConnection