<template>
    <Nav />
        <div class="d-flex justify-content-center" style="margin-top:100px">
           <Post
                :sender="thisSender"
                :subject="thisSubject"
                :message="thisQuestion"
                :id="thisID"
                :emailToCheck="emailVar"
                :mediaUrl="thisMediaURL"
            />
        </div> 
</template>

<script>

import firebase from 'firebase'
import { ref } from 'vue'
import notifyStudentExpertIsAvailable from '../composables/notifyStudentExpertIsAvailable'
import Post from  '../components/Post.vue'
import Nav from '../components/Nav.vue'



export default {

    components: { Post, Nav },

    setup(){

        const questionsCollection = firebase.firestore().collection("questions");
        const thisQuestion = ref("")
        const thisSubject = ref("")
        const thisSender = ref("")
        const thisID = ref("")
        const thisMediaURL = ref("")
        const lastPartOfURL = ref("") // the last part of the URL is the doc ID (post ID)
        const emailVar = ref(null)

        
        const getPath = () => {

                var pathArray = window.location.pathname.split('/');
                lastPartOfURL.value = pathArray[pathArray.length - 1]
                console.log("lastPartOfURL:", lastPartOfURL.value)
                return lastPartOfURL.value
                
            }


        const questionDoc = questionsCollection.doc(getPath())

                questionDoc.get().then((doc) => {

                    const user = firebase.auth().currentUser
                    const theCurrentUserEmail = user.email 

                        if (doc.exists) {

                            console.log("Document data:", doc.data());
                            const { question, sender, subject, id, media_URL } = doc.data()

                            emailVar.value = doc.data()[theCurrentUserEmail]
                            thisQuestion.value = question
                            thisSubject.value = subject
                            thisSender.value = sender
                            thisID.value = id
                            thisMediaURL.value = media_URL
                            

                        } else {
                            // doc.data() will be undefined in this case
                            console.log("No such document!");
                        }
                        }).catch((error) => {
                            console.log("Error getting document:", error);
                            console.log("PLEASE SIGN IN TO VIEW YOU MESSAGE")

                        });


        return { emailVar, thisID, thisQuestion, thisSubject, thisSender, lastPartOfURL, getPath, notifyStudentExpertIsAvailable, thisMediaURL }
    }

    
}
</script>


<style scoped>



</style>