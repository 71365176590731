<template>
    <div class="grad7 box">
    <FlashingText class="white-font my-5 mx-3 fontSize text-start"/>
    </div>
</template>

<script>

import FlashingText from "./FlashingText.vue"

export default {

    components: { FlashingText }


}
</script>

<style scoped>

.bgColor {
    background-color: grad;
}

.height {
    height: 30vh;
}

.fontSize {
    font-size: 240%
}

.myborder {
    border-left: 50px solid #f54952;
}




</style>