<template>
    <div class="slogan-style headingFont"
    :class="{'slogan-size-mobile':!mobileDevice(), 'slogan-size-desktop':mobileDevice()}"
    >
        <div>{{ slogan }}
            <div class="small-font py-3">
                <!-- <VideoModal :videoURL="videoURL"  :buttonText="'PROMO VID (45secs)'"/> -->
                <VideoModal :videoURL="correctVideo()"  :buttonText="'VIDEO (1min)'"/>
                <el-button class="border-radius-none m-1 front-page-button"  type="info"
                    @click="scrollToAboutPage()"
                    style="margin-left:0px; font-weight: bold;">ABOUT</el-button>
            </div>
        </div>
    </div>
    
    <div class="d-flex align-items-end height-100vh">   
        <img v-if="mobileDevice()" :src="mainimage">
        <img v-else :src="mainimageALT">
    </div> 
</template>

<script>

import { ref, onMounted } from 'vue'
import mobileDevice from '../composables/mobileDevice.js'
import VideoModal from '../components/VideoModal.vue'

export default {

    setup(){

        const scrollToAboutPage = () => {
            const element = document.getElementById("aboutdiv");
            element.scrollIntoView();
        }

        const mainimage = ref(require('../assets/groupmobile.png'))
        const mainimageALT = ref(require('../assets/groupmobileALT.png'))
        // const slogan = ref("Live Help & Answers From Real People, Instantly.")
        const slogan = ref("Live Help From The Community.")

        const videoURL = ref("https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/Expert5%20Promo%20vid%201.2%20(alt).mp4?alt=media&token=53bd48cc-6863-4455-812c-591f926a2a4e")
        
        const howToURL = ref("https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/HelpSeshHow2_Four.mp4?alt=media&token=9fa72c70-93e4-43c4-bce1-c33319a89527")
        const howToURL_mobile = ref("https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/HelpSeshQuickHowTo_Mobile_One.mp4?alt=media&token=1c7eba2c-0812-49e5-8a42-730ab318728e")
        // Promo Vid URL: https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/Expert5%20Promo%20vid%201.2%20(alt).mp4?alt=media&token=53bd48cc-6863-4455-812c-591f926a2a4e
        // Quick how to URL: https://firebasestorage.googleapis.com/v0/b/udemy-vue-firebase-sites-ea94e.appspot.com/o/QuichHowTo2.mp4?alt=media&token=05bd3326-8dd0-4dcd-b227-308c8c8ae07c
        const hideSloganText = ref(false)

        const correctVideo = () => {
            if(!mobileDevice()){
                return howToURL_mobile.value
            } else {
                return howToURL.value
            }
        }
        
        return { mainimage, mobileDevice, slogan, VideoModal, videoURL, hideSloganText, howToURL, mainimageALT, scrollToAboutPage, howToURL_mobile, correctVideo }
    }

}
</script>

<style>



</style>