<template>
        <div class="d-flex justify-content-between align-items-center mx-3">
            <div class="d-flex flex-row align-items-center labelMarginMobile"> 
                    <div class="dot"></div>
                    <div class="m-2 fw-bold"
                        :sender="sender" 
                        :subject="subject">
                        {{ sender }} ({{ subject }})
                    </div>    
            </div>
            <div class="mx-2 nameHolder"></div>
        </div>
</template>

<script>
export default {

    props: ['subject', 'sender']

}
</script>

<style>

</style>