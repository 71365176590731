<template>

    <div class="d-flex justify-content-center" style="margin-top:50px;">

        <el-form 
            class="marginTop myform"
            v-if="!signedIn" 
            label-width="100px"        
            >
            <p class="fs-3">SIGN-UP</p>

            <el-form-item label="First Name" style="color:white"> 
                <el-input type='text' v-model='firstName'></el-input> 
            </el-form-item>
            <el-form-item label="Last Name"> 
                <el-input type='text' v-model='lastName'></el-input> 
            </el-form-item>
            <el-form-item label="Email"> 
                <el-input type='email' v-model='email'></el-input> 
            </el-form-item>
            <el-form-item label="Tel"> 
                <el-input type='tel' v-model='tel'></el-input> 
            </el-form-item>
            <el-form-item label="Password"> 
                <el-input type='password' v-model='password'></el-input> 
            </el-form-item>
             <el-button @click="register" type="info" round>next</el-button>
      
        </el-form>
        
    </div>
  

</template>

<script>

import { ref } from 'vue'
import firebase from 'firebase'
import { useRouter } from 'vue-router'
import { timestamp } from '../firebase/config'
import subjects from '../data/subjects.json'
import Nav from '../components/Nav.vue'

export default {

    components: { Nav },

    setup(){

        const db = firebase.firestore();

        const firstName = ref('')
        const lastName = ref('')
        const email = ref('')
        const password = ref('')
        const tel = ref(null)
        const router = useRouter() // get a reference to our vue router
        const signedIn = ref(null)
        const theSubjects = ref(subjects)
        const currentUserEmail = ref(null)
        const currentUserDisplayName = ref(null)

        firebase.auth().onAuthStateChanged((user) => {

                if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User

                //   var uid = user.uid;
                console.log("USER SIGNED IN")
                signedIn.value = true
                currentUserEmail.value = user.email

                const docRef = db.collection("users").doc(user.email);

                    docRef.get().then((doc) => {
                    if (doc.exists) {
                        console.log("Document data first name:", doc.data().firstname);
                        currentUserDisplayName.value = doc.data().firstname;

                    } else {
                        // doc.data() will be undefined in this case
                        console.log("No such document!");
                    }
                }).catch((error) => {
                    console.log("Error getting document:", error);
                });




                // ...
                } else {
                // User is signed out
                // ...
                console.log("NO USER SIGNED IN")
                signedIn.value = false
                }
        });

        

        const register = () => {

            firebase

                    .auth() // get the auth api
                    .createUserWithEmailAndPassword(email.value, password.value) // need .value because ref()
                    .then((data) => {
                    console.log('Successfully registered!########');
                    router.push('/') 
                    })
                    .then(() => {

                        updateUserProfile()
                        console.log("SUCCESS")
                    })
                    .then(onSuccess => {
                        console.log("SUCCESS")
                        createUserProfile(email.value, firstName.value, lastName.value, tel.value)
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });
                }


        const createUserProfile = (email, firstName, lastName, tel) => {

                    const uid = firebase.auth().currentUser.uid

                    db.collection("users").doc(email).set({
                        firstname: firstName,
                        lastname: lastName,
                        email: email,
                        tel: tel,
                        uid: uid,
                        instructor: false,
                        joined: timestamp()
                    })
                    .then(() => { console.log("Document successfully written!");})
                    .catch((error) => {console.error("Error writing document: ", error);
                    });

            }


                const updateUserProfile = () => {

                        const user = firebase.auth().currentUser;
                        
                        user.updateProfile({
                        displayName: firstName.value,
                        // photoURL: "https://example.com/jane-q-user/profile.jpg"
                        }).then(() => {
                        // Update successful
                        console.log("DisplayName Updated")
                        // ...
                        }).catch((error) => {
                        // An error occurred
                        console.log("Error @ updateUserProfile" + error)
                        // ...
                        });  

                }

         
        const signOut = () => {

            firebase.auth()
                    .signOut()
                    .then((data) => {
                    console.log('Successfully Sign-out!');
                    router.push('/ask') 
                    })
                    .catch(error => {
                    console.log(error.code)
                    alert(error.message);
                    });

        }







        return { register, email, password, router, createUserProfile, signOut, 
        firstName, lastName, tel, signedIn, theSubjects, 
        currentUserEmail, currentUserDisplayName, updateUserProfile }

    }


}

</script>

<style scoped>



.welcome {
    font-size: 400%;
    color:red;
}

.marginTop {
    margin-top: 50px
}


</style>