<template>

    <div>
        <h5 class="expert5blue bold-font"> Text "Help" to</h5>
        <div class="white-font mediumFont">+1 (860) EXPERT 5 **</div>
        <div class="white-font mediumFont">+1 (860) 397 3785</div>
        <h5 class="expert5blue bold-font" >Talk, video-chat, screen-share, immediately </h5>
    </div>
  
</template>

<script>
export default {

}
</script>

<style>

</style>