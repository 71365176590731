<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="54.995" height="64.994" viewBox="0 0 54.995 64.994">
  <g id="Icon_feather-user-check" data-name="Icon feather-user-check" transform="translate(2.5 2.5)">
    <path id="Path_129" data-name="Path 129" d="M51.495,42.5V35.832A13.332,13.332,0,0,0,38.163,22.5H14.832A13.332,13.332,0,0,0,1.5,35.832V42.5" transform="translate(-1.5 17.496)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
    <path id="Path_130" data-name="Path 130" d="M33.414,17.832A13.332,13.332,0,1,1,20.082,4.5,13.332,13.332,0,0,1,33.414,17.832Z" transform="translate(4.915 -4.5)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="5"/>
  </g>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>